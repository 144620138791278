import React from "react";
import CarePlanItem from "../../components/carePlan/CarePlanItem";

const CarePlanBodyTemplate: React.FC<any> = ({patient}) => {
    return <>
        {patient?.carePlanes && patient?.carePlanes.length > 0 ? patient?.carePlanes?.map((ele: any, i: number) => (
            <CarePlanItem carePlan={ele} key={i}/>
        )) : '-'}
    </>
}
export default CarePlanBodyTemplate
