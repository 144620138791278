import React from 'react';
import LabelValue from "../custom/labelValue/LabelValue";
import { IconSVG } from "../custom/icon/IconSVG";

const Patient = ({patient}: { patient: { name: string, surname?: string, birthdate?: string, age?: string, gender?: string } }) => {

    return (
        <div className={"d-flex"}>
            <IconSVG icon={"patient"} size={'20px'} className={'me-2'}/>
            <LabelValue label={`${patient.surname?.toUpperCase()} ${patient.name}`} className={'mb-0 '}
                        value={`${patient.birthdate}${patient.age ? ', ' + patient.age : ''}${patient.gender ? ', ' + patient.gender : ''}`}
                        labelClass={'lg medium text-primary-dark'} valueClass={'sm medium'}/>
        </div>
    );
};

export default Patient;
