import React from 'react'
import UserAvatar from "../../../../../shared/custom/userAvatar/UserAvatar";
import {OrganizationModel} from "../../../models/Organization.model";
import {IconSVG} from "../../../../../shared/custom/icon/IconSVG";
import Dropdown from "../../../../../shared/bootstrap/dropdown/Dropdown";
import {useLanguageForShared} from "../../../../../shared/utils/languages/useLanguageForShared";
import {ListAppsProps} from "../models/Nabar.model";

export const NavUser = ({
                            user,
                            setToggleSidebarHandler,
                            currentOrganization,
                            clickAppHandler, listApps
                        }: {
    user: any
    currentOrganization?: OrganizationModel
    setToggleSidebarHandler: () => void,
    clickAppHandler?: (app: any) => void,
    listApps?: ListAppsProps[]
}) => {
    const {t} = useLanguageForShared()

    const fullName = `${user.Name || user.firstname} ${user.Surname || user.lastname}`;

    const dropdownList = <> {listApps && listApps?.map((app, i) => (
        <li key={i}><a className="dropdown-item" data-test="dropdown_app" data-test-name={app.name}
                       onClick={() => clickAppHandler && clickAppHandler(app.url)}>{t(`header.nav.voices.app.${app.name}`)}</a>
        </li>))}
    </>

    return (
        <div className={'item-profile'}>
            <div className="user-label" onClick={setToggleSidebarHandler}>
                <div className="text-primary pb-1">{fullName}</div>
                <div data-test="current_organization_name" data-test-code-organization={currentOrganization?.Id}>{currentOrganization?.Name?.toUpperCase() || currentOrganization?.name?.toUpperCase()}</div>
            </div>
            <UserAvatar
                user={fullName}
                clickHandler={setToggleSidebarHandler}
            />
            {listApps && <div className={"ms-2"}>
                <Dropdown dataTest="app" list={dropdownList} position={'dropstart'}>
                    <div onClick={() => null}>
                        <IconSVG icon={"apps"} size={"20px"}/></div>
                </Dropdown>
            </div>}
        </div>
    )
}
