import React from 'react';
import Tag from "../../../../shared/custom/tag/Tag";
import {mapStateColor} from "../../../../shared/utils/mapStateColor";


const LastSectionStatusCol = ({
                                  state
                              }: { state: any }) => {
    return (
        <div>
            {state?.status ? <Tag label={state.statusTranslate} bgColor={mapStateColor(state.status)?.bg}
                                  color={mapStateColor(state.status)?.color}/> : '-'}
        </div>
    );
};

export default LastSectionStatusCol;
