import React from "react";

const DeviceBodyTemplate: React.FC<any> = ({patient}) => {
    return   <div className={'d-flex flex-column'}>
        <span>{patient?.device?.name}</span>
        <small>
            {patient?.device?.type}
        </small>
    </div>
}
export default DeviceBodyTemplate
