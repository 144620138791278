export interface EventConfiguration{
    id: number;
    event: any
}

export interface ChangeLanguageInterface extends EventConfiguration{
    event: (lang: string) => void
}

const configuration: {
    configuration: any,
    theme: any,
    external: any,
    events: {
        changeLanguage: ChangeLanguageInterface[]
    };
} = {
    configuration: {
        lang: 'it'
    },
    external: {},
    theme: {
        primary: "#00A0E1",
        primaryDark: "#005591",
        grayDark: "#6E7878",
        grayLight: '#7C94A5',

        gray: "#d5d5d5",
        gray100: "#EDF2F5",
        light: "#fff",
        error: "#910000",
        warning: "#DC7800",
        success: "#B2E1C4",
        success_color: "#004D1E",
        danger: "#F6B2B7",
        danger_color: "#700007",
        alert: "#F4D6B2",
        alert_color: "#754000",
        inactive: "#e1e1e1",
        inactive_color: "#404545",

        /*  grayDark: '#333',
            gray: '#888',*/
        inProgress: '#5593C1',
        terminate: '#899094',
        red: '#E1000F',
        cyan: '#37B7F4',
        blueGreen: '#5BD3B8',
        lightOrange: '#EAA227',

        info: "#FFF1B2",
        info_color: "#DC7800",
        series: {
            s100: 'rgb(0,155,155)',
            s170: 'rgba(0,155,155,0.7)',
            s150: 'rgba(0,155,155,0.5)',
            s130: 'rgba(0,155,155,0.3)',
            s100_hex: '#009b9b',
            s170_hex: '#5db9ba',
            s150_hex: '#88cdcd',
            s130_hex: '#b7e1e1',
            s200: 'rgb(128,9,191)',
            s270: 'rgba(128,9,191,0.7)',
            s250: 'rgba(128,9,191,0.5)',
            s230: 'rgba(128,9,191,0.3)',
            s300: 'rgb(189,191,9)',
            s370: 'rgba(189,191,9,0.7)',
            s350: 'rgba(189,191,9,0.5)',
            s330: 'rgba(189,191,9,0.3)',
            s400: 'rgb(222,13,146)',
            s470: 'rgba(222,13,146,0.7)',
            s450: 'rgba(222,13,146,0.5)',
            s430: 'rgba(222,13,146,0.3)',
            s500: 'rgb(162,250,163)',
            s570: 'rgba(162,250,163,0.7)',
            s550: 'rgba(162,250,163,0.5)',
            s530: 'rgba(162,250,163,0.3)',
            s600: 'rgb(0,225,206)',
            s670: 'rgba(0,225,206,0.7)',
            s650: 'rgba(0,225,206,0.5)',
            s630: 'rgba(0,225,206,0.3)',
            s700: 'rgb(77,108,250)',
            s770: 'rgba(77,108,250,0.7)',
            s750: 'rgba(77,108,250,0.5)',
            s730: 'rgba(77,108,250,0.3)',
            s700_hex: '#4D6CFA',
            s770_hex: '#8398fd',
            s750_hex: '#a6b5fe',
            s730_hex: '#cad3fe',
            s800: 'rgb(213,92,140)',
            s870: 'rgba(213,92,140,0.7)',
            s850: 'rgba(213,92,140,0.5)',
            s830: 'rgba(213,92,140,0.3)',
            s800_hex: '#d55c8c',
            s870_hex: '#e08caf',
            s850_hex: '#e9adc6',
            s830_hex: '#f2cedd',

            alert100: 'rgb(220,120,0)',
            alert170: 'rgba(220,120,0,0.7)',
            alert150: 'rgba(220,120,0,0.5)',
            alert130: 'rgba(220,120,0,0.3)',

            alert200: 'rgb(255,210,0)',
            alert270: 'rgba(255,210,0,0.7)',
            alert250: 'rgba(255,210,0,0.5)',
            alert230: 'rgba(255,210,0,0.3)',

            danger100: 'rgb(225,0,15)',
            danger170: 'rgba(225,0,15,0.7)',
            danger150: 'rgba(225,0,15,0.5)',
            danger130: 'rgba(225,0,15,0.3)',

            success100: 'rgb(0,155,60)',
            success170: 'rgba(0,155,60,0.7)',
            success150: 'rgba(0,155,60,0.5)',
            success130: 'rgba(0,155,60,0.3)',

            success200: 'rgb(190,205,0)',
            success270: 'rgba(190,205,0,0.7)',
            success250: 'rgba(190,205,0,0.5)',
            success230: 'rgba(190,205,0,0.3)',

        }
    },
    events: {
        changeLanguage: []
    }
}
export default configuration
