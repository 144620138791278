import {initI18n} from "./localTranslate";
import {loadScriptOrIgnore} from "../../loadScript";

export const formatToI18n: any = (obj: any) => {
    return Object.keys(obj).reduce((acc, ele) => {
        return {
            ...acc,
            [ele]: typeof obj[ele] === 'string'? obj[ele].replace(/\{([0-9])}/g, "{{$1}}") :formatToI18n(obj[ele])
        }
    }, {})
}

export const getCorrectTranslate = (ele: string, base: any, custom: any, path: string[] = []) => {
    const dataCustom = eval('custom?.'+ path.join('?.') + (path.length? '?.' : '')+ ele);
    return dataCustom ? dataCustom : base[ele]
}

export const mergeI18n: any = (base: any, custom: any, path: string[] = []) => {
    return Object.keys(base).reduce((acc, ele) => {
        return {
            ...acc,
            [ele]: typeof base[ele] === 'string'? getCorrectTranslate(ele, base, custom, path) :mergeI18n(base[ele], custom, [...path, ele])
        }
    }, {})
}

export const getDynamicLanguage = (lang: string) => {
    return new Promise((resolve ) => {
        if(process.env.REACT_APP_LOAD_CONFIG){
            Promise.all([loadScriptOrIgnore(`${process.env.REACT_APP_LOAD_CONFIG}base/translate/${lang}.js`), loadScriptOrIgnore(`${process.env.REACT_APP_LOAD_CONFIG}translate/${lang}.js`)]).then(res => {
                const translation = mergeI18n((window as any).translateBase[lang], (window as any).translateCustom[lang]);
                resolve(translation)
            })
        } else {
            resolve(initI18n[lang] || {})
        }

    } )
}
