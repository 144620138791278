import React from 'react'
import CheckboxCustom from './checkbox/CheckboxCustom'
import InputCustom from './input/InputCustom'
import RadioCustom from './radio/RadioCustom'
import Textarea from './textArea/TextArea'
import CheckboxSingle from "./checkbox/CheckboxSingle";
import ReactSelect from "../../external_lib/form/reactSelect/ReactSelect";
import SelectCustom2 from "../../external_lib/form/select2/Select2";

const FormControlCustom = ({
                               isSubmit,
                               errors,
                               value,
                               changeValue = () => {
                               },
                                actions,
                               structure,
                               onBlur,
                               dataForm
                           }: any) => {
    const dataElement = structure?.dataElement || {}

    return (
        <>
            {structure.type === 'input' && (
                <InputCustom
                    name={structure.name}
                    error={isSubmit && !!errors && !!errors[structure.name]}
                    extra={structure.extra}
                    value={dataForm[structure.name]}
                    changeValue={(val) =>
                        changeValue({name: structure.name, value: val})
                    }
                    onBlur={onBlur}
                    {...dataElement}
                />
            )}
            {structure.type === 'select' && (
                /*  <SelectCustom
                  error={isSubmit && !!errors && !!errors[structure.name]}
                  name={structure.name}
                  options={structure.options}
                  value={value}
                  {...dataElement}
                  defaultValue={structure.defaultValue}
                  changeValue={(val) =>
                    changeValue({ name: structure.name, value: val })
                  }
                /> */
                <SelectCustom2
                  error={isSubmit && !!errors && !!errors[structure.name]}
                  options={structure.options}
                    value={dataForm[structure.name]}
                    defaultValue={structure.defaultValue}
                    changeValue={(val) =>
                        changeValue({name: structure.name, value: val})
                    }
                  searchOption={structure.search}
                  actions={actions}
                    dataTest={structure.name}
                    {...dataElement}
                />
            )}
            {structure.type === 'select-create' &&
                <ReactSelect
                    error={isSubmit && !!errors && !!errors[structure.name]}
                    options={structure.options}
                    value={dataForm[structure.name]}
                    defaultValue={structure.defaultValue}
                    extra={structure.extra}
                    changeValue={(val) =>
                        changeValue({name: structure.name, value: val})
                    }
                    dataTest={structure.name}
                    {...dataElement}
                />}
            {structure.type === 'radio' && (
                <RadioCustom
                    name={structure.name}
                    value={dataForm[structure.name]}
                    changeValue={(val) =>
                        changeValue({name: structure.name, value: val})
                    }
                    options={structure.options}
                    format={structure.format}
                    {...dataElement}
                />
            )}
            {structure.type === 'textarea' && (
                <Textarea
                  error={isSubmit && !!errors && !!errors[structure.name]}
                  name={structure.name}
                    value={dataForm[structure.name]}
                    onBlur={onBlur}
                    {...dataElement}
                    changeValue={(val) =>
                        changeValue({name: structure.name, value: val})
                    }
                />
            )}
            {structure.type === 'checkbox' && (
                <CheckboxCustom
                    name={structure.name}
                    value={dataForm[structure.name]}
                    changeValue={(val) =>
                        changeValue({name: structure.name, value: val})
                    }
                    options={structure.options}
                    format={structure.format}
                    {...dataElement}
                />
            )}
            {structure.type === 'checkboxSingle' && (
                <CheckboxSingle
                    name={structure.name}
                    value={dataForm[structure.name]}
                    changeValue={(val) =>
                        changeValue({name: structure.name, value: val})
                    }
                    options={structure.options}
                    format={structure.format}
                    {...dataElement}
                />
            )}
        </>
    )
}

export default FormControlCustom
