import React from 'react'
import NavMenuItem from "./NavMenuItem";
import {HeaderMenuItemsProps} from "./models/Nabar.model";


export const NavItemsMenu: React.FC<HeaderMenuItemsProps> = ({menuItems, ...rest}) => {
    return (
        <div className={'nav-items-menu'}>
            {menuItems?.map((item, i) =>
                <ul data-test="nav-section" data-test-name={item.name} className={'m-0 p-0'} key={i}>
                    <NavMenuItem itemMenu={item} {...rest}/>
                </ul>
            )}
        </div>
    )
}
