export const calendarPikerEN = {
  calendarPiker: {
    action: {
      apply: 'Apply',
      cancel: 'Cancel',
    },
    from: 'From',
    to: 'To',
    period: 'Period',
    today: 'Today',
    yesterday: 'Yesterday',
    last7dayAvailables: 'Last 7 days availables',
    last30dayAvailables: 'Last 30 days availables',
    last7day: 'Last 7 days',
    last30day: 'Last 30 days',
    month: {
      january: 'January',
      february: 'February',
      march: 'March',
      april: 'April',
      may: 'May',
      june: 'June',
      july: 'July',
      august: 'August',
      september: 'September',
      october: 'October',
      november: 'November',
      december: 'December',
    },
    monthMin: {
      january: 'Jan',
      february: 'Feb',
      march: 'Mar',
      april: 'Apr',
      may: 'May',
      june: 'Jun',
      july: 'Jul',
      august: 'Aug',
      september: 'Sep',
      october: 'Oct',
      november: 'Nov',
      december: 'Dec',
    },
    dayMin: {
      su: 'Su',
      mo: 'Mo',
      tu: 'Tu',
      we: 'We',
      th: 'Th',
      fr: 'Fr',
      sa: 'Sa',
    },
  },
}
