export const calendarPikerIT = {
  calendarPiker: {
    action: {
      apply: 'Applica',
      cancel: 'Annulla',
    },
    from: 'Da',
    to: 'a',
    period: 'Periodo',
    today: 'Oggi',
    yesterday: 'Ieri',
    last7dayAvailables: 'Ultimi 7 giorni disponibili',
    last30dayAvailables: 'Ultimi 30 giorni disponibili',
    last7day: 'Ultimi 7 giorni',
    last30day: 'Ultimi 30 giorni',
    month: {
      january: 'Gennaio',
      february: 'Febbraio',
      march: 'Marzo',
      april: 'Aprile',
      may: 'Maggio',
      june: 'Giugno',
      july: 'Luglio',
      august: 'Agosto',
      september: 'Settembre',
      october: 'Ottobre',
      november: 'Novembre',
      december: 'Dicembre',
    },
    monthMin: {
      january: 'Gen',
      february: 'Feb',
      march: 'Mar',
      april: 'Apr',
      may: 'Mag',
      june: 'Giu',
      july: 'Lug',
      august: 'Ago',
      september: 'Set',
      october: 'Ott',
      november: 'Nov',
      december: 'Dic',
    },
    dayMin: {
      su: 'Do',
      mo: 'Lu',
      tu: 'Ma',
      we: 'Me',
      th: 'Gi',
      fr: 'Ve',
      sa: 'Sa',
    },
  },
}
