import React from 'react'

interface LoaderProps {
  className?: string
  size?: string
}

const Loader = ({ className, size }: LoaderProps) => {
  return (
    <div className={`lds-ring ${size ? size : ''}`}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}
export default Loader
