import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import configuration from "../configuration";
import {loadScriptOrIgnore} from "../utils/loadScript";
import {objDeleteProperties} from "../utils/objUtils";

const ConfigLoad: React.FC = ({children}) => {
    const [links, setLinks] = useState<any[]>([])
    const [endLoad, setEndLoad] = useState<boolean>(false)
    useEffect(() => {
        Promise.all([loadScriptOrIgnore(process.env.REACT_APP_LOAD_CONFIG + "base/config.js"), loadScriptOrIgnore(process.env.REACT_APP_LOAD_CONFIG + "config.js")]).then(res => {
            onLoadScript()
        })
    }, [])
    const onLoadScript = () => {
        const configCustom = (window as any).config || {};
        const configBase = (window as any).configBase || {};
        const config = {
            ...configBase,
            ...configCustom,
            theme: {...(configBase.theme || {}), ...(configCustom.theme || {})},
            links: [...(configBase.links || []), ...(configCustom.links || [])]
        }
        console.log('configurazione esterna caricata correttamente', config);
        setLinks(config.links ? config.links.map((el: any) => ({
            ...el,
            href: el?.href?.startsWith('http') ? el.href : process.env.REACT_APP_LOAD_CONFIG + el.href
        })) : [])
        configuration.external = objDeleteProperties(config, ['theme', 'links'])
        if(config.page?.faviconLink){
            const url = config.page.faviconLink;
            const favicon = document?.querySelector('link[rel=icon]')
            if(favicon)
                (favicon as any).href= url.startsWith("http") ? url : process.env.REACT_APP_LOAD_CONFIG + url;
        }
        if(config.page?.baseTitle){
            document.title= config.page.baseTitle
        }
        if (config.theme) {
            configuration.theme = config.theme;
        }
        setEndLoad(true)

    }
    return (
        <>
            {<Helmet link={links}/>}

            {endLoad && children}
        </>
    )
}
export default ConfigLoad
