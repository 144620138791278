import React, {useEffect, useState} from 'react';
import SidebarComponent from "../../libraries/sidebar/SidebarComponent";
import TherapeuticPlan from "./telerehabilitation/therapeuticPlan/TherapeuticPlan";
import SummaryTreatment from "./telerehabilitation/summaryTreatment/SummaryTreatment";
import {LayoutLeftAside} from "../../core/layout/LayoutLeftAside";
import {detailMock} from "./mock/detailMock";
import {useDispatch} from 'react-redux'
import {getCurrentPatientAction, resetCurrentPatient} from "../../store/patientSlice";
import http from "../../shared/utils/http";
import configuration from "../../shared/configuration";
import {useParams} from "react-router-dom";
import {findMoreResourceByReference, findResourceByReference} from "../../shared/hl7/hl7FunctionSearch";
import {PatientDetailModel} from "../../model/PatientDetails";


const PatientDetails = () => {
    const [activeSection, setActiveSection] = useState('summary');
    const {id} = useParams();
    const [patient, setPatient] = useState<PatientDetailModel | undefined>(undefined);
    const sections = detailMock;
    const dispatch = useDispatch()
    useEffect(() => {
        http.get(configuration.external.hl7.httpUrl + '/composition/v1/demo/fhir/Composition?type=urn:ae:patienttreatment:summary|rehab&subject=Patient/' + id).then(res => {
            const entry = res.entry[0];
            const patient = findResourceByReference(entry, 'patient-info', 'Patient');
            const practitioner = findResourceByReference(entry, 'treatment', 'Practitioner')
            const treatments = findMoreResourceByReference(entry, 'treatment', 'CarePlan').map((ele: any) => {
                const url = ele.instantiatesCanonical[0].split('|')[0];
                const extraData = entry?.resource?.contained?.find((ele: any) => ele?.resourceType === "PlanDefinition" && ele?.url === url);
                const sectionsData = extraData.goal.reduce((acc: any, ele: any) => {
                    return {
                        ...acc,
                        [ele.description.coding[0].code.split('-').map((k: string, index: number) => index ? k.charAt(0).toUpperCase() + k.slice(1) : k).join('')]: ele.target[0].detailQuantity.value,
                    }
                }, {})
                const actions = extraData.action.reduce((acc: any, ele: any) => {
                    return {
                        ...acc,
                        [ele.code[0].coding[0].code.split('-').map((k: string, index: number) => index ? k.charAt(0).toUpperCase() + k.slice(1) : k).join('')]: {
                            labelText: ele.code[0].text,
                            timingTiming: ele.timingTiming,
                            timingDuration: ele.timingDuration
                        },
                    }
                }, {})
                const name = extraData?.title || ''
                return {...ele, name, sectionsData, actions}
            })
            const sessions = findMoreResourceByReference(entry, 'sessions-list', 'Observation').map((ele: any) => {
                const data = ele.component.reduce((acc: any, ele: any) => {
                    const key = Object.keys(ele).find(k => k.startsWith('value'))
                    return {
                        ...acc,
                        [ele.code.coding[0].code.split('-').map((k: string, index: number) => index ? k.charAt(0).toUpperCase() + k.slice(1) : k).join('')]: ele[key],
                        ...(ele.code.text !== undefined ? {[ele.code.coding[0].code.split('-').map((k: string, index: number) => index ? k.charAt(0).toUpperCase() + k.slice(1) : k).join('') + 'Translate']: ele.code.text} : {})
                    }
                }, {})
                return {...data, id: ele.id, effectivePeriod: ele.effectivePeriod, status: ele.status}
            })
            const identifier = patient?.identifier?.find((ele: any) => ele?.system === 'urn:ae:defaultpatientregistry:id')?.value
            const data = {
                birthDate: patient.birthDate,
                gender: patient.gender,
                id: patient.id,
                status: patient?.extension?.find((ele: any) => ele.url === 'urn:ae:defaultpatientregistry:status')?.valueString,
                identifier,
                name: patient.name.map((name: any) => name.given.join(' ')).join(' '),
                surname: patient.name.map((name: any) => name.family).join(' '),
                sessions,
                ...(practitioner ? {
                    practitioner: {
                        name: practitioner.name.map((name: any) => name.given.join(' ')).join(' '),
                        surname: practitioner.name?.map((name: any) => name.family).join(' ')
                    }
                } : {}),
                treatments: treatments,
            };
            setPatient(data);
        })
        dispatch(getCurrentPatientAction(id))
        return () => {
            dispatch(resetCurrentPatient())
        }
    }, [])
    const setVoices = (ev, voice) => setActiveSection(voice?.name)
    return (
        <LayoutLeftAside
            leftTemplate={<SidebarComponent currentPage={activeSection} sections={sections} onClickVoice={setVoices}/>}>

            {activeSection === 'summary' && <SummaryTreatment data={patient}/>}
            {activeSection === 'therapeutic_plan' && <TherapeuticPlan data={patient}/>}

        </LayoutLeftAside>
    );
}

export default PatientDetails
