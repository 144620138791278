import {IconSVG} from "../../../../shared/custom/icon/IconSVG";
import React from "react";
import {TableData} from "../../../../shared/table/models/TableProps";
import Tag from "../../../../shared/custom/tag/Tag";
import NumberVal from "../../../../shared/custom/numberVal/NumberVal";
import Spo2Cursor from "../../../../shared/custom/spo2Cursor/Spo2Cursor";
import BtnIconNotify from "../../../../shared/custom/icon/BtnIconNotify";
import MiniBpmChart from "./MiniBpmChart";

export const structureTableHistorical: (t) => TableData[] = (t) => ([
        {
            name: "session",
            label: t('hlv.session'),
        },
        {
            name: "firstBlock",
            label: t('hlv.firstBlock'),
        },
        {
            name: "borg",
            label: t('hlv.borg'),
        },
        {
            name: "spo2_1",
            label: t('hlv.spo2'),
        },
        {
            name: "bpm_1",
            label: t('hlv.bpm'),
        },
        {
            name: "secondBlock",
            label: t('hlv.secondBlock'),
        },
        {
            name: "fatigue",
            label: t('hlv.fatigue'),
        },
        {
            name: "spo2_2",
            label: t('hlv.spo2'),
        },
        {
            name: "bpm_2",
            label: t('hlv.bpm'),
        },
        {
            name: "comment",
        },
    ]
)

export const headerTemplatesTableHistorical = (t) => ({
    firstBlock: (value: string, row: any) => {
        return (
            <div className={"d-flex align-items-center gap-2"}>
                <IconSVG icon={'cyclette'} size={'18px'}/>
                <span>{value}</span>
            </div>
        );
    },
    spo2_1: (value: string, row: any) => {
        return (
            <div className={"d-flex align-items-baseline"}>
                <span>{value}</span><small>({t('hlv.min_avg')})</small>
            </div>
        );
    },
    bpm_1: (value: string, row: any) => {
        return (
            <div className={"d-flex align-items-baseline"}>
                <span>{value}</span><small>({t('hlv.avg')})</small>
            </div>
        );
    },
    secondBlock: (value: string, row: any) => {
        return (
            <div className={"d-flex align-items-center gap-2"}>
                <IconSVG icon={'exercise'} size={'18px'}/>
                <span>{value}</span>
            </div>
        );
    },
    spo2_2: (value: string, row: any) => {
        return (
            <div className={"d-flex align-items-baseline"}>
                <span>{value}</span><small>({t('hlv.min_avg')})</small>
            </div>
        );
    },
    bpm_2: (value: string, row: any) => {
        return (
            <div className={"d-flex align-items-baseline"}>
                <span>{value}</span><small>({t('hlv.avg')})</small>
            </div>
        );
    },
})


