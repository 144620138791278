export const valueSetHl7Name= {
    practitionerRole: 'practitionerRole',
    organizationType: 'organizationType',
    condition: 'condition',
    patientStatus: 'patientStatus',
    carePlan: 'carePlan',
}

export const valueSetSubHl7Name= {
    careplan: 'careplan',
    treatment: 'treatment',
}


export const valueSetHl7 = {
    [valueSetHl7Name.practitionerRole]: (env: string) => ({
        url: `reference=urn:ae:practitionerrole:type`
    }),
    [valueSetHl7Name.organizationType]: (env: string) => ({
        url: `reference=urn:ae:organization:type`
    }),
    [valueSetHl7Name.condition]: (env: string) => ({
        url: `reference=urn:ae:findings:type`
    }),
    [valueSetHl7Name.patientStatus]: (env: string) =>({
        url: `reference=urn:ae:patient:status:type`
    }),
    [valueSetHl7Name.carePlan]: (env: string) => ({
        url: `title=AE-DEFAULT-CAREPLAN-VALUESET`,
        elements: [
            {
                name: [valueSetSubHl7Name.careplan],
                system: `urn:ae:careplan:type`
            },
            {
                name: [valueSetSubHl7Name.treatment],
                system: `urn:ae:treatment:type`
            }
        ]
    }),
}
