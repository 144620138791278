import React, {ForwardedRef, useState} from 'react';
import {TitleHeadingH3} from "../../../shared/custom/headings/TitleHeadingH3";
import RowPatient from "./components/RowPatient";
import RowSessionDate from "./components/RowSessionDate";
import FirstBlock from "./components/FirstBlock";
import SecondBlock from "./components/SecondBlock";
import CommentsNotes from "./components/CommentsNotes";
import ChartRow from "./components/ChartRow";
import {useLanguageForShared} from "../../../shared/utils/languages/useLanguageForShared";
import ModalComment from "../../patientDetails/telerehabilitation/components/modal/ModalComment";
import {useDateCustom} from "../../../shared/custom_use/useDateCustom";
import Tag from "../../../shared/custom/tag/Tag";
import {mapStateColor} from "../../../shared/utils/mapStateColor";

const SessionTelerehabilitation = React.forwardRef(({
                                                        printView,
                                                        setImg,
                                                        img,
                                                        patient,
                                                        patientBis
                                                    }: { printView: boolean, setImg: (data: any) => void, img: string, patient: any, patientBis: any },
                                                    ref: ForwardedRef<any>) => {
    const {t} = useLanguageForShared()
    const [showComment, setShowComment] = useState(undefined);
    const closeModal = () => setShowComment(undefined)
    const {data: {dateFormatGGmmmYYYY}} = useDateCustom()

    return (
        <div className={`col-12 ${printView ? 'print' : ''}`} ref={ref}>
            <TitleHeadingH3 title={
                <div className={'d-flex gap-3 align-items-center'}>
                    {t('hlv.sessionDetailOf')} {patientBis?.session?.effectivePeriod?.start ? dateFormatGGmmmYYYY(patientBis?.session?.effectivePeriod?.start) : '-'} | ID {patientBis?.session?.sessionId || '-'}
                    <Tag classNames={'text-sm'} label={patientBis?.session.sessionCompletionTranslate} bgColor={mapStateColor(patientBis?.session.sessionCompletion)?.bg} color={mapStateColor(patientBis?.session.sessionCompletion)?.color}/>
                </div>
            }/>
            <div className={"d-flex flex-column row-gap-4"}>
                <RowPatient data={patient}/>
                <RowSessionDate session={patientBis?.session}/>
                <ChartRow patientBis={patientBis} printView={printView} setImg={setImg} img={img}/>
                <FirstBlock session={patientBis?.session}/>
                <SecondBlock session={patientBis?.session}/>
                <TitleHeadingH3 title={t('hlv.comment_notes')}/>
                <CommentsNotes
                    printView={printView}
                    data={{vocal: 'https://filesamples.com/samples/audio/mp3/Symphony%20No.6%20(1st%20movement).mp3'}}
                    showComment={setShowComment}/>
            </div>
            {showComment && <ModalComment closeModal={closeModal} comment={showComment}/>}

        </div>
    );
});

export default SessionTelerehabilitation;
