import React from 'react';
import LoggedIn from "./core/LoggedIn";

function App() {
    return (
        <LoggedIn/>
    );
}

export default App;
