import React from "react";

const Tag = ({
               label,
               bgColor,
               classNames,
               color,
               dataElement = {},
               tagTest = "tag-test"
             }: { label: string, bgColor?: string, classNames?: string, color?: string, dataElement?: any, tagTest?: string }) => {
  return (
    <div className={`tag ${classNames}`} {...dataElement} style={{ backgroundColor: bgColor, color: color }}
         data-test={tagTest}>
      {label}
    </div>
  );
};

export default Tag;
