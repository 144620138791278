import React from 'react';
import {useDateCustom} from "../../../../shared/custom_use/useDateCustom";


const LastSectionDateCol = ({
                                date
                            }: { date: string }) => {
    const {data: {dateFormatGGmmmYYYY}} = useDateCustom()

    return (
        <div>
            {date ? dateFormatGGmmmYYYY(date) : '-'}
        </div>
    );
};

export default LastSectionDateCol;
