import React, {useEffect, useRef, useState} from "react";
import {Chart} from "react-google-charts";
import {useLanguageForShared} from "../utils/languages/useLanguageForShared";
import ReactDOM from "react-dom";

export function ChartCustom({
                                dataTable = [],
                                width,
                                height = 150,
                                hAxis = {},
                                vAxis = {},
                                options = {},
                                chartPackages,
                                controls,
                                chartType = "ColumnChart",
                                formatters = [],
                                setImg
                            }:any) {

    const {lang} = useLanguageForShared()
    const [chartWrapper, setChartWrapper ] = useState<any>()
    const calcImage = () => {
        const chart: any = (chartWrapper as any)?.getChart()
        if(chart){
            const image = chart?.getImageURI()
            console.log(image)
            image && setImg(image);
        } else {
            setTimeout(() => calcImage(), 10)
        }

    }
    useEffect(() => {
            if(chartWrapper){
                calcImage()
            }

            // console.log(chartWrapper)
            //
            // const chart = ReactDOM.findDOMNode(chartGraph.current)
            // const img = chart && (chart as any).getImageURI();
            //
            // console.log(img, 'img')

    }, [chartWrapper])
    const chartOptions = {
        pointSize: 5,

        colors: ['#F2726F'],
        fontName: "Open Sans",
        fontSize: 12,
        titleTextStyle: {
            italic: false,
            fontSize: 14,
            color: "#5A5A5A"
        },
        chartArea: {
            width: "100%",
            left: 100,
            right: 10,
            top: 50,
            bottom: 50
        },
        bar: {
            groupWidth: 40
        },
        hAxis: {
            viewWindowMode: "pretty",
            textStyle: {
                italic: false,
                color: "#5A5A5A"
            },
            // direction: -1,
            slantedText: true,
            slantedTextAngle: 45,
            ...hAxis
        },
        vAxis: {
            viewWindow: {
                min: 0,
            },
            titleTextStyle: {
                italic: false,
                color: "#999"
            },
            textStyle: {
                italic: false,
                color: "#5A5A5A"
            },
            baselineColor: "#F2F2F2",
            gridlines: {
                color: "#F2F2F2"
            },
            ...vAxis
        },
        legend: {
            maxLines: 10,
            position: 'top',
            alignment: 'end',
            textStyle: {
                fontSize: 10
            }
        },
        ...options
    }
    return (
        <>
            <Chart chartType={chartType} height={height} width={width} data={dataTable}
                    options={chartOptions}
                    formatters={formatters} chartPackages={chartPackages} controls={controls}
                    chartLanguage={lang}
                    getChartWrapper={chartWrapper => {
                        setImg && setChartWrapper(chartWrapper);
                    }}
            />
        </>

    );
}
