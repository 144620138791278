import React, {useState} from 'react'
import {Sidebar} from "./core/sidebar/Sidebar";
import MenuSidebar from "./core/sidebar/MenuSidebar";
import {sectionModel, voicesModel} from "./models/sectionsModel";

const SidebarComponent: React.FC<{
    token?: string
    currentPage: string
    sections: sectionModel[]
    onClickVoice: (event: React.MouseEventHandler<HTMLDivElement>, voice: voicesModel) => void
}> = ({currentPage, sections, onClickVoice}) => {

    const [toggleSidebar, setToggleSidebar] = useState(true);



    return (
        <>
            <Sidebar
                isVisible={toggleSidebar || false}
                hideSidebarHandler={() =>
                    setToggleSidebar && setToggleSidebar(!toggleSidebar)
                }>
                <MenuSidebar currentPage={currentPage} sections={sections}
                             goToPageHandler={onClickVoice}/>
            </Sidebar>
        </>
    )
}

export default SidebarComponent
