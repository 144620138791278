import {RoutesModel} from "./models/Routes.model";
import Patients from "../../features/patients/Patients";
import PatientDetails from "../../features/patientDetails/PatientDetails";
import OxyView from "../../features/oxyView/OxyView";
import {nameRoutes} from "./nameRoutes";
import ChangeUser from "../../features/changeUser/ChangeUser";

const authList: RoutesModel = {
    list: [
        // {
        //     path: '/',
        //     name: nameRoutes.home,
        //     component: <Home/>
        // },
        {
            path: '/patients',
            name: nameRoutes.patients,
            component: <Patients/>,
            title: 'Patients'
        },
        {
            path: '/patients/:id',
            name: nameRoutes.patientsDetail,
            component: <PatientDetails/>
        },
        {
            path: '/rehab/:id/:patientId',
            name: nameRoutes.sessionDetail,
            component: <OxyView/>
        },
        {
            path: '/changeuser',
            name: nameRoutes.changeUser,
            component: <ChangeUser/>
        }
    ],
    redirect: '/patients'
}

export default authList;
