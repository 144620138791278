import React from 'react';
import LabelValue from "../../../../shared/custom/labelValue/LabelValue";
import NumberVal from "../../../../shared/custom/numberVal/NumberVal";
import ChartData from "./ChartData";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";
import PointLabel from "../../../../shared/custom/pointLabel/PointLabel";
import {telemetryStyleRehab} from "../../../../libraries/patient_list/components/colorData";

const ChartRow = ({printView, setImg, img, patientBis}: { patientBis: any, printView?: boolean, setImg?: (data: string) => void, img?: string }) => {
    const {t} = useLanguageForShared()
    const templateFatigue = (value) => (<>
        <div className={"d-flex flex-column gap-2"}>
            {value.map((ele, i) => (
                <PointLabel key={i} label={ele?.name.toUpperCase()} title={ele?.title}
                            pointColor={telemetryStyleRehab()[+ele?.val]?.bg}/>
            ))}
        </div>
    </>)

    return (
        <div className={'d-flex gap-3'}>
            <img style={{width: '940px', display: printView ? 'block' : 'none'}} src={img}/>
            <div style={{width: "100%", height: '100%', display: printView ? 'none' : 'block'}}>
                <ChartData patientBis={patientBis} setImg={setImg}/>
            </div>
            <div className={'d-flex gap-3 pt-3'}>
                <LabelValue label={t('hlv.borg_scale')}
                            value={<NumberVal val={'8/10'} size={'lg'} bgColor={telemetryStyleRehab()['1']?.bg}
                                              color={telemetryStyleRehab()['1']?.color}/>}
                            labelClass={'sm text-nowrap pb-3'}
                            valueClass={'bold'}/>
                <LabelValue label={t('hlv.fatigue_scale')}
                            value={templateFatigue([
                                {name: 'q1', title: 'asd', val: 1},
                                {name: 'q2', title: 'asd', val: 5},
                                {name: 'q3', title: 'asd', val: 3}
                            ])}
                            labelClass={'sm text-nowrap pb-3'}
                            valueClass={'bold'}/>
            </div>
        </div>
    );
};

export default ChartRow;
