import React from 'react';
import {ChartCustom} from "../../../../shared/chartcustom/ChartCustom";
import configuration from "../../../../shared/configuration";
import {BlockBpm, ValuesModel} from "../../../../model/PatientDetails";

const MiniBpmChart = ({flow, avg}: { flow: BlockBpm, avg: ValuesModel }) => {


    const avgVal = avg.value
    const flowChart = flow.data.split(' ').map((ele, i) => ([i, +ele, avgVal]))

    const data = [
        ["Date", "Value", "avg"],
        ...flowChart
    ];

    const options = {
        pointSize: 0,
        hAxis: {
            textStyle: {
                color: "transparent"
            },
            baselineColor: "transparent",
            minorGridlines: {
                color: "transparent"
            },
            gridlines: {
                color: "transparent"
            },
        },
        vAxis: {
            textStyle: {
                color: "transparent"
            },
            minorGridlines: {
                color: "transparent"
            },
            gridlines: {
                color: "transparent"
            },
            baselineColor: "transparent",

        },
        vAxes: {},
        tooltip: {
            trigger: 'none'
        },
        colors: [configuration.theme.series.s700, "#C3C9FF"],
        chartArea: {top: -10, left: 0, right: 0, bottom: -10},
        legend: {position: "none"},
    }
    return (<div className={'d-flex gap-1 align-items-center'}>
            <ChartCustom dataTable={data} options={options} chartType="LineChart" formatters={null} height={'40px'}
                         width={'100px'}/>
            <small style={{fontSize: '12px'}}>{avgVal}</small>
        </div>
    );
};

export default MiniBpmChart;
