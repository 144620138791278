import React, {Fragment, useState} from 'react';
import AlertRow from "../alert/AlertRow";
import httpData from "../../../../shared/utils/httpData";
import CheckBodyTemplate from "../../template/body/CheckBodyTemplate";
import PatientBodyTemplate from "../../template/body/PatientBodyTemplate";
import PersonalCareBodyTemplate from "../../template/body/PersonalCareBodyTemplate";
import StateBodyTemplate from "../../template/body/StateBodyTemplate";
import ScoreBodyTemplate from "../../template/body/ScoreBodyTemplate";
import CarePlanBodyTemplate from "../../template/body/CarePlaneBodyTemplate";
import PathologyBodyTemplate from "../../template/body/PathologyBodyTemplate";
import TreatmentsBodyTemplate from "../../template/body/TreatmentsBodyTemplate";
import DeviceBodyTemplate from '../../template/body/DeviceBodyTemplate';
import ActionsBodyTemplate from "../../template/body/ActionsBodyTemplate";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";
import LastSessionDateBodyTemplate from "../../template/body/LastSessionDateBodyTemplate";
import LastSessionStatusBodyTemplate from "../../template/body/LastSessionStatusBodyTemplate";

const PatientItem = ({patient, listTableConfig, clickRow}: { patient: any, listTableConfig: any, clickRow?: (data: any) => void}) => {
    const {t} = useLanguageForShared()
    const [showActionBtn, setShowActionBtn] = useState(false);
    const [checkPatient, setCheckPatient] = useState(false); //mock


    const selectPatient = () => {
        setCheckPatient(!checkPatient)
    }

    const goToDetailPatient = () => {
        // console.log(`${httpData.other.projectUrl}/sleep/logbook?id=${patient.identifier}&orgid=${httpData.other.organizationId}`)
        if(clickRow){
            clickRow(patient)
        } else {
            window.location.href = `${httpData.other.projectUrl}sleep/logbook?id=${patient.identifier}&orgid=${httpData.other.organizationId}`
        }

    }

    const templateBody = {
        check: () => <CheckBodyTemplate checkPatient={checkPatient} selectPatient={selectPatient} />,
        patient: () => <PatientBodyTemplate patient={patient} />,
        personalCare: ()  => <PersonalCareBodyTemplate patient={patient}/>,
        state: ()  => <StateBodyTemplate patient={patient}/>,
        score: () => <ScoreBodyTemplate patient={patient}/>,
        carePlan: () => <CarePlanBodyTemplate patient={patient}/>,
        pathology: () => <PathologyBodyTemplate patient={patient}/>,
        treatments: () => <TreatmentsBodyTemplate patient={patient}/>,
        lastSessionDate: () => <LastSessionDateBodyTemplate patient={patient}/>,
        lastSessionStatus: () => <LastSessionStatusBodyTemplate patient={patient}/>,
        device: () => <DeviceBodyTemplate patient={patient}/>,
        action: () => <ActionsBodyTemplate showActionBtn={showActionBtn} goToDetailPatient={goToDetailPatient} />,
    }

    return (
        <div className={`row-list`}
             onClick={goToDetailPatient}
             onMouseOver={() => {
                 setShowActionBtn(true)
             }}
             onMouseOut={() => {
                 setShowActionBtn(false)
             }}>
            <div className={'col-list'}>
                {
                    listTableConfig.map((conf, i) => <Fragment key={i}>{conf.templateIntegralBody ? templateBody[conf.name](): <div className={'col-item ' + conf.elementsHeaderBody.className}>
                        {templateBody[conf.name]()}
                    </div>}</Fragment>)
                }
            </div>

            <div className={'alert-component'}>
                <AlertRow patient={patient}/>
            </div>
        </div>
    );
};

export default PatientItem;
