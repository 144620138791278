export const loadScript = (url: string) => new Promise((resolve, reject) => {
        try{
            const script = document.createElement("script");
            script.src = url;
            script.async = true;
            script.onload = () => {
                resolve(true)
            };
            script.onerror = (err) => {
                console.error('errore caricamento configurazione, path js non corretto '+ url);
                reject(true)
            }
            document.body.appendChild(script);
        }catch (err){
            console.log('errore caricamento configurazione, path js non corretto '+ url);
            reject(true)
        }
    })

export const loadScriptOrIgnore = (url: string) => new Promise((resolve, reject) => loadScript(url).then(res => resolve(true), err => resolve(true)))
