import React, {ReactChild} from 'react';
import LabelValue from "../custom/labelValue/LabelValue";
import PopoverCustomClick from "../external_lib/popover/PopoverCustomClick";
import configuration from "../configuration";
import { IconSVG } from "../custom/icon/IconSVG";

const InfoIcon = ({
                      label,
                      value,
                      icon,
                      className = '',
                      style,
                      titlePop,
                      contentPop
                  }: {
    label: string, value: string, icon: string, className?: any, style?: any, titlePop?: string | ReactChild
    contentPop?: string | ReactChild
}) => {

    return (
        <PopoverCustomClick
            positions={['top', 'bottom', 'left', 'right']}
            content={<div>
                <div>{titlePop}</div>
                <div>{contentPop}</div>
            </div>}
        >
                   <span className="d-flex icon-label tooltip-content">
                    <div className={`d-flex me-3`} style={style}>
                    <IconSVG icon={icon} size={'20px'} className={'me-2'}  tintColor={configuration.theme.primary} />
                    <LabelValue label={label}
                                value={value}
                                className={'mb-0 '}
                                labelClass={`lg medium text-primary-dark pointer ${className}`}
                                valueClass={'sm medium text-dark'}/>
        </div>
                    </span>
        </PopoverCustomClick>

    );
};

export default InfoIcon;
