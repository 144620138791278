

const userManager = {
    authority: process.env.REACT_APP_AUTORITY,
    clientId: process.env.REACT_APP_CLIENT_ID,
    redirectUri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/callback`,
    responseType: "code",
    scope: process.env.REACT_APP_SCOPE,
    postLogoutRedirectUri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/logout`,
    silentRedirectUri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/silent_renew`,
    automaticSilentRenew: true,
};



export default userManager;
