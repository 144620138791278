import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit'
import generalSettingsReducer from './generalSettingsSlice'
import errorsReducer from './errors/errorSlice'
import loadReducer from '../custom/loader/loaderFullscreen/loadSlice'
import {additionalReducers} from "../../store/storeAddReducer";
import translateHl7Slice from "../hl7/translateHl7Slice";
export const store = configureStore({
  reducer: {
    generalSettings: generalSettingsReducer,
    errors: errorsReducer,
    load: loadReducer,
    translateHl7: translateHl7Slice,
    ...additionalReducers
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
