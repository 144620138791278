import moment from "moment";
import {it} from "./locale/it"

export const settings: any = {
    it: {
        moment: it(moment),
        other: {
            LT : "HH:mm",
            L : "dd/MM/YY",
            LL : "d MMM YY",
            LLL : "d MMM YY HH:mm",
            LLLL : "dddd, D MMMM YY LT",
            fullDateWithMinute: "d MMM YY HH:mm",
            fullDateWithSeconds: "d MMM YY HH:mm:ss",
        }
    }
}

export const setMomentLocale = (lang: string) => {
    moment.locale(lang, settings[lang]? [settings[lang].moment] : undefined)
}
