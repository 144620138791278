import React, {useEffect} from 'react';
import { useAuth } from 'oidc-react';
import {Route, Routes, useNavigate} from "react-router-dom"

const CallbackPage = () => {
    const navigate = useNavigate()
    useEffect(() => {
        // navigate('/')
    }, [])
    return <></>

};

export default CallbackPage;
