import React from 'react';

const Legend = ({list}: { list: { label: string, color: string }[] }) => {
    return (
        <div>

            <div className={'d-flex pt-3 justify-content-end'}>
                {list.map((ele, key) => (
                    <div className={'d-flex align-items-center me-4'} key={key}>
                        <div className={'me-2'}
                             style={{backgroundColor: ele.color || "#37b7f3", width: "20px", height: "5px"}}></div>
                        <small>{ele.label}</small>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Legend;
