import React from 'react'

interface InputProps {
    error?: boolean
    format?: string
    name: string
    value: any
    options: any[]
    changeValue: (data: any, event: React.ChangeEvent<HTMLInputElement>) => void
    display: string
}

const CheckboxSingle = ({
                            changeValue,
                            name,
                            error,
                            value,
                            options,
                            display,
                            ...rest
                        }: InputProps) => {
    const classArray = ['form-control', ...(error ? ['error'] : [])]

    return (
        <div className={display}>
            {options.map((ele, i) => {
                return (
                    <div className="shared-checkbox check-item check-item-single" key={i}>
                        <input
                            id={`check-${ele.label}-${i}`}
                            className={'checkbox-sh'}
                            type="checkbox"
                            value={ele.value}
                            name={name}
                            checked={
                                rest.format === 'boolean' ? value === true : value === ele.value
                            }
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                const val =
                                    rest.format === 'boolean'
                                        ? event.target.checked
                                        : (event.target.checked ? (rest.format === 'number' ? +event.target.value : event.target.value) : undefined)
                                changeValue(val, event)
                            }}
                        />
                        <label htmlFor={`check-${ele.label}-${i}`} className="ms-2 pointer">
                            {ele.label}
                        </label>
                    </div>
                )
            })}
        </div>
    )
}

export default CheckboxSingle
