import React from 'react';
import {ChartWithZoom} from "../../../../shared/chartcustom/ChartWithZoom";
import {ChartCustom} from "../../../../shared/chartcustom/ChartCustom";
import configuration from "../../../../shared/configuration";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";
import {PatientDetailModel} from "../../../../model/PatientDetails";

const ChartDataSessions = ({patient}: {patient: PatientDetailModel}) => {
    const sessions = patient?.sessions || [];
    const count = sessions.reduce((acc: any, ele) => {
        return {...acc, [ele.sessionCompletion]: acc[ele.sessionCompletion]+1}
    }, {completed: 0, interrupted: 0})
    const totalSessions = patient.treatments[0].sectionsData.totalSessions;
    const nodataSession = totalSessions- count.completed -count.interrupted
    const {t} = useLanguageForShared()

    const data = [
        [t('hlv.completed'), t('hlv.interrupted')],
        [t('hlv.completed'), count.completed],
        [t('hlv.interrupted'), count.interrupted],
        [t('hlv.notPerformed'), nodataSession]
    ];

    return (
        <div className={'position-relative'}>
            <ChartCustom dataTable={data} chartType="PieChart" formatters={null} width={"100%"} height={"240px"}
                         options={{
                             pieHole: 0.8,
                             pieSliceTextStyle: {
                                 color: 'transparent',
                             },
                             colors: [configuration.theme.series.success100_hex || 'green', configuration.theme.series.alert100_hex || 'yellow', configuration.theme.series.inactive || 'gray'],
                             chartArea: {top: 20, left: 80, right: 80, bottom: 60},
                             sliceVisibilityThreshold: 0,
                             legend: {position: 'labeled'},
                         }}/>
            <div className={'position-absolute'}
                 style={{
                     top: '0',
                     width: '100%',
                     height: "210px",
                     display: 'flex',
                     alignItems: 'center',
                     justifyContent: 'center'
                 }}>
                <b>{count.completed  + count.interrupted }/{totalSessions}</b>
            </div>
        </div>
    );
};

export default ChartDataSessions;
