import React from 'react';

const TitleMenuVoice = ({title}: { title: string }) => {
    return (
        <div className={'title-menu'}>
            {title}
        </div>
    );
};

export default TitleMenuVoice;
