import React from 'react';
import {telemetryStyle, telemetryStyleRehab} from "../colorData";

const StatusBar = ({compliance = [], complianceType}: any) => {
    const isRehab = complianceType === 'urn:ae:rehab:observation';


    const checkStyle = (val: string) => {
        return {
            width: '10px',
            background: isRehab ? telemetryStyleRehab()[+val]?.bg : telemetryStyle()[+val]?.bg
        }
    }

    return (
        <div className={'status-bar'}>
            {compliance?.map((ele: string, i:number) => (
                <div key={i} className={'status-bar-item'} style={checkStyle(ele)}></div>
            ))}
        </div>
    );
};

export default StatusBar;
