import React from 'react';
import IconLabel from "../icon/IconLabel";

const BtnList = ({listBtn}: { listBtn: { label: string, icon: string, actionHandler: () => void }[] }) => {
    return (
        <div className={'d-flex gap-3 align-items-center flex-wrap'}>
            {listBtn?.map((btn, i) => (
                <IconLabel key={i} icon={btn.icon} label={btn.label} className={'pointer'}
                           onClick={btn.actionHandler}/>
            ))}
        </div>
    );
};

export default BtnList;
