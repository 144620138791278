import React from 'react';
import StatusBar from "./StatusBar";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";

const StatePlan = ({status, compliance = [], complianceType}: { status?: string, complianceType?: string, compliance?: string[] }) => {
    const {t} = useLanguageForShared()

    return (
        <div className={'state-plan'}>
            <StatusBar complianceType={complianceType} compliance={compliance}/>
            {status && <small>{status}</small>}
        </div>
    );
};

export default StatePlan;
