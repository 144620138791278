import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk, RootState } from '../redux/store'
import {getValueSetHl7} from "./hl7.service";
import {valueSetHl7} from "./valueSet.hl7";
import configuration from "../configuration";

export interface TranslateHl7Model {
  translations: any
  sector: string;
  baseUrlValueSet: string
}

const initialState: TranslateHl7Model = {
  translations: {},
  sector: 'demo',
  baseUrlValueSet: ''
}

export const translateHl7Slice = createSlice({
  name: 'generalSettings',
  initialState,
  reducers: {
    addTranslation: (state, action: PayloadAction<any>) => {
      state.translations = {...state.translations, ...action.payload}
    },
    setSectorHl7: (state, action: PayloadAction<string>) => {
      state.sector =  action.payload
    },
    setBaseUrlValueSet: (state, action: PayloadAction<string>) => {
      state.baseUrlValueSet =  action.payload
    }
  },
})

export const {
  addTranslation,
    setBaseUrlValueSet,
    setSectorHl7
} = translateHl7Slice.actions


export const setTranslationHl7 = (arrayValueSet: { name: string }[], callback: any): AppThunk => (dispatch, getState) => {
  Promise.all(arrayValueSet.map(ele => new Promise(resolve => {
    const conf: any = valueSetHl7[ele.name](configuration.external.hl7.section);
    getValueSetHl7(configuration.external.hl7.section, conf.url).then(res => {
      const elements = res.entry? (res.entry[0]?.resource?.compose?.include || [])  : [];
      if(conf.elements){
        resolve(conf.elements.map((el: any) => ({...el, data: elements.find((ele: any) => ele.system === el.system)})).reduce((acc: any, ele: any) => {
          return {
            ...acc,
            [ele.name]: ele.data?.concept
          }
        }, {}))
      } else {
        const element = elements && elements.length? elements[0].concept : [];
        resolve({[ele.name]: element})
      }


    })
  }))).then(res =>{
   dispatch(addTranslation(res.reduce((acc: any, ele: any) => ({...acc, ...ele}),{})))
    callback && callback()
  } )

}

export const selectTranslationHl7 = (state: RootState) => state.translateHl7.translations
export const selectBaseUrlValueSetHl7 = (state: RootState) => state.translateHl7.baseUrlValueSet
export const selectSectorHl7 = (state: RootState) => state.translateHl7.sector


export default translateHl7Slice.reducer
