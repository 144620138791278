import React from 'react';
import ChartDataLine from "./ChartDataLine";
import ChartDataSessions from "./ChartDataSessions";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";
import {PatientDetailModel} from "../../../../model/PatientDetails";

const ChartRow = ({patient}: {patient: PatientDetailModel}) => {
    const {t} = useLanguageForShared()

    return (
        <div className={'row'}>
            <div className="col-12 col-lg-8">
                <h5>
                    {t('hlv.sessions_chart')}
                </h5>
                <ChartDataLine patient={patient}/>
            </div>
            <div className="col-12 col-lg-4 text-center">
                <h5>
                    {t('hlv.sessions_status')}
                </h5>
                <ChartDataSessions patient={patient}/>
            </div>
        </div>
    );
};

export default ChartRow;
