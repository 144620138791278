import React from 'react';
import Dropdown from "../../../../bootstrap/dropdown/Dropdown";
import Checkbox from "../../../../bootstrap/form/checkbox/Checkbox";
import {useLanguageForShared} from "../../../../utils/languages/useLanguageForShared";

const ColumnFilter = ({allColumns, columnsHide, setColumnsHide}:
                          {
                              allColumns: any[],
                              columnsHide: any[],
                              setColumnsHide: (values: any[]) => void
                          }
) => {
    const {t} = useLanguageForShared()

    const listDrop = <>
        {allColumns.filter(ele => ele.label).map((ele, key) => <li key={key}><Checkbox name={ele.name} checked={!columnsHide.includes(ele.name)}
                                                              label={ele.label}
                                                              onChange={(e) => setColumnsHide(
                                                                  columnsHide.includes(e.target.value) ? columnsHide.filter(ele => ele !== e.target.value) : [...columnsHide, ele.name]
                                                              )}
                                                              value={ele.name}></Checkbox>
        </li>)}
    </>

    return (
        <Dropdown list={listDrop} className={'filter-column'} showToggle
                  position={'right'}>{t('common.column')}</Dropdown>
    );
};

export default ColumnFilter;
