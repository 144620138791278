import React, { ReactChild } from "react";
import { mergeClass } from "../../functions/function";
import { classByArray } from "../../utils/htmlUtils";

const LabelValue = ({
                      label,
                      value,
                      inline,
                      labelClass,
                      valueClass,
                      children, className
                    }: { label: string | ReactChild, value?: string | ReactChild, inline?: boolean, labelClass?: string, valueClass?: string, children?: ReactChild, className?: string }) => {

  const labelValueStyle = mergeClass("label-value", classByArray([inline ? "inline" : "", className ? className : ""]));
  const labelStyle = mergeClass("sh-label", labelClass ? labelClass : undefined);
  const valueStyle = mergeClass("value", valueClass ? valueClass : undefined);

  return (
    <div className={labelValueStyle}>
      <div className={labelStyle}>{label}</div>
      <div className={valueStyle}>{children && <span className={"me-2"}>{children}</span>}<span>{value}</span>
      </div>
    </div>
  );
};

export default LabelValue;
