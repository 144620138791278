import React, {ReactChild} from "react";
import LabelValue from "../custom/labelValue/LabelValue";
import {IconSVG} from "../custom/icon/IconSVG";

const IconLabelDetail = ({
                             icon,
                             iconSize = "20px",
                             label,
                             value
                         }: { icon: string, iconSize?: string, label: string | ReactChild, value: string | ReactChild }) => {

    return (
        label ? <div className={"d-flex"}>
            <IconSVG icon={icon} size={iconSize} className={"me-2"}/>
            <LabelValue label={label} className={"mb-0 "}
                        value={value}
                        labelClass={"lg medium text-primary-dark"} valueClass={"sm medium"}/>
        </div> : null
    );
};

export default IconLabelDetail;
