import React from 'react';
import StatusTagVoice from "./StatusTagVoice";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";
import {voicesModel} from "../../models/sectionsModel";

interface ItemMenuVoiceProps {
    voice: voicesModel,
    activeItem?: boolean,
    clickItemHandler: (event: React.MouseEventHandler<HTMLDivElement>, voice: voicesModel) => void
}

const ItemMenuVoice: React.FC<ItemMenuVoiceProps> = ({
                                                         voice,
                                                         activeItem,
                                                         clickItemHandler
                                                     }) => {
    const {t} = useLanguageForShared()

    return (
        <div className={`item-menu ${activeItem ? 'active' : 'pointer'}`}
             onClick={(event: any) => clickItemHandler(event, voice)}>
            {t(`sidebarMenu.nav.voices.${voice.name}`)}
            {voice?.status && <span className={'ms-2'}><StatusTagVoice status={voice.status}/></span>}
        </div>
    );
};

export default ItemMenuVoice;
