import {calendarPikerEN} from "./calendar_en";

export const generalEN = {
    ...calendarPikerEN,
    action: {
        select: 'Select',
        continue: 'Continue',
        cancel: 'Cancel',
        back: 'Back',
        confirm: 'Confirm',
        close: 'Close',
        orderBy: 'Order by',
        delete: 'Cancel',
        edit: 'Edit',
        save: 'Save',
        profile: 'Profile',
        admin: 'Admin',
        logout: 'Logout',
    },
    form: {
        action: {
            select: 'Select',
            addFilters: 'Add filters',
            noFilters: 'No filters to add',
        },
    },
}
