import React, {ReactChild, useState} from "react";
import {Sidebar} from "./Sidebar";
import {SubNavbar} from "./SubNavbar";
import {SubNav} from "../SubNav";

export const NavbarCollapse = ({
                                   logo,
                                   navLeft,
                                   navRight,
                                   navCenter,
                                   sidebarRight,
                                   subNav,
                                   setToggleSidebar,
                                   toggleSidebar,
                                   isExpand,
                                   setIsExpand,
                                   bodyScroll,
                                   goBack,
                                   innerTemplateSubNav,
                                 showExpand
                               }: {
        logo: ReactChild;
        navLeft: ReactChild;
        navRight: ReactChild;
        navCenter?: ReactChild;
        sidebarRight: ReactChild;
        subNav?: ReactChild;
        setToggleSidebar?: (toggleSidebar: boolean) => void;
        toggleSidebar?: boolean;
        isExpand?: boolean;
        setIsExpand?: (isExpand: boolean) => void,
        bodyScroll?: boolean,
        goBack?: () => void
        innerTemplateSubNav?: ReactChild
        showExpand?:boolean
    }) => {
        const [isNavCollapsed, setIsNavCollapsed] = useState(true);
        const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

        return (
            <>
                <nav
                    data-test="navbar-sh"
                    className={`sh navbar navbar-expand-lg ${bodyScroll ? 'sticky-subHeader' : ''} ${(subNav && isNavCollapsed) ? 'var-height' : ''}`}>
                    <div className="container-fluid info-nav pb-2">
                        <a className="navbar-brand me-5">{logo}</a>
                        <button
                            className="navbar-toggler ms-auto"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent"
                            aria-expanded={!isNavCollapsed ? true : false}
                            aria-label="Toggle navigation"
                            onClick={handleNavCollapse}>
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div
                            className={`${isNavCollapsed ? "collapse" : ""} navbar-collapse`}
                            data-test={`navbar-collapse-${isNavCollapsed ? "on" : "off"}`}
                            id="navbarSupportedContent" >
                            <div className="nav-content" data-test="nav-content">
                                <div className="col-auto left" data-test="nav-left">
                                    {navLeft}
                                </div>
                                <div className="col center" data-test="nav-center">{navCenter}</div>
                                <div className="col-auto right" data-test="nav-right">
                                    <div className="ms-auto nav-right">{navRight}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {subNav && <SubNavbar isExpand={isExpand} setIsExpand={setIsExpand} showExpand={showExpand}>
                        <SubNav goBack={goBack} isExpand={isExpand} innerTemplate={innerTemplateSubNav}>
                            {subNav}
                        </SubNav>
                    </SubNavbar>}
                    {sidebarRight && (
                        <Sidebar
                            isVisible={toggleSidebar || false}
                            hideSidebarHandler={() =>
                                setToggleSidebar && setToggleSidebar(!toggleSidebar)
                            }>
                            {sidebarRight}
                        </Sidebar>
                    )}
                </nav>
            </>
        );
    }
;
