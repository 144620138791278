import React, {ReactChild} from 'react';

const ActionTopBar = ({
                          leftTemplate,
                          centerTemplate,
                          rightTemplate
                      }: { leftTemplate?: ReactChild, centerTemplate?: ReactChild, rightTemplate?: ReactChild }) => {
    return (
        <div className={'action-top-bar'}>
            <div className={'left'}>
                {leftTemplate}
            </div>
            <div className={'center'}>
                {centerTemplate}
            </div>
            <div className={'right'}>
                {rightTemplate}
            </div>
        </div>
    );
};

export default ActionTopBar;
