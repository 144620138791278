import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk, RootState } from './store'
import {setLanguageShared} from "../utils/languages/setLanguageShared";
import i18n, {updateI18n} from "../utils/languages/config/i18n";

export interface GeneralSettingsModel {
  token?: string
  organizationId?: string
  patientId?: string
  language: string
}

const initialState: GeneralSettingsModel = {
  language: 'it',
}

export const generalSettingsSlice = createSlice({
  name: 'generalSettings',
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload
    },
    setOrganizationId: (state, action: PayloadAction<string>) => {
      state.organizationId = action.payload
    },
    setPatientId: (state, action: PayloadAction<string>) => {
      state.patientId = action.payload
    },
    setLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload
    },
  },
})

export const {
  setToken,
  setOrganizationId,
  setLanguage,
  setPatientId
} = generalSettingsSlice.actions

export const setInitConfig = (): AppThunk => (dispatch) => {}
export const setTokenAction = (token: string): AppThunk => (dispatch) => {
  localStorage.setItem('token', token)
  dispatch(setToken(token))
}
export const setLanguageAction = (language: string, ignoreMoment: boolean = false): AppThunk => (dispatch) => {
  updateI18n(language, () => {
    setLanguageShared(language, ignoreMoment);
    i18n.changeLanguage(language)
    dispatch(setLanguage(language))
  })
}

export const selectToken = (state: RootState) => state.generalSettings.token
export const selectPatientId = (state: RootState) => state.generalSettings.token
export const selectLanguage = (state: RootState) =>
  state.generalSettings.language

export default generalSettingsSlice.reducer
