import React, {Fragment} from 'react';
import TreatmentItem from "./TreatmentItem";
import StatePlan from "../statusBar/StatePlan";


const TreatmentCol = ({
                          treatments,
                          compliance,
                          complianceType
                      }: { compliance: string[], complianceType?: string, treatments: { name: string, date: string, status?: string }[] }) => {
    return (
        <div className={'treatment-list'}>
            {treatments?.map((ele, key) => (
                <Fragment key={key}>
                    <TreatmentItem treatment={ele}/>
                    <div className={'mt-1'}>
                        <StatePlan compliance={compliance} complianceType={complianceType}
                                   status={ele?.status}/>
                    </div>
                </Fragment>
            ))}
        </div>
    );
};

export default TreatmentCol;
