import React from 'react';
import LabelValue from "../../../../shared/custom/labelValue/LabelValue";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";
import {useDateCustom} from "../../../../shared/custom_use/useDateCustom";

const RowSessionDate = ({session}: { session: any }) => {
    const {data: {dateFormatGGmmmYYYYhhMM}} = useDateCustom()
    const {t} = useLanguageForShared()

    return (
        <div className={"d-flex flex-column gap-1"}>
            <div className={'d-flex gap-5 row-gap-1 flex-wrap'}>
                <div style={{minWidth: '288px', maxWidth: '288px'}}>
                    <LabelValue inline label={t('hlv.summary_sessions')}
                                value={session?.effectivePeriod?.start ? dateFormatGGmmmYYYYhhMM(session?.effectivePeriod?.start) : '-'}
                                labelClass={'sm'}
                                valueClass={'bold'}/>
                </div>
                <div style={{minWidth: '288px', maxWidth: '288px'}}>
                    <LabelValue inline label={t('hlv.total_duration')}
                                value={'01:55:32'} labelClass={'sm'}
                                valueClass={'bold'}/>
                </div>
            </div>
            <div className={"d-flex flex-wrap gap-5 row-gap-1 flex-wrap"}>
                <div style={{minWidth: '120px', maxWidth: '120px'}}>
                    <LabelValue label={t('hlv.spo2_min')} value={session?.block1Spo2Min?.value || '-'} labelClass={'sm'}
                                valueClass={'bold'}/>
                </div>
                <div style={{minWidth: '120px', maxWidth: '120px'}}>
                    <LabelValue label={t('hlv.spo2_avg')} value={session?.block1Spo2Avg?.value || '-'} labelClass={'sm'}
                                valueClass={'bold'}/>
                </div>
                <div style={{minWidth: '120px', maxWidth: '120px'}}>
                    <LabelValue label={t('hlv.spo2_max')} value={session?.block1Spo2Max?.value || '-'} labelClass={'sm'}
                                valueClass={'bold'}/>
                </div>
                <div style={{minWidth: '120px', maxWidth: '120px'}}>
                    <LabelValue label={t('hlv.desaturation')} value={session?.block1Odi || '-'} labelClass={'sm'}
                                valueClass={'bold'}/>
                </div>
                <div style={{minWidth: '120px', maxWidth: '130px'}}>
                    <LabelValue label={t('hlv.bpm_min')} value={session?.block1BpmMin?.value || '-'} labelClass={'sm'}
                                valueClass={'bold'}/>
                </div>
                <div style={{minWidth: '120px', maxWidth: '130px'}}>
                    <LabelValue label={t('hlv.bpm_avg')} value={session?.block1BpmAvg?.value || '-'} labelClass={'sm'}
                                valueClass={'bold'}/>
                </div>
                <div style={{minWidth: '120px', maxWidth: '130px'}}>
                    <LabelValue label={t('hlv.bpm_max')} value={session?.block1BpmMax?.value || '-'} labelClass={'sm'}
                                valueClass={'bold'}/>
                </div>
            </div>
        </div>
    );
};

export default RowSessionDate;
