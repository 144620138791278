import React from 'react'

import FooterComponent from "../../libraries/footer/FooterComponent";
import HeaderComponent from "../../libraries/header/HeaderComponent";
import {AuthContextProps} from "oidc-react/build/src/AuthContextInterface";
import configuration from "../../shared/configuration";
import authList from "../routes/authList";
import {useRoute} from "../routes/useRoute";
import SubPatientNav from "../../libraries/header/components/navbar/SubPatientNav";
import {PatientModel} from "../../libraries/header/models/Patient.model";
import {HeaderMenuModel} from "../../libraries/header/models/Header.model";
import {subPatientDataNameSections} from "../../libraries/header/components/navbar/data/subPatientData";
import {selectCurrentOrganization, selectOrganizations} from "../../store/organizationSlice";
import {useSelector} from 'react-redux'
import {selectCurrentPatient} from "../../store/patientSlice";
import SubPatientNavHl7 from "../../libraries/header/components/navbar/SubPatientNavHl7";

const LayoutBase = ({
                        auth,
                        children
                    }: { auth: AuthContextProps, children: React.ReactNode }) => {
    const logo = `${process.env.REACT_APP_LOAD_CONFIG}assets/images/logo/logo${"_hlv"}.svg`;
    const footerString = `${configuration?.external?.footerLabel || ""}`;
    const currentPatient = useSelector(selectCurrentPatient);
    const isSubNav: boolean = !!currentPatient;
    const logout = () => {
        return auth.signOutRedirect()
    }
    const user = {
        firstname: auth?.userData?.profile?.family_name,
        lastname: auth?.userData?.profile?.given_name,
        email: auth?.userData?.profile?.email
    }
    const voices = authList.list.filter(ele => ele.title)
    const {changeRoute} = useRoute();

    const organizations = useSelector(selectOrganizations)
    const currentOrganization = useSelector(selectCurrentOrganization)

    console.log('---------currentPatient----------', currentPatient)

    return (
        <div className={'layout'}>
            <HeaderComponent hideSubPatientNavElements={[subPatientDataNameSections.devices]} logout={logout}
                             languages={['it', 'en']}
                             voices={voices} user={user}
                             templateVoices={null}
                             subHeaderHtml={isSubNav &&
                                 <SubPatientNavHl7 hideSubPatientNavElements={[subPatientDataNameSections.devices]}
                                                   patientDetails={currentPatient}/>}
                             innerTemplateSubNav={null}
                             currentPage={''}
                             organizations={organizations}
                             currentOrganization={currentOrganization}
                             goProfile={() => null}
                             changePage={(page) => changeRoute(page.name)}
                             logo={logo}
                             backHostnames={[process.env.REACT_APP_URL_LSI]}
                             editProfile={true}
                             showExpand={false}/>
            <main className={"flex-shrink-0"}>
                <div className={"container-fluid h-100"}>
                    {children}
                </div>
            </main>
            <FooterComponent customerName={footerString}/>
        </div>
    )
}

export default LayoutBase
