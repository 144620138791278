
import Tag from "../../../../shared/custom/tag/Tag";
import React from "react";
import configuration from "../../../../shared/configuration";

const StatusTagVoice: React.FC<{ status: string }> = ({status}) => {
    const data: any = {
        active: {
            label: 'active',
            bgColor: configuration.theme.success,
            color: configuration.theme.success_color
        },
        allarm: {
            label: 'In Allarme',
            bgColor: configuration.theme.danger,
            color: configuration.theme.danger_color
        },
        alert: {
            label: 'In Allerta',
            bgColor: configuration.theme.alert,
            color: configuration.theme.alert_color
        },
        notCompliant: {
            label: 'Non conforme',
            bgColor: configuration.theme.danger,
            color: configuration.theme.danger_color
        },
        closed: {
            label: 'Concluso',
            bgColor: configuration.theme.inactive,
            color: configuration.theme.inactive_color
        }
    }
    const value = data[status]
    return <Tag label={value.label} bgColor={value.bgColor} color={value.color}/>
}

export default StatusTagVoice;
