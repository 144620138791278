import React, {ReactChild} from 'react';
import useComponentVisible from "../../hook/useComponentVisible";
import {mergeClass} from "../../functions/function";


const Dropdown = ({
                      children,
                      list,
                      className,
                      showToggle,
                      position = '',
                        dataTest= ''
                  }: { children?: ReactChild, list?: ReactChild, className?: any, showToggle?: boolean, position?: string, dataTest?: string }) => {

    const {ref, isComponentVisible, setIsComponentVisible} = useComponentVisible(false);

    const dropStyle = mergeClass('sh dropdown', className)
    const childStyle = mergeClass(showToggle ? 'dropdown-toggle' : '')
    const dropdownStyle = mergeClass('dropdown-menu', isComponentVisible ? 'show' : '')


    return (
        <div className={`btn-group ${position}`} data-test={'dropdown-'+dataTest}>

            <div className={dropStyle}>
                <a className={childStyle} ref={ref}
                   onClick={() => setIsComponentVisible(!isComponentVisible)} role="button"
                   data-bs-toggle="dropdown" aria-expanded="false">
                    {children}
                </a>

                {list && <ul className={dropdownStyle}
                            >
                    {list}
                </ul>}
            </div>
        </div>
    );
};

export default Dropdown;
