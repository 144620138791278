import React from 'react';
import IconLabelDetail from "./IconLabelDetail";
import Tag from "../custom/tag/Tag";
import {mapStateColor} from "../utils/mapStateColor";
import {useLanguageForShared} from "../utils/languages/useLanguageForShared";

const Treatment = ({
                       treatment,
                   }: { treatment: { name: string, state?: string, date?: string, icon?: string } }) => {
    const {t} = useLanguageForShared()

    return (

        <IconLabelDetail label={treatment.name} value={
            <span><Tag classNames={'text-sm'} label={treatment.state}
                       bgColor={mapStateColor(treatment.state)?.bg}
                       color={mapStateColor(treatment.state)?.color}/> {t('common.on')} {treatment?.date}</span>
        } icon={treatment?.icon || 'treatment'} iconSize={'32px'}/>

    );
};

export default Treatment;
