import React, {useEffect, useState} from "react";
import {mergeClass} from "../../functions/function";

import {TableProps} from "../models/TableProps";
import TableHeader from "./components/table-header/TableHeader";
import TableBody from "./components/table-body/TableBody";
import ColumnFilter from "./components/filterColumn/ColumnFilter";
import Pagination from "./components/pagination/Pagination";
import {useEffectOnlyUpdate} from "../../utils/use_custom/useEffectCustom";
import {TitleHeadingH3} from "../../custom/headings/TitleHeadingH3";

const Table = ({
                   children,
                   data = [],
                   styleTable = "",
                   columns,
                   templates = {},
                   headerTemplates = {},
                   changeExternalPage,
                   clickRow,
                   changePage,
                   callbackChangePage,
                   totalElements,
                   elementsForPage,
                   rowIndexActive = [],
                   titleTable,
                   rightTemplate
               }: TableProps) => {
    const [columnsHide, setColumnsHide] = useState<string[]>([]);
    const allColumns = columns.filter((col) => col.show !== false)
    const columnsFilter = allColumns.filter(col => !columnsHide.includes(col.name));
    return (
        <>


            <div className={'table-responsive'}>
                <TitleHeadingH3 title={titleTable}
                                rightTemplate={<div className={'d-flex gap-3 mb-1'}>{rightTemplate} <ColumnFilter
                                    columnsHide={columnsHide}
                                    allColumns={allColumns}
                                    setColumnsHide={setColumnsHide}/>
                                </div>}/>

                <table
                    className={mergeClass("table-custom", styleTable)}>
                    <TableHeader columns={columnsFilter} templates={headerTemplates}/>
                    <TableBody
                        clickRow={clickRow}
                        columns={columnsFilter}
                        data={data}
                        templates={templates}
                        rowIndexActive={rowIndexActive}
                    />
                </table>
            </div>
            <div style={{minHeight: "75px"}}>
                {totalElements !== undefined &&
                    <Pagination changeExternalPage={changeExternalPage} callbackChangePage={callbackChangePage}
                                elements={totalElements}
                                elementForPage={elementsForPage} goPage={() => {
                    }}/>}
            </div>
        </>
    );
};
export default Table;
