import React from 'react';

const PointLabel = ({label, title, pointColor}: { label: string, title?: string, pointColor?: string }) => {
    return (
        <div className={`point-label`} title={title}>
            <div className={'point'} style={{backgroundColor: pointColor}}></div>
            <span className={'label'}>{label}</span>
        </div>
    );
};

export default PointLabel;
