import React, {useEffect, useState} from "react";
import {setLanguageAction} from "../redux/generalSettingsSlice";
import {setI18n} from "../utils/languages/config/i18n";
import {useDispatch} from "react-redux";
import {setTranslationHl7} from "./translateHl7Slice";
import {valueSetHl7Name} from "./valueSet.hl7";
import http from "../utils/http";
import httpData from "../utils/httpData";

const Hl7Load: React.FC<any> = ({children, list = []}) => {
    const [initHl7, setInitHl7] = useState<boolean>(false)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(setTranslationHl7(list.map((ele: any) => ({name: ele})),() => setInitHl7(true)))
    }, [])
    return (
        <>
            {initHl7 && children}
        </>
    )
}
export default Hl7Load
