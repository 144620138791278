import React, {useEffect, useState} from "react";
import InfoIcon from "../../../../shared/UI components/InfoIcon";
import Patient from "../../../../shared/UI components/Patient";
import moment from "moment";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";
import {getLastDeviceSettings} from "../../services/header.service";
import {PatientModel} from "../../models/Patient.model";
import {DeviceModel} from "../../models/Device.model";
import {IconSVG} from "../../../../shared/custom/icon/IconSVG";
import configuration from "../../../../shared/configuration";
import ModalSendMail from "../modal/ModalSendMail";
import {subPatientDataNameSections} from "./data/subPatientData";
import {useDateCustom} from "../../../../shared/custom_use/useDateCustom";


export const SubPatientNavHl7 = ({
                                     patientDetails,
                                     hideSubPatientNavElements = []
                                 }: { patientDetails: any, hideSubPatientNavElements?: string[] }) => {
    const {t} = useLanguageForShared()
    const {data: {dateFormatGGmmmYYYY}} = useDateCustom()

    const patient = patientDetails && {
        name: patientDetails?.patient?.name?.map(ele => ele?.given?.map(ele => ele)).join(' '),
        surname: patientDetails?.patient?.name?.map(ele => ele?.family).join(' '),
        birthdate: dateFormatGGmmmYYYY(patientDetails?.patient?.birthDate),
        age: '',
        gender: patientDetails?.patient?.gender === 'male' ? t('common.male') : patientDetails?.patient?.gender === 'female' ? t('common.female') : t('common.notDefined'),
    }
    const pathologies = patientDetails && patientDetails.pathologies?.map(ele => ele?.code?.text).join(', ')

    const treatments = patientDetails && patientDetails?.treatments?.map(ele => ele?.name).join(', ')
    //const gender = patient.Gender === 0 ? t('common.male') : patient.Gender === 1 ? t('common.female') : t('common.notDefined')


    return (
        <>
            {patient &&
                <div className={'d-flex justify-content-between align-items-center'} style={{flex: '1'}}>
                    <div className={'d-flex'}>
                        <Patient patient={patient}/>

                        {pathologies && pathologies.length > 0 &&
                            <InfoIcon label={pathologies} value={t('common.pathology')} icon={'pathology'}
                                      style={{maxWidth: '300px'}} className={'text-ellipsis'}
                                      contentPop={pathologies}/>}

                        {treatments && treatments.length > 0 &&
                            <InfoIcon label={treatments} value={t('common.treatment')} icon={'treatment'}
                                      style={{maxWidth: '300px'}} className={'text-ellipsis'} contentPop={treatments}/>}

                    </div>
                    <div className={'ms-auto'}>
                        {/*{rightTemplate}*/}
                    </div>
                </div>
            }
        </>
    )
}

export default SubPatientNavHl7;
