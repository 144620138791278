import React from 'react';

const CarePlanItem = ({carePlan}: { carePlan: { name: string } }) => {
    const value = carePlan?.name;
    return (
        <div className={""}>
            {value || '-'}
        </div>
    );
};

export default CarePlanItem;
