import React, { useEffect, useState } from 'react'
import { useLanguageForShared } from '../../../../utils/languages/useLanguageForShared'
import DatePickerBase from "./DatePickerBase";
import moment from "moment";

let keypress: boolean = false;
const DatePicker = ({
                        settings,
                        localeSettings,
                        handleCallback,
                        myRef,
                        value,
                        dataTest
                    }: {
    value: any;
    settings: any
    localeSettings: any
    handleCallback: (start: any, end: any) => void
    myRef?: any
    dataTest?:string
}) => {
    const { lang } = useLanguageForShared()
    const format = settings?.timePicker? 'L LT' : 'L';
    const myRefSet = () => {
        updateCalendar();
        myRef?.current?.ref && myRef?.current?.ref.addEventListener('keyup', () => {
            keypress = true;
        })
    }
    useEffect(() => {
        updateCalendar();
    }, [value])

    const updateCalendar = () => {
        !value && myRef?.current?.ref?.value && (myRef.current.ref.value = '');
        value && myRef?.current?.ref && (myRef.current.ref.value = moment(value).format(format));
    }
    return (

        <DatePickerBase
            myRef={myRef}
            issetMyRef={myRefSet}
            settings={{
                autoUpdateInput: false,
                ...settings
            }}
            localeSettings={localeSettings}
            onCallback={(start, end) => {
                myRef?.current?.ref && (myRef.current.ref.value =  start.format(format));
                handleCallback(start, end)
            }}
            onApply={(event, picker) => {
                picker.element[0].value = picker.startDate.format(format)
            }}
            onEvent={(d, picker) => {
                if(((d.type === 'hide' && keypress) || d.type === 'apply') && picker.startDate.isSame(picker.oldStartDate)){
                    handleCallback(picker.startDate, picker.endDate)
                }
                if(d.type === 'hide' && keypress){
                    picker.element[0].value = picker.startDate.format(format)
                    keypress = false;
                }
            }}
            dataTest={dataTest}
        />

    )
}

export default DatePicker
