import React from 'react';
import {IconSVG} from "./IconSVG";
import configuration from "../../configuration";

const BtnIconNotify = ({
                           icon,
                           disabled,
                           clickHandler
                       }: { icon: string, disabled?: boolean, clickHandler?: (ev:any) => void }) => {
    return (
        <div className={`btn-icon-notify ${disabled ? 'disabled' : 'pointer'}`} onClick={clickHandler}>
            <IconSVG icon={icon} size={'16px'} tintColor={configuration.theme.light}/>
            <div className={'notify'}></div>
        </div>
    );
};

export default BtnIconNotify;
