import React, {Fragment} from 'react';
import {useLanguageForShared} from "../../../shared/utils/languages/useLanguageForShared";
import TreatmentsHeader from "../template/header/TreatmentsHeader";

const HeadingList = ({listTableConfig}: { listTableConfig: any }) => {
    const {t} = useLanguageForShared()

    const templateHeader = {
        treatments: () => {
            return <TreatmentsHeader/>
        }
    }
    return (
        <div className={"row-list"}>
            <div className={"col-list heading-list"}>
                {listTableConfig.map((voice, i) =>
                    <Fragment key={i}>{voice.templateHeader ? templateHeader[voice.templateHeader]() :
                        <div {...voice.elementsHeaderBody}>
                            {voice.title}
                        </div>}
                    </Fragment>
                )}
            </div>
        </div>
    );
};

export default HeadingList;
