import React from 'react';
import LabelValue from "../../../../shared/custom/labelValue/LabelValue";
import SessionTitle from "../../../../shared/custom/section/SessionTitle";
import {IconSVG} from "../../../../shared/custom/icon/IconSVG";
import IconLabel from "../../../../shared/custom/icon/IconLabel";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";


const CommentsNotes = ({data, showComment, printView}: { printView: boolean, data: any, showComment: (data: any) => void }) => {
    const {t} = useLanguageForShared()

    return (
        <div className={'d-flex gap-5 row-gap-2 flex-wrap'}>
            <div className={"d-flex flex-column gap-1 flex-wrap"}>
                <div className={'d-flex gap-5 row-gap-1 flex-wrap'}>
                    <div style={{flex: '0 0 54%'}}>
                        <LabelValue label={t('hlv.patient_comments')}
                                    value={' Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eaque eum fuga mollitia. Ab atque doloribus, ea error eveniet iusto maxime necessitatibus nihil odit'}
                                    labelClass={'sm'}
                                    valueClass={'bold'}/>
                    </div>
                    {!printView && <div style={{flex: '0 0 40%'}}>
                        <LabelValue inline label={t('hlv.patient_vocal')}
                                    value={<div className={'pointer text-primary ms-2'}
                                                onClick={() => showComment({data, type: 'vocal'})}>
                                        <IconLabel icon={'play'} size={'16px'} label={'Play'}/>
                                    </div>}
                                    labelClass={'sm'}
                                    valueClass={'bold'}/>
                    </div>}
                </div>
                <div className={'d-flex gap-5 row-gap-1 flex-wrap'}>
                    <div style={{flex: '0 0 100%'}}>
                        <LabelValue label={t('hlv.note')}
                                    value={'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eaque eum fuga mollitia. Ab atque doloribus, ea error eveniet iusto maxime necessitatibus nihil odit perferendis quaerat quibusdam recusandae sed sint velit!'}
                                    labelClass={'sm'}
                                    valueClass={'bold'}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CommentsNotes;
