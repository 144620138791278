import React from "react";
import {IconSVG} from "../../../../shared/custom/icon/IconSVG";

const ActionsBodyTemplate: React.FC<any> = ({showActionBtn, goToDetailPatient}) => {
    return   <div className={`actions ${!showActionBtn ? 'd-none' : ''}`}>
        <div className={'pointer'} onClick={goToDetailPatient}>
            <IconSVG icon={'arrow-right'} size={'20px'}/>
        </div>
    </div>
}
export default ActionsBodyTemplate
