import http from "../shared/utils/http";
import configuration from "../shared/configuration";

interface resourceHl7OrganizationModel{
    id: string,
    identifier: any[],
    meta: any,
    name: string,
    resourceType: string,
    type: any[]
}
interface entryDataHl7<T>{
    fullUrl: string,
    resource: T
}
interface LinkHl7Model {
    relation: string,
    url: string
}
interface OrganizationResponseHl7Model {
    entry: entryDataHl7<resourceHl7OrganizationModel>[],
    link: LinkHl7Model[],
    resourceType: string,
    total: number,
    type: string,
    id: string
}
export const getOrganizations = () => http.get<OrganizationResponseHl7Model>(configuration.external.hl7.httpUrl +`/administrativeresource/v1/${configuration.external.hl7.section}/fhir/Organization`)
