import React, {useEffect, useState} from "react";
import InfoIcon from "../../../../shared/UI components/InfoIcon";
import Patient from "../../../../shared/UI components/Patient";
import moment from "moment";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";
import {getLastDeviceSettings} from "../../services/header.service";
import {PatientModel} from "../../models/Patient.model";
import {DeviceModel} from "../../models/Device.model";
import {IconSVG} from "../../../../shared/custom/icon/IconSVG";
import configuration from "../../../../shared/configuration";
import ModalSendMail from "../modal/ModalSendMail";
import {subPatientDataNameSections} from "./data/subPatientData";


export const SubPatientNav = ({data, hideSubPatientNavElements = []}: { data: PatientModel, hideSubPatientNavElements?: string[] }) => {
    const {t} = useLanguageForShared()
    const [device, setDevice] = useState<DeviceModel[] | undefined>(undefined)
    const isDevices = !hideSubPatientNavElements.includes(subPatientDataNameSections.devices);
    useEffect(() => {
        if(isDevices){
            getLastDeviceSettings(data.PatientID).then(res => {
                setDevice(res)
            })
        }
    }, [data.PatientID])


    const patient = data
    const ageCalc = '' //TODO calcolare anni

    const treatments = patient && Object.keys(patient?.TreatmentTypes).map(ele => t('treatments.' + ele)).join(', ')
    const pathologies = patient && patient?.Pathologies.map(ele => t('pathology.' + ele)).join(', ')
    const devices = device && device.map(ele => ele.modelName).join(', ')
    const birthdate = patient?.DateOfBirth ? moment(patient?.DateOfBirth).format('DD MMM YY') : ""
    const gender = patient.Gender === 0 ? t('common.male') : patient.Gender === 1 ? t('common.female') : t('common.notDefined')


    const [showModalMail, setShowModalMail] = useState<any>(undefined);
    const rightTemplate = <div className={'d-flex gap-4'}>
        <div className={'d-flex gap-2 align-items-end pointer'} onClick={() => setShowModalMail(true)}>
            <IconSVG icon={'appointment'} tintColor={configuration.theme.primary} size={'24px'}/>
            <a className={'link'}>{t('header.action.requestAppointment')}</a>
        </div>
        <div className={'d-flex gap-2 align-items-end'}>
            <IconSVG icon={'call-user'} tintColor={configuration.theme.primary} size={'24px'}/>
            <a className={'link'}>{t('header.action.call')}</a>
        </div>
    </div>


    return (
        <>
            {patient &&
                <div className={'d-flex justify-content-between align-items-center'} style={{flex: '1'}}>
                    <div className={'d-flex'}>
                        <Patient
                            patient={{
                                name: patient.FirstName,
                                surname: patient.LastName,
                                birthdate: birthdate,
                                age: ageCalc,
                                gender: gender
                            }}/>

                        {pathologies && pathologies.length > 0 &&
                            <InfoIcon label={pathologies} value={t('common.pathology')} icon={'patology'}
                                      style={{maxWidth: '200px'}} className={'text-ellipsis'}
                                      contentPop={pathologies}/>}

                        {treatments && treatments.length > 0 &&
                            <InfoIcon label={treatments} value={t('common.treatment')} icon={'treatment'}
                                      style={{maxWidth: '200px'}} className={'text-ellipsis'} contentPop={treatments}/>}

                        {devices && devices.length > 0 &&
                            <InfoIcon label={devices} value={t('common.device')} icon={'device'}
                                      style={{maxWidth: '200px'}}
                                      className={'text-ellipsis'} contentPop={devices}/>}
                        {/*  <StatusLabel label={'App Linde MyTerapy'} value={"Servizio"}
                                     labelClass={'lg medium text-primary-dark'}
                                     valueClass={'sm medium text-dark'}/>*/}
                    </div>
                    <div className={'ms-auto'}>
                      {/*  {rightTemplate}*/}
                    </div>
{/*
                    {showModalMail && <ModalSendMail closeModal={() => setShowModalMail(false)}/>}
*/}

                </div>
            }
        </>
    )
}

export default SubPatientNav;
