import React, {useEffect, useRef, useState} from 'react'
import DatePicker from "./datePicker/DatePicker";
import moment from "moment";

interface DatePickerCustomProps {
    error?: boolean
    name: string
    classString?: string
    value: any
    type: string
    extra: any;
    changeValue: (data: any, event: React.ChangeEvent<HTMLInputElement>) => void
}


const DatePickerCustom = ({
                              changeValue,
                              type,
                              classString,
                              name,
                              error,
                              extra,
                              value,
                              ...rest
                          }: DatePickerCustomProps) => {
    const myRef = useRef<any>();
    const timePicker = !!extra?.settings?.timePicker;
    const is24Hours = !moment().format('L LT').includes('AM') && !moment().format('L LT').includes('PM')
    const [update, setUpdateValue] = useState<any>();
    useEffect(() => {
        update && changeValue(update.start, update.end)
    }, [update])
    const changeValueHandler = (start: any, end: any) => {
        setUpdateValue({start, end});
    }
    const handleCallback = (start: any, end: any) => {
        return changeValueHandler(new Date(start.format()).toISOString(), end)
    }

    return (
        <>
            <DatePicker
                value={value}
                settings={{
                    startDate: (value && moment(value).format('L LT')) || moment().format('L LT'),
                    timePicker: timePicker,
                    singleDatePicker: true,
                    timePicker24Hour: is24Hours,
                    //format: 'M/DD hh:mm A',
                    ...(extra?.settings || {}),
                }}
                localeSettings={{
                    format: timePicker ? 'L LT' : 'L'
                }}
                myRef={myRef}
                handleCallback={handleCallback}
                dataTest={name}
            />
        </>
    )
}

export default DatePickerCustom
