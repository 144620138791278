import React from 'react';
import PatientItem from './patientItem/PatientItem';

const PatientListTable = ({listPatient, listTableConfig, clickRow}: { listTableConfig: any, listPatient?: any[], clickRow?: (data: any) => void }) => {

    return (
        <div className={'list-patient'}>
            {listPatient?.map((ele, key) => (
                <PatientItem clickRow={clickRow} listTableConfig={listTableConfig} key={key} patient={ele}/>
            ))}
        </div>
    );
};

export default PatientListTable;
