import React, {useState} from 'react';
import Table from "../../../../shared/table/table/Table";
import NumberVal from "../../../../shared/custom/numberVal/NumberVal";
import Spo2Cursor from "../../../../shared/custom/spo2Cursor/Spo2Cursor";
import BtnIconNotify from "../../../../shared/custom/icon/BtnIconNotify";
import {headerTemplatesTableHistorical, structureTableHistorical,} from "./TemplateTabelHistoricalData";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";
import {useRoute} from "../../../../core/routes/useRoute";
import {nameRoutes} from "../../../../core/routes/nameRoutes";
import Tag from "../../../../shared/custom/tag/Tag";
import MiniBpmChart from "./MiniBpmChart";
import ModalComment from "../components/modal/ModalComment";
import PointLabel from "../../../../shared/custom/pointLabel/PointLabel";
import {telemetryStyleRehab} from "../../../../libraries/patient_list/components/colorData";
import {mapStateColor} from "../../../../shared/utils/mapStateColor";
import {PatientDetailModel} from "../../../../model/PatientDetails";
import {useDateCustom} from "../../../../shared/custom_use/useDateCustom";

const HistoricalSession = ({data}: { data: PatientDetailModel }) => {
    const {t} = useLanguageForShared()
    const {data: {dateFormatGGmmmYYYYhhMM}} = useDateCustom()
    const sessions = (data?.sessions || []).map((ele, index) => ({
        ...ele,
        index: data.sessions.length -index
    }))

    const goToDetail = (ev, row: any) => {
        window.open(`/rehab/${row.id}/${data?.id}`, "_blank");
    }

    const [showComment, setShowComment] = useState(undefined);

    /*    const sampleData = [
            {
                id: '27',
                state: 'active',
                creationDate: '12/12/12',
                duration: '42:56 / 40:00',

                borg: 5,
                spo2_data_1: {min: 92, avg: 94},
                bpm_data_1: {min: 92, avg: 97},

                exercises: '4/4',
                fatigue: [
                    {name: 'q1', title: 'asd', val: 1},
                    {name: 'q2', title: 'asd', val: 5},
                    {name: 'q3', title: 'asd', val: 3}
                ],
                spo2_data_2: {min: 92, avg: 97},
                bpm_data_2: {min: 92, avg: 97},
                comment: 'msg text',
                vocal: 'msg vocal'
            },
        ]*/

    const fatigueSample = [
        {name: 'q1', title: 'asd', val: 1},
        {name: 'q2', title: 'asd', val: 5},
        {name: 'q3', title: 'asd', val: 3}
    ]

    const templatesTableHistorical = {
        session: (value: string, row: any) => {
            return (
                <div className={"d-flex flex-column gap-1"}>
                    <span
                        className={'d-flex gap-2'}><b>{row.index}</b>{dateFormatGGmmmYYYYhhMM(row?.effectivePeriod?.start)}</span>
                    <small className={'d-flex align-items-center gap-2'}>
                        <Tag classNames={'text-sm'} label={row.sessionCompletionTranslate}
                             bgColor={mapStateColor(row.sessionCompletion)?.bg}
                             color={mapStateColor(row.sessionCompletion)?.color}/>{row?.sessionTypeTranslate}</small>
                </div>
            );
        },
        firstBlock: (value: string, row: any) => {
            return (
                <div className={"d-flex"}>
                    {row?.block1Duration}
                </div>
            );
        },
        borg: (value: string, row: any) => {
            return (
                <div className={"d-flex"}>
                    <NumberVal val={row?.borgScale.toString()}
                               bgColor={telemetryStyleRehab()[row?.borgScale]?.bg}
                               color={telemetryStyleRehab()[row?.borgScale]?.color}/>
                </div>
            );
        },
        spo2_1: (value: string, row: any) => {
            return (
                <div className={"d-flex"}>
                    <div className={"w-100"} style={{maxWidth: '200px'}}>
                        <Spo2Cursor min={row.block1Spo2Min} avg={row.block1Spo2Avg}/>
                    </div>
                </div>
            );
        },
        bpm_1: (value: string, row: any) => {
            return (
                <div className={"d-flex"}>
                    <MiniBpmChart flow={row.block1Bpm} avg={row.block1BpmAvg}/>
                </div>
            );
        },
        secondBlock: (value: string, row: any) => {
            return (
                <div className={"d-flex"}>
                    <span>{row.block2Exe}/4</span>
                </div>
            );
        },
        fatigue: (value: any, row: any) => {
            return (
                <div className={"d-flex gap-2"}>
                    {fatigueSample.map((ele, i) => (
                        <PointLabel key={i} label={ele?.name.toUpperCase()} title={ele?.title}
                                    pointColor={telemetryStyleRehab()[+ele?.val]?.bg}/>
                    ))}
                </div>
            );
        },
        spo2_2: (value: string, row: any) => {
            return (
                <div className={"d-flex"}>
                    <div className={"w-100"} style={{maxWidth: '200px'}}>
                        <Spo2Cursor min={row.block2Spo2Min} avg={row.block2Spo2Avg}/>
                    </div>
                </div>
            );
        },
        bpm_2: (value: string, row: any) => {
            return (
                <div className={"d-flex"}>
                    <MiniBpmChart flow={row.block2Bpm} avg={row.block2BpmAvg}/>
                </div>
            );
        },

        comment: (value: string, row: any) => {
            return (
                <div className={'d-flex gap-3'}>
                    <BtnIconNotify icon={'comment'} disabled={false} clickHandler={(ev) => {
                        ev.stopPropagation();
                        setShowComment({data: row.comment, type: 'text'})
                    }
                    }/>
                    <BtnIconNotify icon={'vocal'} disabled={false} clickHandler={(ev) => {
                        ev.stopPropagation();
                        setShowComment({data: row.vocal, type: 'vocal'})
                    }}/>
                </div>
            );
        },
    }


    const closeModal = () => setShowComment(undefined)


    return (
        <div className={"col-12 rehab-historical-list"}>
            <Table columns={structureTableHistorical(t)} templates={templatesTableHistorical}
                   headerTemplates={headerTemplatesTableHistorical(t)} data={sessions}
                   titleTable={t('hlv.summary_sessions')} clickRow={goToDetail}/>
            {showComment && <ModalComment closeModal={closeModal} comment={showComment}/>}
        </div>
    );
};

export default HistoricalSession;
