import React, {useEffect} from 'react';
import {AuthContextProps} from "oidc-react/build/src/AuthContextInterface";
import authList from "./routes/authList";
import LoadDynamicRoute from "./routes/LoadDynamicRoute";
import LayoutBase from "./layout/LayoutBase";
import {getOrganizationsAction, selectOrganizations} from "../store/organizationSlice";
import {useSelector, useDispatch} from 'react-redux'
import LoaderFullScreen from "../shared/custom/loader/loaderFullscreen/LoaderFullScreen";

const AuthPage = ({auth}: { auth: AuthContextProps }) => {
    const organizations = useSelector(selectOrganizations)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getOrganizationsAction())
    }, []);
    return (
        organizations.length ? <LayoutBase auth={auth}>
            <>
                <LoadDynamicRoute
                    listRoutes={authList}
                />
            </>
        </LayoutBase> : <LoaderFullScreen show={true}/>
    )

};

export default AuthPage;
