import React from 'react';
import configuration from "../../configuration";
import TagAlert from "./TagAlert";

const AlertItem = ({state = 'default', label, icon}: { state?: string, label: string, icon?: string }) => {

    const stateMap: any = {
        alert: {
            bg: configuration.theme.alert,
            color: configuration.theme.alert_color,
            icon: icon || 'alert-msg',
        },
        alarm: {
            bg: configuration.theme.danger,
            color: configuration.theme.danger_color,
            icon: icon || 'alert-msg'
        },
        default: {
            bg: configuration.theme.gray100,
            color: configuration.theme.primaryDark,
        }
    }

    return (
        <div className={'alert-item'}>
            <TagAlert label={label} icon={stateMap[state]?.icon || icon}
                      color={state ? stateMap[state].color : ''}
                      bgColor={state ? stateMap[state].bg : ''}/>
        </div>
    );
};

export default AlertItem;
