import React, {ReactNode} from 'react'

export const LayoutLeftAside = ({
                                    leftTemplate,
                                    children,
                                }: {
    leftTemplate?: ReactNode
    children: ReactNode
}) => {
    return (
        <>
            <div className="row ae-page-content-container">
                <div className="col-2 ae-sidebar-content">
                    {leftTemplate && leftTemplate}
                </div>
                <div className="col-10 detail">
                    {children}
                </div>
            </div>
        </>
    )
}
