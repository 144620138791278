import React, {useEffect} from 'react';
import {useAuth} from 'oidc-react';
import {Route, Routes, useNavigate} from "react-router-dom"
import LoaderFullScreen from "../shared/custom/loader/loaderFullscreen/LoaderFullScreen";

const LoadingAuthPage = () => {
    return <div>
        <LoaderFullScreen show={true}/>
    </div>

};

export default LoadingAuthPage;
