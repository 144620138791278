import React from 'react'
import {TitleHeadingH3} from "../../../../shared/custom/headings/TitleHeadingH3";
import {IconSVG} from "../../../../shared/custom/icon/IconSVG";
import BtnPrimary from "../../../../shared/bootstrap/button/primary/BtnPrimary";
import BtnSecondary from "../../../../shared/bootstrap/button/secondary/BtnSecondary";
import {OrganizationModel} from "../../models/Organization.model";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";
import {ChangeOrganizations} from "./ChangeOrganizations";
import {ChangeLanguage} from "./ChangeLanguage";
import BtnDefault from "../../../../shared/bootstrap/button/default/BtnDefault";
import configuration from "../../../../shared/configuration";

interface SidebarRightProps {
    organizations?: OrganizationModel[];
    organization_id?: string;
    changeLanguageHandler?: (id: any) => void
    user: any;
    languages: string[],
    changeOrganization?: (id: any) => void
    editProfile?: boolean
    logout?: () => void
    goProfile?: () => void
    goToAdmin?: (path: string) => void
    adminSectionEnabled?: boolean
}

export const SidebarRight: React.FC<SidebarRightProps> = ({
                                                              logout,
                                                              editProfile,
                                                              goProfile,
                                                              goToAdmin,
                                                              changeOrganization,
                                                              changeLanguageHandler,
                                                              languages,
                                                              user,
                                                              organizations = [],
                                                              organization_id,
                                                              adminSectionEnabled
                                                          }) => {

    const {t} = useLanguageForShared();
    return (
        <div className={"d-flex flex-column h-100"}>
            <div>
                <TitleHeadingH3 title={t('header.sidebar.userProfile')}/>
                <div>
                    <div>{user.Name || `${user.firstname || ''} ${user?.lastname || ''}`}</div>
                    <div className={'mt-2'}>
                        <IconSVG icon={'mail'} color={configuration.theme?.primary} size={'18px'}/> <span
                        className={'text-primary'}> {user.Email || user.email}</span>
                    </div>
                </div>
                <div className={'form mt-5'}>
                    {organizations && !!organizations.length && <ChangeOrganizations changeOrganization={changeOrganization} organizations={organizations}
                                                                                     organization_id={organization_id}/>}
                    {languages && !!languages.length &&
                        <ChangeLanguage languages={languages} changeLanguageHandler={changeLanguageHandler}/>}
                </div>
            </div>

            <div className={'mt-auto'}>

                {adminSectionEnabled && <div className={'border-bottom'}>
                    <BtnDefault iconRight={'arrow-right'}
                                onClick={() => goToAdmin && goToAdmin('/admin')}>{t('action.admin')}</BtnDefault>
                </div>
                }
                <div className={'mt-3'}>
                    <div className={'d-flex btn-group-distance'}>
                        {editProfile && <BtnPrimary className={'w-50 justify-content-center'}
                                                    onClick={() => goProfile && goProfile()}>{t('action.profile')}</BtnPrimary>}
                        <BtnSecondary className={'w-50 justify-content-center'}
                                      onClick={() => logout && logout()}>{t('action.logout')}</BtnSecondary>
                    </div>
                </div>
            </div>


        </div>
    )
}
