import React from 'react';
import ActionTopBar from "../../../../shared/custom/actionBar/ActionTopBar";
import {TitleHeadingH3} from "../../../../shared/custom/headings/TitleHeadingH3";
import BtnList from "../../../../shared/custom/actionBar/BtnList";
import InfoTreatment from "../components/InfoTreatment";
import HistoricalSession from "./HistoricalSession";
import ChartRow from "../components/ChartRow";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";
import {PatientDetailModel} from "../../../../model/PatientDetails";

const SummaryTreatment = ({data}: { data: PatientDetailModel }) => {
    const {t} = useLanguageForShared()

    const actionPage = [
        {
            label: t('hlv.action.generateReport'),
            icon: 'report-gen',
            actionHandler: () => console.log('generate report')
        }
    ]

    return (
        <div>
            <ActionTopBar leftTemplate={<TitleHeadingH3 title={t('hlv.telerehabilitation_treatment')}/>}
                          rightTemplate={<BtnList listBtn={actionPage}/>}
            />
            <div className={'row mt-3 gap-5'}>

                <div className={"col-12"}>
                    <TitleHeadingH3 title={t('hlv.summary_treatment')}/>
                    <InfoTreatment data={data}/>
                </div>
                <div className={"col-12"}>
                    {data && <ChartRow patient={data}/>}
                </div>
                <div className={"col-12"}>
                    <HistoricalSession data={data} />
                </div>
            </div>
        </div>
    );
};

export default SummaryTreatment;
