import {Select2} from "select2-react-component";
import React, {useEffect, useState} from "react";
import {t} from "i18next";
import {useLanguageForShared} from "../../../utils/languages/useLanguageForShared";
import {IconSVG} from "../../../custom/icon/IconSVG";

interface SelectProps {
    changeValue: (data: any) => void;
    value: any;
    options: { value: string; label: string; disabled?: boolean }[];
    actions?: any;
    searchOption?: any;
    defaultValue?: {
        label?: string
        isDefault: boolean
        disabled?: boolean
        value?: any
    };
    multi?: boolean;
    isClearable?: boolean;
    dataTest?: string;
    style?: any;
    placeholderLabel?: string;
    error?: boolean;
    disabled?: boolean;

}

const SelectCustom2 = ({
                           changeValue,
                           value,
                           options,
                           defaultValue = {label: t("form.action.select"), isDefault: true, disabled: true},
                           dataTest = "select2",
                           multi = false,
                           isClearable = false,
                           placeholderLabel = t("form.action.select"),
                           error,
                           disabled,
                           actions,
                           searchOption,
                           ...rest
                       }: SelectProps) => {

    const [reload, setReload] = useState(false);
    const {lang} = useLanguageForShared()
    useEffect(() => {
        // todo Massy ricarica il componente select2 ss alla modifica delle options vedere se c'è qualche altra alternativa migliore
        setReload(true);
        setTimeout(() => {
            setReload(false);
        }, 2);
    }, [lang]);
    const optionsData = [
        ...(defaultValue.isDefault
            ? [
                {
                    value: "-1",
                    label: placeholderLabel || defaultValue.label || "",
                    disabled: !!defaultValue.disabled,
                    classes: defaultValue.disabled ? "hidden" : ""
                }
            ]
            : []),
        ...options.map(ele => ({...ele, classes: "data-test-value-" + ele.value}))
    ];
    const onChangeAction = (val: any) => {
        if (multi) {
            changeValue(val.filter((ele: any) => ele !== "-1"));
        } else {
            changeValue(val != "-1" ? val : undefined);
        }
    };

    const formatReadMulti = (value: any) => value !== undefined && value !== null && value.length ? value : ["-1"];

    const formatReadValue = (value: any) => value !== undefined && value !== null ? value : "-1";
    const valueFormat = multi ? formatReadMulti(value) : formatReadValue(value);

    const handleClearSelection = () => {
        onChangeAction(multi ? [] : null)
    };


    return (
        <div data-test={"select-" + dataTest}
             className={`select2-custom content-input d-flex gap-3 align-items-center ${multi && valueFormat?.length && valueFormat[0] === "-1" ? "placeholderSelectMulti" : ""} ${error ? "error" : ""}`}>
            {!reload && <Select2
                disabled={disabled}
                search={(v) => searchOption?.action && actions && actions[searchOption.action] && actions[searchOption.action](v)}
                customSearchEnabled={!!searchOption?.action}
                data={optionsData}
                multiple={multi}
                value={valueFormat}
                update={onChangeAction}
                {...rest}
            ></Select2>}
            {isClearable && !!value && <IconSVG className={'pointer'} icon={'trash'} size={'20px'}
                                                      onClick={handleClearSelection} />}
        </div>
    );
};

export default SelectCustom2;
