import React from 'react';
import {Navigate, Route, Routes} from "react-router-dom"
import {RoutesModel} from "./models/Routes.model";

const LoadDynamicRoute = ({listRoutes} : {listRoutes: RoutesModel}) => {
    return <Routes>
                {listRoutes.list.map(route => <Route
                    key={route.path}
                    path={route.path}
                    element={route.component}
                />)}
                {listRoutes.redirect && <Route path="*" element={<Navigate to={listRoutes.redirect} />} />}
            </Routes>
};

export default LoadDynamicRoute;
