import {IconSVG} from "../../../../../shared/custom/icon/IconSVG";
import React from "react";
import configuration from "../../../../../shared/configuration";
import { useLanguageForShared } from "../../../../../shared/utils/languages/useLanguageForShared";

export const BackBtnNav = ({clickHandler}: { clickHandler: () => void }) => {
    const {t} = useLanguageForShared()

    return <div className={"back-btn"} onClick={clickHandler}>
        <IconSVG icon={"arrow-left"} size={"20px"} className={'me-2'} tintColor={configuration.theme.primary}/>
        <span className={"text-primary"}>{t("action.back")}</span>
    </div>;
}
