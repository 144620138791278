import { createSlice } from '@reduxjs/toolkit'

interface InitialStateProps {
  n: number;
  manual: boolean;
  stop: boolean
}
const initialState: InitialStateProps = {
  n: 0,
  manual: false,
  stop: false,
}

export const loadSlice = createSlice({
  name: 'load',
  initialState,
  reducers: {
    incrementN: (state) => {
      state.n = state.n + 1
    },
    decrementN: (state) => {
      state.n = state.n > 0 ? state.n - 1 : 0
    },
    setLoadManual: (state, action) => {
      state.manual = action.payload;
    },
    setStopLoader: (state, action) => {
      state.stop = action.payload;
    },
  },
})

export const { incrementN, decrementN, setLoadManual, setStopLoader } = loadSlice.actions

export const selectActiveLoad = (state: { load: InitialStateProps }) => !!state.load.n
export const selectActiveManualLoad = (state: { load: InitialStateProps }) => state.load.manual;
export const selectStopLoad = (state: { load: InitialStateProps }) => state.load.stop;

export default loadSlice.reducer
