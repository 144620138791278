import configuration from "../configuration";

export const mapStateColor = (state: string) => {

    const colorStates = {
        completed: {color: configuration.theme.primary900, bg: configuration.theme.primary100},
        active: {color: configuration.theme.primary900, bg: configuration.theme.primary100},
        interrupted: {color: configuration.theme.warning, bg: configuration.theme.warninglight},
        activating: {color: configuration.theme.violet, bg: configuration.theme.violetlight},
        inactive: {color: configuration.theme.grayDark, bg: configuration.theme.gray100},
        disabled: {color: configuration.theme.grayDark, bg: configuration.theme.gray100},
        draft: {color: configuration.theme.primary900, bg: configuration.theme.info},
    }


    return colorStates[state]
}