import React from 'react'
import DateInputCustom from './DateInputCustom'
import DatePickerCustom from "./DatePickerCustom";
import {InputCustomPropsWithDetailsModel} from "../models/InputCustomPropsModel";

const InputCustomDate = ({
                             changeValue,
                             type,
                             name,
                             classString,
                             error,
                             value,
                             extra = {},
                             ...rest
                         }: InputCustomPropsWithDetailsModel) => {
    return (
        <>
            {extra.type === 'datePicker' ?
                <DatePickerCustom
                    name={name}
                    value={value}
                    type={type}
                    changeValue={changeValue}
                    extra={extra}
                    classString={classString}/>
                : (
                    <DateInputCustom
                        error={error}
                        name={name}
                        value={value}
                        changeValue={changeValue}
                        {...rest}
                    />
                )}
        </>
    )
}

export default InputCustomDate
