import React from 'react';

const NumberVal = ({
                       val = '',
                       bgColor,
                       color,
                       size = ''
                   }: { val: string, bgColor?: string, color?: string, size?: string }) => {
    return (
        <div className={`number-val ${size}`} style={{backgroundColor: bgColor, color: color}}>
            {val}
        </div>
    );
};

export default NumberVal;
