import React, {ReactChild} from 'react'

export const FooterComponent = ({
                                    leftTemplate,
                                    righTemplate,
                                }: {
    leftTemplate?: ReactChild
    righTemplate?: ReactChild
}) => {
    return (
        <div className="sh footer">
            <div className="d-flex justify-content-between px-4">
                <div>
                    {leftTemplate}
                </div>
                <div>
                    {righTemplate}
                </div>
            </div>
        </div>
    )
}
