import React from 'react';
import LabelValue from "../../../../shared/custom/labelValue/LabelValue";
import SessionTitle from "../../../../shared/custom/section/SessionTitle";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";
import Tag from "../../../../shared/custom/tag/Tag";
import {mapStateColor} from "../../../../shared/utils/mapStateColor";


const SecondBlock = ({session}: { session: any }) => {
    const {t} = useLanguageForShared()

    return (
        <div className={'d-flex gap-5 row-gap-2 flex-wrap flex-xl-nowrap'}>
            <div className={'d-flex flex-column gap-1 align-items-start'} style={{flex: '0 0 160px'}}>
                <SessionTitle label={t('hlv.secondBlockReinforce')}/>
                <LabelValue inline className={'mb-0'} label={t('hlv.duration')} value={'00:52:03'} labelClass={'sm'} valueClass={'bold'}/>
                <Tag classNames={'text-sm'} label={'Completed'} bgColor={mapStateColor('active')?.bg} color={mapStateColor('active')?.color}/>
            </div>
            <div className={"d-flex flex-column gap-1 flex-wrap"} style={{flex: '0 0 100%'}}>
                <div className={"d-flex flex-wrap gap-5 row-gap-1"}>
                    <div style={{flex: '0 0 42.5%'}}>
                        <LabelValue label={t('hlv.quadriceps')} value={'Exercise 2 | 2 series - 3 repetitions'}
                                    labelClass={'sm'}
                                    valueClass={'bold'}/>
                    </div>
                    <div style={{flex: '0 0 42.5%'}}>
                        <LabelValue label={t('hlv.abdominal')} value={'Exercise 2 | 2 series - 3 repetitions'}
                                    labelClass={'sm'}
                                    valueClass={'bold'}/>
                    </div>
                    <div style={{flex: '0 0 42.5%'}}>
                        <LabelValue label={t('hlv.arm')} value={'Exercise 2 | 2 series - 3 repetitions'}
                                    labelClass={'sm'}
                                    valueClass={'bold'}/>
                    </div>
                    <div style={{flex: '0 0 42.5%'}}>
                        <LabelValue label={t('hlv.legs')} value={'Exercise 2 | 2 series - 3 repetitions'}
                                    labelClass={'sm'}
                                    valueClass={'bold'}/>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default SecondBlock;
