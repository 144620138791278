import React from 'react';
import Treatment from "../../../../shared/UI components/Treatment";
import LabelValue from "../../../../shared/custom/labelValue/LabelValue";
import Device from "../../../../shared/UI components/Device";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";
import {PatientDetailModel} from "../../../../model/PatientDetails";
import {useDateCustom} from "../../../../shared/custom_use/useDateCustom";

const InfoTreatment = ({data}: { data?: PatientDetailModel }) => {
    const {t} = useLanguageForShared()
    const {data: {dateFormatGGmmmYYhhMM}} = useDateCustom()
    return (
        <div className={"d-flex flex-column row-gap-4"}>
            <div className={"d-flex flex-wrap gap-4"}>
                <Treatment treatment={{
                    name: data?.treatments[0]?.name,
                    state: data?.treatments[0]?.status,
                    date: dateFormatGGmmmYYhhMM(data?.treatments[0]?.status === 'completed' ? data?.treatments[0]?.period?.end : data?.treatments[0]?.period?.start),
                }}
                />

                <div style={{minWidth: '100px', maxWidth: '300px'}}>
                    {/*
                    <span style={{color: 'red'}}>MANCA</span>
*/}
                    <LabelValue label={t('hlv.creationDate')} value={'14 set 2023'} labelClass={'sm'}
                                valueClass={'bold'}/>
                </div>
                <div style={{minWidth: '140px', maxWidth: '300px'}}>
                    <LabelValue label={t('hlv.prescriber')}
                                value={`${data?.practitioner?.name} ${data?.practitioner?.surname}`} labelClass={'sm'}
                                valueClass={'bold'}/>
                </div>
                <div style={{minWidth: '100px', maxWidth: '300px'}}>
                    {/*
                    <span style={{color: 'red'}}>MANCA</span>
*/}
                    <LabelValue label={t('hlv.prescriptionDate')} value={'14 set 2023'} labelClass={'sm'}
                                valueClass={'bold'}/>
                </div>
                <Treatment treatment={{
                    name: 'HealtStation 23',
                    icon: 'Device',
                    state: 'active',
                    date: dateFormatGGmmmYYhhMM(new Date())
                }}/>

            </div>
            <div className={"d-flex flex-column gap-3"}>
                <h4 className={'mb-0'}>{t('hlv.planDetail')}</h4>
                <div className={"d-flex flex-wrap gap-4"}>
                    <div style={{minWidth: '90px', maxWidth: '300px'}}>
                        <LabelValue label={t('hlv.totalSessions')}
                                    value={data?.treatments[0]?.sectionsData?.totalSessions} labelClass={'sm'}
                                    valueClass={'bold'}/>
                    </div>
                    <div style={{minWidth: '90px', maxWidth: '300px'}}>
                        <LabelValue label={t('hlv.weekSessions')}
                                    value={data?.treatments[0]?.sectionsData?.durationWeeks} labelClass={'sm'}
                                    valueClass={'bold'}/>
                    </div>
                    <div style={{minWidth: '160px', maxWidth: '300px'}}>
                        <LabelValue label={t('hlv.firsBlock')}
                                    value={`${data?.treatments[0]?.actions?.bike?.timingDuration?.value} ${data?.treatments[0]?.actions?.bike?.timingDuration?.unit}`}
                                    labelClass={'sm'}
                                    valueClass={'bold'}/>
                    </div>
                    <div style={{minWidth: '120px', maxWidth: '300px'}}>
                        <LabelValue label={t('hlv.secondBlock')}
                                    value={`${data?.treatments[0]?.actions?.bicepsTriceps?.timingTiming?.repeat?.count} ${t('hlv.sets')} / ${data?.treatments[0]?.actions?.bicepsTriceps?.timingTiming?.repeat?.frequency} ${t('hlv.repetitions')}`}
                                    labelClass={'sm'}
                                    valueClass={'bold'}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InfoTreatment;
