import {
    dateFormatGGmmm,
    dateFormatGGmmmYY,
    dateFormatGGmmmYYhhMM,
    dateFormatGGmmmYYYY,
    dateFormatGGmmmYYYYhhMM
} from "../utils/dateUtils";

export function useDate(language: string = 'it') {
    const locales = `${language.toLocaleLowerCase()}-${language.toLocaleUpperCase()}`
    return {
        dateFormatGGmmmYYYYhhMM: (date: string | number | Date) => dateFormatGGmmmYYYYhhMM(date, locales),
        dateFormatGGmmmYYYY: (date: string | number | Date) => dateFormatGGmmmYYYY(date, locales),
        dateFormatGGmmmYYhhMM: (date: string | number | Date) => dateFormatGGmmmYYhhMM(date, locales),
        dateFormatGGmmmYY: (date: string | number | Date) => dateFormatGGmmmYY(date, locales),
        dateFormatGGmmm: (date: string | number | Date) => dateFormatGGmmm(date, locales)
    }
}
