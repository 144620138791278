import React from "react";
import Hl7Load from "../../shared/hl7/Hl7Load";
import {valueSetHl7Name} from "../../shared/hl7/valueSet.hl7";

const Hl7PatientList: React.FC<any> = ({children}) => {

    return (
        <Hl7Load list={[valueSetHl7Name.condition, valueSetHl7Name.carePlan, valueSetHl7Name.patientStatus]}>
            {children}
        </Hl7Load>
    )
}
export default Hl7PatientList
