import {calendarPikerIT} from "./calendar_it";

export const generalIT = {
    ...calendarPikerIT,
    action: {
        select: 'Seleziona',
        continue: 'Continua',
        cancel: 'Annulla',
        back: 'Indietro',
        confirm: 'Conferma',
        close: 'Chiudi',
        orderBy: 'Ordina per',
        delete: 'Cancella',
        edit: 'Modifica',
        save: 'Salva',
        profile: 'Profilo',
        admin: 'Amministrazione',
        logout: 'Esci',
    },
    form: {
        action: {
            select: 'Seleziona',
            addFilters: 'Aggiungi filtri',
            noFilters: 'Nessun filtro da aggiungere',
        },
    },
}
