import React, {ReactChild} from 'react'
import {clickSubVoiceType, ListAppsProps, templatesTypes} from "./components/navbar/models/Nabar.model";
import {OrganizationModel} from "./models/Organization.model";
import {Navbar} from "./components/navbar/Navbar";
import {HeaderMenuModel, VoiceInfoMenuModel} from "./models/Header.model";
import {TemplateModel} from "./models/TemplateModel";


const HeaderComponent: React.FC<{
    template?: TemplateModel,
    subHeaderHtml?: React.ReactNode
    organizations?: OrganizationModel[],
    backHostnames?: string[],
    templateVoices?: {[key: string]: React.ReactNode}
    languages: string[],
    organization_id?: string,
    voices: VoiceInfoMenuModel[],
    currentOrganization?: OrganizationModel,
    changeOrganization?: (id: any) => void
    changeLanguageHandler?: (id: any) => void
    editProfile?: boolean
    user: any,
    changePage?: (route: VoiceInfoMenuModel) => void
    goProfile: () => void
    goToAdmin?: () => void
    currentPage: string;
    currentSection?: string;
    patient?: any;
    logout?: () => void;
    listApps?: ListAppsProps[];
    logo?: any;
    innerTemplateSubNav?: ReactChild
    adminSectionEnabled?: boolean
    showExpand?: boolean
    hideSubPatientNavElements?: string[]
}> = ({
          user,
          templateVoices,
          changePage,
          subHeaderHtml,
          currentPage,
          currentSection,
          voices,
          currentOrganization,
          editProfile,
          logout,
         backHostnames= [],
          goProfile,
          goToAdmin,
          changeLanguageHandler,
          languages,
          changeOrganization,
          organization_id,
          organizations,
          template,
          patient,
          listApps,
          logo,
          innerTemplateSubNav,
          adminSectionEnabled,
          showExpand,
          hideSubPatientNavElements
      }) => {

    const goToPage: clickSubVoiceType = (sub) => {
        sub.url ? window.open( sub.url as string, sub.target ? sub.target : '_self' ) : (changePage && changePage(sub));
    }
    const goToAdminPage = (path: string) => {
        window.open( path as string, '_blank' );
    }
    const goBack = () => {
        //todo Massy verificare back lsi
        if ([window.location.hostname, ...backHostnames].find(ele => document.referrer.includes(ele))) {
            window.history.back();
        } else {
            window.location.href = "/";
        }
    }

    return (
        <>
            <Navbar
                goBack={goBack}
                editProfile={editProfile}
                templateVoices={templateVoices}
                logout={logout}
                goProfile={goProfile}
                goToAdmin={goToAdminPage}
                subHeaderHtml={subHeaderHtml}
                currentOrganization={currentOrganization}
                changeLanguageHandler={changeLanguageHandler}
                changeOrganization={changeOrganization}
                menuItems={voices}
                languages={languages}
                patient={patient}
                currentPage={currentPage}
                currentSection={currentSection}
                template={template}
                user={user}
                organizations={organizations}
                organization_id={organization_id}
                clickSubVoice={goToPage}
                hideSubPatientNavElements={hideSubPatientNavElements}
                listApps={listApps}
                logo={logo}
                innerTemplateSubNav={innerTemplateSubNav}
                adminSectionEnabled={adminSectionEnabled}
                showExpand={showExpand}
            />
        </>
    )
}

export default HeaderComponent
