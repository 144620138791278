import {useLanguageForShared} from "../utils/languages/useLanguageForShared";
import {settings} from "./moment";

export function useLocale(){
    const {lang} = useLanguageForShared();

    return {
        locale: settings[lang]
    }
}
