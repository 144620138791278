import React from "react";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";
import {useDateCustom} from "../../../../shared/custom_use/useDateCustom";

const TreatmentsHeader: React.FC<any> = () => {
    const {t} = useLanguageForShared()
    const {data: {dateFormatGGmmm}} = useDateCustom()
    const date = `${dateFormatGGmmm(new Date().setDate(new Date().getDate() - 30))} - ${dateFormatGGmmm(new Date())}`
    return   <div className={'col-treatments'}>
        {t('patientList.headingTable.treatment')} <small>{date}</small>
    </div>
}
export default TreatmentsHeader
