import React from 'react'
import {Footer} from "./components/Footer";
import BimmoTemplate from "./components/templates/BimmoTemplate";


interface TemplateModel {
    name: string,
    data?: any
}

const FooterComponent: React.FC<{
    rightTemplate?: TemplateModel,
    customerName?: string
    version?: string;
}> = ({rightTemplate, customerName, version}) => {
    const template: any = {
        bimmo: (data: any) => <BimmoTemplate data={data}/>
    }

    const defaultTemplate = <>{`© ${new Date().getFullYear()} ${customerName}`}</>

    return (
        <>
            <Footer leftTemplate={defaultTemplate}
                    righTemplate={<div
                        className={'d-flex'}>{rightTemplate && template && template[rightTemplate.name](rightTemplate.data)}
                        {version && <span className={'ms-2'}>{version}</span>}</div>}/>
        </>
    )
}

export default FooterComponent
