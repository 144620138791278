import React, {ReactChild} from "react";
import {FooterComponent} from "../../../shared/bootstrap/footer/Footer";

export const Footer = ({leftTemplate, righTemplate}: {
    leftTemplate?: ReactChild
    righTemplate?: ReactChild
}) => {
    return (
        <FooterComponent
            leftTemplate={leftTemplate}
            righTemplate={righTemplate}
        ></FooterComponent>
    );
};
