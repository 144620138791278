import React from 'react';
import BtnPrimary from "../../shared/bootstrap/button/primary/BtnPrimary";
import ModalCustom from "../../shared/bootstrap/modal/ModalCustom";
import {useLanguageForShared} from "../../shared/utils/languages/useLanguageForShared";
import {useAuth} from "oidc-react";

const ChangeUser = () => {
    const {t} = useLanguageForShared()
    const auth = useAuth();

    const changeUser = () => {
        return auth.signOutRedirect()
    }

    return (
        <div>
            <ModalCustom
                title={t('hlv.change_user_title')}
                footerTemplate={<BtnPrimary onClick={changeUser}>{t('hlv.change_user_action')}</BtnPrimary>}
            >
                <h4>{t('hlv.change_user_info')}</h4>
                <p className={'fs-5'}>{t('hlv.change_user_help')}</p>
            </ModalCustom>
        </div>
    );
};

export default ChangeUser;
