import React from 'react'

interface UserAvatarProps {
    user: any
    clickHandler: () => void
}

const UserAvatar = ({user, clickHandler}: UserAvatarProps) => {
    return (
        <div className="shared-user-avatar pointer" data-test="user-avatar" onClick={clickHandler}>
            {user.match(/\b\w/g).join('').toUpperCase()}
        </div>
    )
}

export default UserAvatar
