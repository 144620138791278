import React, {useEffect, useState} from "react";
import {ChartCustom} from "./ChartCustom";
import {useLocale} from "../moment/useLocale";
import {useEffectOnlyUpdate} from "../utils/use_custom/useEffectCustom";

export function ChartWithZoom({
                                  printView = false,
                                  dataTable = [],
                                  width = '100%',
                                  height = '100%',
                                  hAxis = {},
                                  vAxis = {},
                                  options = {},
                                  chartType = "ColumnChart",
                                  formatters = [],
                                setImg = undefined,
                                img = undefined
                              }) {
    const {locale} = useLocale();
    const [show, setShow] = useState(true);
    useEffectOnlyUpdate(() =>  {
        setShow(false)
        setTimeout(() => {
            setShow(true)
        }, 1)
    }, [dataTable])
    const hAxisInfo = {
        gridlines: {
            // count: -1,
            units: {
                days: {format: [locale? locale.other.LL : 'YY MMM dd']},
                hours: {format: [locale? locale.other.LLL : 'YY MMM dd HH:mm']},
                minutes: {format: [locale? locale.other.LLL : 'YY MMM dd HH:mm']},
                seconds: {format: [locale? locale.other.fullDateWithSeconds : 'YY MMM dd HH:mm:ss', ':mm']}
            }
        },
        minorGridlines: {
            units: {
                days: {format: [locale? locale.other.LL : 'YY MMM dd']},
                hours: {format: ['HH:mm']},
                minutes: {format: ['HH:mm', ':mm']},
                seconds: {format: ['HH:mm:ss', ':mm']},
                milliseconds: {format: ['', ':mm']}
            }
        },
        ...hAxis
    }
    return (
        <>{show && <ChartCustom setImg={setImg} dataTable={dataTable} formatters={formatters} chartType={chartType} width={width} height={height}
                                hAxis={hAxisInfo} vAxis={vAxis} options={options} chartPackages={["corechart", "controls"]}
                                controls={[
                                    {
                                        controlType: "ChartRangeFilter",
                                        options: {
                                            filterColumnIndex: 0,
                                            ui: {
                                                chartType: "LineChart",
                                                chartOptions: {
                                                    pointSize: 2,
                                                    lineWidth: 1,
                                                    height: 80,
                                                    fontSize: 10,
                                                    chartArea: {
                                                        width: "100%",
                                                        height: 33,
                                                        left: 60,
                                                        right: 60,
                                                    },
                                                    colors: [...options.colors],
                                                    hAxis: {
                                                        viewWindowMode: 'pretty',
                                                        textPosition: "none",
                                                        baselineColor: "none",
                                                        slantedText: false,
                                                        slantedTextAngle: 0,
                                                        ...hAxis
                                                    },
                                                    vAxis: {
                                                        viewWindow: {
                                                            min: 0,
                                                        }, logScale: true,
                                                        ...vAxis
                                                    }
                                                }
                                            }
                                        },
                                        controlPosition: "bottom",
                                        // controlWrapperParams: {
                                        //     state: {
                                        //         range:  {
                                        //             start: new Date(2021, 4, 1),
                                        //             end: new Date(2022, 4, 1)
                                        //         }
                                        //     }
                                        // }
                                    }
                                ]}/>}</>

    );
}
