import React from 'react';
import ActionTopBar from "../../../../shared/custom/actionBar/ActionTopBar";
import {TitleHeadingH3} from "../../../../shared/custom/headings/TitleHeadingH3";
import BtnList from "../../../../shared/custom/actionBar/BtnList";
import Treatment from "../../../../shared/UI components/Treatment";
import LabelValue from "../../../../shared/custom/labelValue/LabelValue";
import Table from "../../../../shared/table/table/Table";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";

const HistoricalPlan = () => {
    const {t} = useLanguageForShared()

    const structure = [
        {
            name: "creationDate",
            label: t('hlv.creationDate'),
        },
        {
            name: "totalSessions",
            label: t('hlv.totalSessions'),
        },
        {
            name: "weekSessions",
            label: t('hlv.weekSessions'),
        },
        {
            name: "firstBlock",
            label: t('hlv.firstBlock'),
        },
        {
            name: "setsRepetitions",
            label: t('hlv.secondBlock'),
        },
    ]

    const templates = {
        /*  name: (value: string, row: any) => {
              return (
                  <div className="d-flex flex-column">
                      <span>{row.name}</span>
                      <span>{row.duration}</span>
                  </div>
              );
          },*/
    }
    const sampleData = [
        {
            creationDate: '12/12/12',
            totalSessions: '36',
            weekSessions: '4',
            firstBlock: '30 min every 15 min',
            setsRepetitions: '4/3',
        },
        {
            creationDate: '12/12/12',
            totalSessions: '36',
            weekSessions: '4',
            firstBlock: '30 min every 15 min',
            setsRepetitions: '4/3',
        }
    ]
    return (
        <div className={"col-12"}>
            <Table columns={structure} templates={templates} data={sampleData} titleTable={t('hlv.historical_plan')}/>
        </div>
    );
};

export default HistoricalPlan;
