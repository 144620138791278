import React from "react";
import { BackBtnNav } from "./components/BackBtnNav";
import { mergeClass } from "../../../../shared/utils/htmlUtils";


export const SubNav = ({
                           isExpand,
                           goBack,
                           children,
                           innerTemplate
                       }: { isExpand?: boolean, goBack?: () => void, children: React.ReactNode, innerTemplate?: React.ReactNode }) => {
    const subNavStyle = mergeClass("sub-nav", isExpand ? 'isExpand' : '')
    return (
        <>
            <div className={subNavStyle}>
                <div className={'first-row'}>
                    <BackBtnNav clickHandler={() => goBack && goBack()}/>
                    {children}
                </div>
                {innerTemplate && <div className={'inner-row'}>
                    {innerTemplate}
                </div>}
            </div>
        </>
    );
};
