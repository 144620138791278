import React from 'react'
import BoxFullScreen from '../../boxFullScreen/BoxFullScreen'

import Loader from '../loader/Loader'
import {useSelector} from "react-redux";
import {selectActiveLoad, selectActiveManualLoad, selectStopLoad} from "./loadSlice";

interface LoaderProps {
  children?: React.ReactNode
  className?: string
  show?: boolean
  label?: string
}

const LoaderFullScreen = ({
  children,
  className,
  label,
  show
}: LoaderProps) => {

  return (
    <BoxFullScreen show={show}>
      <div className="loader">
        <Loader></Loader>
        {label && label}
      </div>
    </BoxFullScreen>
  )
}
export default LoaderFullScreen
