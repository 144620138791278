import React from 'react';
import {Provider} from 'react-redux';
import reportWebVitals from './reportWebVitals';
import './index.scss';
import {store} from "./shared/redux/store";
import ReactDOM from "react-dom";
import Init from "./Init";
import ConfigLoad from "./shared/shared_load/ConfigLoad";
import LanguageLoad from "./shared/shared_load/LanguageLoad";

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <ConfigLoad>
                <LanguageLoad language='en'>
                    <Init/>
                </LanguageLoad>
            </ConfigLoad>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
