import React, { ReactChild } from 'react'

export const HeadingLayout = ({
  leftTemplate,
  centerTemplate,
  rightTemplate,
}: {
  leftTemplate?: ReactChild
  centerTemplate?: ReactChild
  rightTemplate?: ReactChild
}) => {
  return (
    <div className="sh-heading">
      <div className="left">{leftTemplate}</div>
      <div className="center">{centerTemplate}</div>
      <div className="right">{rightTemplate}</div>
    </div>
  )
}
