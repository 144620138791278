import React from 'react';
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";
import AlertItem from "../../../../shared/custom/tag/AlertItem";
import {useDateCustom} from "../../../../shared/custom_use/useDateCustom";

const AlertRow = ({patient}: { patient: any }) => {
    const {t} = useLanguageForShared()
    const {data: {dateFormatGGmmmYYYY}} = useDateCustom()


    return (
        <div className={'alert-row'}>
            {patient.alarm && <AlertItem label={t('patientList.alert.alarm')} state={'alarm'}/>}
            {patient.alert && <AlertItem label={t('patientList.alert.alert')} state={'alert'}/>}
            {patient.questionnairesNotComplete && patient.questionnairesNotComplete.status === 'not-complete' &&
                <AlertItem label={t('patientList.alert.questionnairesNotComplete')} icon={'alert-msg'}/>}
            {patient.toContact && <AlertItem label={t('patientList.alert.toContact')} icon={'contact-user'}/>}
            {patient.newMeasurement && <AlertItem label={t('patientList.alert.newMeasurement')} icon={'bell'}/>}
            {patient.nextAppointment && patient.nextAppointment?.date && <AlertItem
                label={`${t('patientList.alert.nextAppointment')} ${patient.nextAppointment?.date ? dateFormatGGmmmYYYY(patient.nextAppointment?.date) : '-'}`}
                icon={'note'}/>}
            {patient.prescription && <AlertItem
                label={`${t('patientList.alert.prescriptionFrom')} ${patient.prescription ? dateFormatGGmmmYYYY(patient.prescription?.date) : '-'}`}
                icon={'cf'}/>}
        </div>
    );
};

export default AlertRow;
