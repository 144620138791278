import React, {Fragment, useState} from 'react';
import IconLabel from "../../custom/icon/IconLabel";
import {Popover, PopoverPosition, ContentRenderer, ArrowContainer} from 'react-tiny-popover'

const PopoverCustomClick = ({
                                positions,
                                children,
                                content,
                                trigger = 'click'
                            }: {
    positions?: PopoverPosition[],
    children: React.ReactChild,
    content: React.ReactChild,
    trigger?: 'click' | 'mouseOver'
}) => {
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    return (
        <Popover
            isOpen={isPopoverOpen}
            positions={positions}
            content={({position, childRect, popoverRect}) => (
                <ArrowContainer
                    position={position}
                    childRect={childRect}
                    popoverRect={popoverRect}
                    arrowColor={'#fff'}
                    arrowSize={10}
                    arrowStyle={{opacity: 1}}
                    className='popover-arrow-container'
                    arrowClassName='popover-arrow'
                >
                    <>
                        <div
                            className={"popover-content"}
                            onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                        >
                            {content}
                        </div>
                    </>
                </ArrowContainer>
            )}
        >
            <div onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
                {children}
            </div>
        </Popover>
    );
};

export default PopoverCustomClick;
