import React from 'react';
import LabelValue from "../../../../shared/custom/labelValue/LabelValue";
import SessionTitle from "../../../../shared/custom/section/SessionTitle";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";
import {mapStateColor} from "../../../../shared/utils/mapStateColor";
import Tag from "../../../../shared/custom/tag/Tag";


const FirstBlock = ({session}: { session: any }) => {
    const {t} = useLanguageForShared()

    return (
        <div className={'d-flex gap-5 row-gap-2 flex-wrap flex-xl-nowrap'}>

            <div className={'d-flex flex-column gap-1 align-items-start'} style={{minWidth: '160px'}}>
                <SessionTitle label={t('hlv.firstBlockCyclette')}/>
                <LabelValue inline className={'mb-0'} label={t('hlv.duration')} value={'00:52:03'} labelClass={'sm'}
                            valueClass={'bold'}/>
                <Tag classNames={'text-sm'} label={'Completed'} bgColor={mapStateColor('active')?.bg}
                     color={mapStateColor('active')?.color}/>
            </div>

            <div className={"d-flex flex-column gap-1 flex-wrap"}>
                <div className={'d-flex gap-5 row-gap-1 flex-wrap'} style={{height: '26px'}}>
                    <div style={{minWidth: '120px', maxWidth: '120px'}}>
                        <LabelValue inline label={t('hlv.type')} value={'Autonomous'} labelClass={'sm'}
                                    valueClass={'bold'}/>
                    </div>
                    <div style={{height: '26px'}}>
                        <LabelValue inline label={t('hlv.power')}
                                    value={'100'} labelClass={'sm'}
                                    valueClass={'bold'}/>
                    </div>
                </div>
                <div className={"d-flex flex-wrap gap-5 row-gap-1 flex-wrap"}>
                    <div style={{minWidth: '120px', maxWidth: '120px'}}>
                        <LabelValue label={t('hlv.spo2_min')} value={session?.block1Spo2Min?.value || '-'} labelClass={'sm'}
                                    valueClass={'bold'}/>
                    </div>
                    <div style={{minWidth: '120px', maxWidth: '120px'}}>
                        <LabelValue label={t('hlv.spo2_avg')} value={session?.block1Spo2Avg?.value || '-'} labelClass={'sm'}
                                    valueClass={'bold'}/>
                    </div>
                    <div style={{minWidth: '120px', maxWidth: '120px'}}>
                        <LabelValue label={t('hlv.spo2_max')} value={session?.block1Spo2Max?.value || '-'} labelClass={'sm'}
                                    valueClass={'bold'}/>
                    </div>

                    <div style={{minWidth: '120px', maxWidth: '130px'}}>
                        <LabelValue label={t('hlv.bpm_min')} value={session?.block1BpmMin?.value || '-'} labelClass={'sm'}
                                    valueClass={'bold'}/>
                    </div>
                    <div style={{minWidth: '120px', maxWidth: '130px'}}>
                        <LabelValue label={t('hlv.bpm_avg')} value={session?.block1BpmAvg?.value || '-'} labelClass={'sm'}
                                    valueClass={'bold'}/>
                    </div>
                    <div style={{minWidth: '120px', maxWidth: '130px'}}>
                        <LabelValue label={t('hlv.bpm_max')} value={session?.block1BpmMax?.value || '-'} labelClass={'sm'}
                                    valueClass={'bold'}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FirstBlock;
