import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {AppThunk, RootState} from "../shared/redux/store";
import {OrganizationModelHl7} from "../libraries/header/models/Organization.model";
import {getOrganizations} from "../services/organization.service";
import {patientMock} from "../core/layout/mock/patientMock";
import http from "../shared/utils/http";
import configuration from "../shared/configuration";
import {findMoreResourceByReference, findResourceByReference} from "../shared/hl7/hl7FunctionSearch";


const initialState: any = {
  currentPatient: undefined
}

export const patientSlice = createSlice({
  name: 'patient',
  initialState,
  reducers: {
    setCurrentPatient: (state, action: PayloadAction<any>) => {
      state.currentPatient = action.payload
    },
    resetCurrentPatient: (state) => {
      state.currentPatient = undefined
    }
  },
})

export const {
    setCurrentPatient,
    resetCurrentPatient
} = patientSlice.actions


export const getCurrentPatientAction = (id: any): AppThunk => (
  dispatch
) => {
  http.get(configuration.external.hl7.httpUrl + '/composition/v1/demo/fhir/Composition?type=urn:ae:patient:summary|header-info&subject=Patient/' + id).then(res => {
    const entry = res.entry[0];
    const patient = findResourceByReference(entry, 'patient-info', 'Patient');
    const carePlans = findMoreResourceByReference(entry, 'care-plan', 'CarePlan').map((ele: any) => {
      const url = ele.instantiatesCanonical[0].split('|')[0];
      const translate = entry?.resource?.contained?.find((ele: any) => ele?.resourceType === "PlanDefinition" && ele?.url === url);
      const name = translate?.title || ''
      return {...ele, name}
    })
    const pathologies = findMoreResourceByReference(entry, 'pathology', 'Condition');
    const treatments = findMoreResourceByReference(entry, 'treatment', 'CarePlan').map((ele: any) => {
      const url = ele.instantiatesCanonical[0].split('|')[0];
      const translate = entry?.resource?.contained?.find((ele: any) => ele?.resourceType === "PlanDefinition" && ele?.url === url);
      const name = translate?.title || ''
      return {...ele, name}
    })

      dispatch(setCurrentPatient({patient, carePlans, pathologies, treatments}))
  })

}

export const selectCurrentPatient = (state: RootState) => state.patient.currentPatient

export default patientSlice.reducer
