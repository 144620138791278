import React from 'react';
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";
import {useDateCustom} from "../../../../shared/custom_use/useDateCustom";
import {useHl7Translate} from "../../../../shared/hl7/useHl7Translate";
import {valueSetSubHl7Name} from "../../../../shared/hl7/valueSet.hl7";


const TreatmentItem = ({treatment}: { treatment: { name: string, date: string } }) => {
    const {t} = useLanguageForShared()
    const value = treatment?.name
    const {data: dataformat} = useDateCustom()

    return (
        <div className={"item-treatment"}>
            <b>{value}</b>
            {treatment?.date ?
                <small>{t('patientList.from')} {dataformat.dateFormatGGmmmYY(treatment?.date)}</small> : ''}
        </div>
    );
};

export default TreatmentItem;
