import React, { useEffect, useState } from 'react'
import DateRangePicker, {EventHandler} from 'react-bootstrap-daterangepicker'
import { useLanguageForShared } from '../../../../utils/languages/useLanguageForShared'
import {datePickerBaseSetting} from "./datePickerBaseSetting";


const DatePickerBase = ({
                            settings,
                            localeSettings = {},
                            onCallback,
                            myRef,
                            onEvent,
                            issetMyRef,
                            onApply,
                            dataTest
                        }: {
    settings: any
    localeSettings: any
    onCallback: (start: any, end: any) => void
    myRef?: any
    issetMyRef?: () => void;
    onEvent?: EventHandler
    onApply?: EventHandler
    dataTest?:string
}) => {
    const { lang } = useLanguageForShared()
    const [show, setShow] = useState(false)
    const [issetRef, setIssetRef] = useState<boolean>(false)
    const settingsDatePiker = datePickerBaseSetting();
    useEffect(() => {
        setShow(false)
        setTimeout(() => {
            setShow(true);
        }, 1)
    }, [lang])
    const findMyRef = () => {
        myRef.current ? setIssetRef(true) :
            setTimeout(() => {
                findMyRef()
            }, 100)
    }
    useEffect(() => {
        findMyRef();
    }, [myRef.current])
    useEffect(() => {
        issetRef && issetMyRef && issetMyRef();
    }, [issetRef])
    return (
        <div className="date-picker">
            {show && (
                <DateRangePicker
                    ref={myRef}
                    initialSettings={{
                        ...settingsDatePiker,
                        ...settings,
                        locale: {
                            ...settingsDatePiker.locale,
                            ...localeSettings
                        }
                    }}
                    onCallback={onCallback}
                    onEvent={(event, picker) => onEvent && onEvent(event, picker)}
                    onApply={(event, picker) => onApply && onApply(event, picker)}

                >
                    <input type="text" className="form-control" data-test={'datepiker-'+dataTest}/>
                </DateRangePicker>
            )}
        </div>
    )
}

export default DatePickerBase

