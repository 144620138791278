import React from 'react';
import {IconSVG} from "../../../../shared/custom/icon/IconSVG";
import configuration from "../../../../shared/configuration";

const BimmoTemplate = ({data}: { data: any }) => {
    return (
        <div className={'d-flex align-items-end fw-bolder'}>BIMMO <IconSVG icon={'ce'}
                                                                           size={"12px"}
                                                                           tintColor={configuration.theme.primaryDark}
                                                                           className={'mx-1'}
                                                                           style={{marginBottom: '-1px'}}/> 0425 {data &&
            <span className={'ms-2'}>{data}</span>}
        </div>
    );
};

export default BimmoTemplate;
