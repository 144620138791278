import React, {useEffect, useRef} from 'react'
import {FormElements} from "../../../../shared/bootstrap/form/FormElements";
import {useForm} from "../../../../shared/utils/forms/useForm";
import {OrganizationModel} from "../../models/Organization.model";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";
import {useEffectOnlyUpdate} from "../../../../shared/utils/use_custom/useEffectCustom";

interface ChangeOrganizationsProps {
    organizations?: OrganizationModel[];
    organization_id?: string;
    changeOrganization?: (id: any) => void
}

export const ChangeOrganizations: React.FC<ChangeOrganizationsProps> = ({
                                                                            organizations = [],
                                                                            changeOrganization,
                                                                            organization_id
                                                                        }) => {
    const submitHandler = () => {

    }
    const {t, lang} = useLanguageForShared()
    const {dataForm, changeValue, setData, errors} = useForm(submitHandler, undefined, {organization_id})
    useEffectOnlyUpdate(() => {
        setData({organization_id})
    }, [organization_id])
    const formStructure = [
        {
            name: 'organization_id',
            type: 'select',
            options: organizations?.map(ele => ({
                label: ele.Name || ele.name,
                value: ele.Id || ele.id
            })),
            label: t('header.sidebar.organization'),
            dataElement: {
                placeholder: '',
                type: 'text',
                dataTest: 'data-test-select-change-organization'
            },
            col: {xs: "12"},
        }
    ]

    const changeValueHandler = (data: any, val: any) => {
        changeOrganization && changeOrganization(data.value)
    }
    return (
        <FormElements
            structure={formStructure}
            dataForm={dataForm}
            errors={errors}
            changeValue={changeValueHandler}
        />
    )
}
