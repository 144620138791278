import React from 'react';
import Tag from "../../../../shared/custom/tag/Tag";
import {useHl7Translate} from "../../../../shared/hl7/useHl7Translate";
import {valueSetHl7Name} from "../../../../shared/hl7/valueSet.hl7";
import {mapStateColor} from "../../../../shared/utils/mapStateColor";

const PatientStateItem = ({state}: { state: string }) => {
    const {value} = useHl7Translate(state, valueSetHl7Name.patientStatus)
    return (
        <div>
            <Tag label={value} bgColor={mapStateColor(state)?.bg} color={mapStateColor(state)?.color}/>
        </div>
    );
};

export default PatientStateItem;
