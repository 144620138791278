import {generalEN} from "../../../../config/lang/general_en";
import {devicesEN} from "./devices/devices_en";

export default {
    ...generalEN,
    ...devicesEN,
    header: {
        nav: {
            voices: {
                notifications: 'Notifications',
                notification_list: 'Notification List',
                appointments: 'Appointments',
                appointments_calendar: 'Calendar',
                patients: 'Patients',
                patients_list: 'patient list',
                patients_respiratory_compliance: 'Respiratory Compliance',
                devices: 'Devices',
                devices_list: 'Device list',
                services: 'Services',
                users: 'Users',
                organizations: 'Organizations',
                manage_users: 'Manage users',
                manage_organizations: 'Manage organizations',
                help: 'Help',
                manual: 'Manual',
                info: 'Information on',
                support: 'Support',
                billing: 'Billing',
                costs: 'Costs',
                invoices: 'Invoices'
            }
        },
        sidebar: {
            userProfile: 'User Profile',
            organization: 'Organization',
            language: 'Language',
        }
    },
    common: {
        pathology: 'Pathology',
        treatment: 'Treatment',
        device: 'Device',
    },
    sidebarMenu: {
        nav: {
            voices: {
                patient: 'Patient',
                personal_data: 'Personal data',
                patologies_treatments: 'Patologies and treatments',
                devices: 'Devices',
                services: 'Services',
                treatments: 'Treatments',
                summary: 'summary',
                trend_over_time: 'Trend over time',
                wave_forms_over_time: 'Wave forms over time',
                prescription_Log: 'Prescription Log',
                biomedical_parameters: 'Biomedical Parameters',
                logbook_spo2: 'Logbook SpO2/BPM',
                report_spo2: 'Report SpO2/BPM',
                logbook_glycemia: 'Logbook glycemia',
                logbook_weight: 'Logbook weight',
                logbook_temperature: 'Logbook temperature',
                logbook_pressure: 'Logbook pressure',
                questionnaires: 'Questionnaires',
                test_sleep: 'sleep test del sonno',
                test_init_trerapy: 'Test di inizio terapia',
                test_trend_terapy: 'Test di andamento della terapia',
                surveillance: 'Sorveglianza',
                monitoring_plan: 'Piani di monitoraggio',
                pharmacological_treatment: 'Trattamento farmacologico',
                patientGraph: 'Status summary and ongoing performance',
                patientRegister: 'Registry',
                patientPrescriptions: 'Treatments',
                patientProvisions: 'Performed and planned performance',
                patientRequestPrescription: 'Request prescription',
                patientDevices: 'Devices',
                patientAccessories: 'Accessories',
                patientConsumable: 'Consumables',
                patientMedicines: 'Drugs',
                patientInvoices: 'Billed amounts',
                provision: 'Provisions',
                deviceCard: 'Device Card',
                deviceLastPatientAssigned: 'Last Patient Assigned',
                deviceLogBook: 'Log Book',
                deviceAccessories: 'Accessories',
                provisionSectionMain: 'Main Information',
                provisionPatientInfo: 'Patient Information',
                provisionDevices: 'Devices',
                provisionAccessories: 'Accessories',
                provisionConsumables: 'Consumables',
                provisionInfoDetail: 'Information',
            }
        }
    },
    languages: {
        it: 'IT - Italian',
        en: 'EN - English',
        ro: 'RO - Romanian',
    },
    prescription: {
        on: 'YES',
        off: 'NO',
        settingsDevice: "Device settings",
        settingFrom: 'Setting from',
        showOtherSettings: 'Show other settings',
        hideOtherSettings: 'Hide other settings',
        mode: 'Mode',
        dataAssociateFrom: "Data associate from",
        associateDate: "Associate date",
        state: "Sstate",
        telemetry: "Telemetry",
        from: "from",
        settingsFrom: "Dettings from",
        lastSettingFrom: 'Last setting from',
        of: 'of',
        noSettings: 'No settings available',
        stateType: {
            active: 'Active',
            inactive: 'Inactive',
            enabled: 'Enabled',
            disabled: 'Disabled'
        },
        editTreatment: "Edit treatment",
        introduction: "This wizard allows you to modify the parameters of the associated treatment and request a variation of the treatment for the actual device.",
        stepOne: "Step 1 of 2",
        stepTwo: "Step 2 of 2",
        step: "Step",
        summary: "Summary",
        idRequest: "ID Request",
        updateButton: "Edit treatment",
        template: {
            mode: {
                label: "Mode",
            },
            device: {
                label: "Device Model",
                placeholder: "Insert device model",
            },
            modelCannula: {
                label: "Tracheostomy tube model",
                placeholder: "Insert tracheostomy tube model",
            },
            regulation: {
                label: "Discharge regulation",
                placeholder: "Insert regulation at resignation",
            },
            pressure: {
                label: "Pressure (cmH2O)",
                placeholder: "eg. CPAP",
            },
            ipap_max: {
                label: "IPAP max",
                placeholder: "eg. 10",
            },
            ipap_min: {
                label: "IPAP min",
                placeholder: "eg. 4",
            },
            epap_max: {
                label: "EPAP max",
                placeholder: "eg. 10",
            },
            epap_min: {
                label: "EPAP min",
                placeholder: "eg. 4",
            },
            press_min: {
                label: "Press. min (cmH2O)",
                placeholder: "eg. 4",
            },
            press_max: {
                label: "Press. max (cmH2O)",
                placeholder: "eg. 10",
            },
            epap: {
                label: "EPAP",
                placeholder: "eg. 4",
            },
            ipap: {
                label: "IPAP",
                placeholder: "eg. 4",
            },
            notesHumidifier: {
                label: "Level of umidifier",
                placeholder: "Insert level of humidifier"
            },
            cpap: 'CPAP',
            autost: 'AUTO S/T',
            apap: 'APAP',
            st: 'ST',
            t: 'T',
            apvc: 'APVC',
            s: 'S',
            autocpap: 'AUTOCPAP',
            bilevels: 'BILEVEL S',
            bilevelauto: 'BILEVEL AUTO',
            rampa: {
                label: "Rampa",
            },
            initialPress: {
                label: "Press. initial (cmH2O)",
                placeholder: "es. 4",
            },
            duration: {
                label: "Duration (min)",
                placeholder: "es. 10",
            },
            interface: {
                label: "Interface",
            },
            interfaceType: {
                label: "Interface Type",
                placeholder: "es. Nasale",
            },
            interfaceModel: {
                label: "Model",
                placeholder: "Insert mask model",
            },
            interfaceSize: {
                label: "Size",
                placeholder: "es. M",
            },
            humidifier: {
                label: "Hot humidifier",
            },
            note: {
                label: "Note",
            },
        }
    },
    treatments: {
        'Sleep': 'Sleep',
        'Sleep_Bilevel': 'Sleep Bilevel',
        'Sleep_CPAP_APAP': 'Sleep CPAP/APAP',
        'NIV': 'Vent',
        'Other': 'Other',
    },
    pathology: {
        'COPD': 'COPD',
        'SAS': 'OSAS',
        'COPDSAS': 'COPD-OSAS',
        'OHS': 'OHS',
        'CSA': 'CSAS',
        'RestrictivePathology': 'Restrictive pathology',
        'NeuroMuscularDisease': 'Neuromuscolar disease',
        'Other': 'Other',
        'Obesity': 'Obesity',
        'Asthma': 'Asthma',
        'RespiratoryFailure': 'Respiratory failure',
        'HeartFailure': 'Heart failure',
        'KidneyFailure': 'Kidney failure',
        'Hyperthyroidism': 'Hyperthyroidism',
        'Hypothyroidism': 'Hypothyroidism',
        'IschemicHeartDisease': 'Ischemic heart disease',
        'Cerebrovascular': 'Cerebrovascular disease',
        'Diabetes': 'Diabetes type II',
        'RLS_PLM': 'RLS/PLM',
        'Insomnia': 'Insomnia',
        'Anxiety': 'Anxiety',
        'Parkinson': 'Parkinson',
    },
    msg: {
        requiredField: '(*) Fields required',
        emptyData: {
            title: "Sorry, no report available.",
            subTitle: "There is currently no report.",
        },
        success: {
            requestSend: 'Change request sent successfully',
            emptyData: {
                title: "Sorry, no report available.",
                subTitle: "There is currently no report.",
            }
        },
        errors: {
            be: {},
            fe: {
                required: 'The field {{name}} is required',
                requiredOne: 'Fill in the field or add {{name}}',
            },
            errorsMsg: {
                noPermission: 'You are not authorized for this operation',
                token: 'Token expired or incorrect',
                genericSave: 'Internal error, please try again later',
                pdfDownload: 'Error to generate pdf, please try later.'
            },
        },
    }
}
