export const devicesIT= {
    "ibreeze": {
        "therapy_mode": "Modo",
        "r_care": "R-Care",
        "e_comp": "E-COMP",
        "p_max": "P Max",
        "p_min": "P Min",
        "press": "Pressione",
        "epap_min": "EPAP Min",
        "epap_max": "EPAP Max",
        "ipap": "IPAP",
        "ti": "Ti",
        "ti_max": "Ti Max",
        "i_trigger": "I Genera",
        "ipap_min": "IPAP min",
        "epap": "EPAP",
        "ti_min": "Ti Min",
        "e_trigger": "E Genera",
        "ps": "PS",
        "vt": "VT",
        "ti_control": "Ti Controllo",
        "rise_time": "Tempo sal",
        "fast_parameter": "Parametro veloce",
        "auto_f": "Auto F",
        "f": "F",
        "ipap_max": "IPAP max",
        "press_adj": "Press. ADJ",
        "humidity": "Umidità",
        "p_ramp": "P Ramp",
        "ipr": "iPR",
        "mask_test_pressure": "P Mask Fit",
        "preheat": "Riscald",
        "auto_ramp": "Ramp auto",
        "mask_type": "Maschera Tipo",
        "auto_stop": "Auto Stop",
        "auto_start": "Auto Start",
        "ramp": "Tempo Ramp",
        "tube_type": "Tipo tubo",
        "patient_info": "Menu info paziente",
        "filter": "Filtro",
        "water_tube": "Cisterna",
        "press_unit": "Unità misura Pressione",
        "leak": "Perdita alto",
        "mask": "Masch",
        "clinic": "Clinica",
        "apnoea": "Apnea",
        "mask_vent_holes_blocked": "Bloccato foro maschera",
        "tube": "Tubo",
        "clinic_time": "Tempo Servizio",
        "rr_high": "RR alto",
        "rr_low": "RR basso",
        "minvent_low": "MV basso",
        "vt_low": "VT basso",
        "ipap_high": "IPAP alto",
        "epap_low": "EPAP basso"
    },
    "s8": {
        "therapy_mode": "Modalità",
        "mode": "Modalità",
        "press": "Imposta pressione (cmH20)",
        "ramp_time": "Tempo di rampa (min)",
        "ramp_enable": "Abilitazione rampa",
        "eprtype": "EPR",
        "eprlevel": "Livello EPR (cmH20)",
        "startpress": "Pressione iniziale (cmH2O)",
        "minpress": "Pressione min (cmH2O)",
        "maxpress": "Pressione max (cmH2O)",
        "autosetresponse": "Risposta",
        "epap": "EPAP (cmH2O)",
        "ipap": "IPAP (cmH2O)",
        "ibrenable": "iBR",
        "resprate": "Frequenza respiratoria (respiri/min)",
        "startepap": "EPAP iniziale (cmH2O)",
        "minepap": "EPAP Min (cmH2O)",
        "maxepap": "EPAP Max (cmH2O)",
        "epapautoenable": "EPAP Auto",
        "maxipap": "IPAP Max (cmH2O)",
        "presssupport": "Aiuto respiratorio (cmH2O)",
        "minps": "AI Min (cmH2O)",
        "maxps": "AI Max (cmH2O)",
        "tgtpatientrate": "Frequenza paziente target (respiri/min)",
        "tgtalveolarvent": "Ventilazione alveolare target (l/min)",
        "triggersensitivity": "Trigger",
        "ramp_down_enable": "Abilitazione discesa della rampa",
        "timin": "Ti Min (s)",
        "timax": "Ti Max (s)",
        "heightcm": "Altezza del paziente (cm)",
        "risetime": "Tempo di salita (ms)",
        "circuit": "Tipo di circuito",
        "tidalvolume": "Vt (ml)",
        "safetytidalvolume": "Sicurezza Vt (ml)",
        "patienttype": "Tipo di paziente",
        "interface": "Interfaccia paziente",
        "volumebreathoptions": "Opzioni respirazione a volume controllato",
        "sigh": "Sospiro",
        "manualbreath": "Respiro manuale",
        "cyclesensitivity": "Ciclo (%)",
        "ti": "Ti (s)",
        "mask": "Maschera"
    },
    "s9": {
        "therapy_mode": "Modalità",
        "mode": "Modalità",
        "press": "Imposta pressione (cmH20)",
        "ramp_time": "Tempo di rampa (min)",
        "ramp_enable": "Abilitazione rampa",
        "eprtype": "EPR",
        "eprlevel": "Livello EPR (cmH20)",
        "startpress": "Pressione iniziale (cmH2O)",
        "minpress": "Pressione min (cmH2O)",
        "maxpress": "Pressione max (cmH2O)",
        "autosetresponse": "Risposta",
        "epap": "EPAP (cmH2O)",
        "ipap": "IPAP (cmH2O)",
        "ibrenable": "iBR",
        "resprate": "Frequenza respiratoria (respiri/min)",
        "startepap": "EPAP iniziale (cmH2O)",
        "minepap": "EPAP Min (cmH2O)",
        "maxepap": "EPAP Max (cmH2O)",
        "epapautoenable": "EPAP Auto",
        "maxipap": "IPAP Max (cmH2O)",
        "presssupport": "Aiuto respiratorio (cmH2O)",
        "minps": "AI Min (cmH2O)",
        "maxps": "AI Max (cmH2O)",
        "tgtpatientrate": "Frequenza paziente target (respiri/min)",
        "tgtalveolarvent": "Ventilazione alveolare target (l/min)",
        "triggersensitivity": "Trigger",
        "ramp_down_enable": "Abilitazione discesa della rampa",
        "timin": "Ti Min (s)",
        "timax": "Ti Max (s)",
        "heightcm": "Altezza del paziente (cm)",
        "risetime": "Tempo di salita (ms)",
        "circuit": "Tipo di circuito",
        "tidalvolume": "Vt (ml)",
        "safetytidalvolume": "Sicurezza Vt (ml)",
        "patienttype": "Tipo di paziente",
        "interface": "Interfaccia paziente",
        "volumebreathoptions": "Opzioni respirazione a volume controllato",
        "sigh": "Sospiro",
        "manualbreath": "Respiro manuale",
        "cyclesensitivity": "Ciclo (%)",
        "ti": "Ti (s)",
        "mask": "Maschera"
    },
    "air10": {
        "therapy_mode": "Modalità",
        "mode": "Modalità",
        "press": "Imposta pressione (cmH20)",
        "ramp_time": "Tempo di rampa (min)",
        "ramp_enable": "Abilitazione rampa",
        "eprtype": "EPR",
        "eprlevel": "Livello EPR (cmH20)",
        "startpress": "Pressione iniziale (cmH2O)",
        "minpress": "Pressione min (cmH2O)",
        "maxpress": "Pressione max (cmH2O)",
        "autosetresponse": "Risposta",
        "epap": "EPAP (cmH2O)",
        "ipap": "IPAP (cmH2O)",
        "ibrenable": "iBR",
        "resprate": "Frequenza respiratoria (respiri/min)",
        "startepap": "EPAP iniziale (cmH2O)",
        "minepap": "EPAP Min (cmH2O)",
        "maxepap": "EPAP Max (cmH2O)",
        "epapautoenable": "EPAP Auto",
        "maxipap": "IPAP Max (cmH2O)",
        "presssupport": "Aiuto respiratorio (cmH2O)",
        "minps": "AI Min (cmH2O)",
        "maxps": "AI Max (cmH2O)",
        "tgtpatientrate": "Frequenza paziente target (respiri/min)",
        "tgtalveolarvent": "Ventilazione alveolare target (l/min)",
        "triggersensitivity": "Trigger",
        "ramp_down_enable": "Abilitazione discesa della rampa",
        "timin": "Ti Min (s)",
        "timax": "Ti Max (s)",
        "heightcm": "Altezza del paziente (cm)",
        "risetime": "Tempo di salita (ms)",
        "circuit": "Tipo di circuito",
        "tidalvolume": "Vt (ml)",
        "safetytidalvolume": "Sicurezza Vt (ml)",
        "patienttype": "Tipo di paziente",
        "interface": "Interfaccia paziente",
        "volumebreathoptions": "Opzioni respirazione a volume controllato",
        "sigh": "Sospiro",
        "manualbreath": "Respiro manuale",
        "cyclesensitivity": "Ciclo (%)",
        "ti": "Ti (s)",
        "mask": "Maschera"
    },
    "lumis": {
        "therapy_mode": "Modalità",
        "mode": "Modalità",
        "press": "Imposta pressione (cmH20)",
        "ramp_time": "Tempo di rampa (min)",
        "ramp_enable": "Abilitazione rampa",
        "eprtype": "EPR",
        "eprlevel": "Livello EPR (cmH20)",
        "startpress": "Pressione iniziale (cmH2O)",
        "minpress": "Pressione min (cmH2O)",
        "maxpress": "Pressione max (cmH2O)",
        "autosetresponse": "Risposta",
        "epap": "EPAP (cmH2O)",
        "ipap": "IPAP (cmH2O)",
        "ibrenable": "iBR",
        "resprate": "Frequenza respiratoria (respiri/min)",
        "startepap": "EPAP iniziale (cmH2O)",
        "minepap": "EPAP Min (cmH2O)",
        "maxepap": "EPAP Max (cmH2O)",
        "epapautoenable": "EPAP Auto",
        "maxipap": "IPAP Max (cmH2O)",
        "presssupport": "Aiuto respiratorio (cmH2O)",
        "minps": "AI Min (cmH2O)",
        "maxps": "AI Max (cmH2O)",
        "tgtpatientrate": "Frequenza paziente target (respiri/min)",
        "tgtalveolarvent": "Ventilazione alveolare target (l/min)",
        "triggersensitivity": "Trigger",
        "ramp_down_enable": "Abilitazione discesa della rampa",
        "timin": "Ti Min (s)",
        "timax": "Ti Max (s)",
        "heightcm": "Altezza del paziente (cm)",
        "risetime": "Tempo di salita (ms)",
        "circuit": "Tipo di circuito",
        "tidalvolume": "Vt (ml)",
        "safetytidalvolume": "Sicurezza Vt (ml)",
        "patienttype": "Tipo di paziente",
        "interface": "Interfaccia paziente",
        "volumebreathoptions": "Opzioni respirazione a volume controllato",
        "sigh": "Sospiro",
        "manualbreath": "Respiro manuale",
        "cyclesensitivity": "Ciclo (%)",
        "ti": "Ti (s)",
        "mask": "Maschera"
    },
    "stellar": {
        "therapy_mode": "Modalità",
        "mode": "Modalità",
        "press": "Imposta pressione (cmH20)",
        "ramp_time": "Tempo di rampa (min)",
        "ramp_enable": "Abilitazione rampa",
        "eprtype": "EPR",
        "eprlevel": "Livello EPR (cmH20)",
        "startpress": "Pressione iniziale (cmH2O)",
        "minpress": "Pressione min (cmH2O)",
        "maxpress": "Pressione max (cmH2O)",
        "autosetresponse": "Risposta",
        "epap": "EPAP (cmH2O)",
        "ipap": "IPAP (cmH2O)",
        "ibrenable": "iBR",
        "resprate": "Frequenza respiratoria (respiri/min)",
        "startepap": "EPAP iniziale (cmH2O)",
        "minepap": "EPAP Min (cmH2O)",
        "maxepap": "EPAP Max (cmH2O)",
        "epapautoenable": "EPAP Auto",
        "maxipap": "IPAP Max (cmH2O)",
        "presssupport": "Aiuto respiratorio (cmH2O)",
        "minps": "AI Min (cmH2O)",
        "maxps": "AI Max (cmH2O)",
        "tgtpatientrate": "Frequenza paziente target (respiri/min)",
        "tgtalveolarvent": "Ventilazione alveolare target (l/min)",
        "triggersensitivity": "Trigger",
        "ramp_down_enable": "Abilitazione discesa della rampa",
        "timin": "Ti Min (s)",
        "timax": "Ti Max (s)",
        "heightcm": "Altezza del paziente (cm)",
        "risetime": "Tempo di salita (ms)",
        "circuit": "Tipo di circuito",
        "tidalvolume": "Vt (ml)",
        "safetytidalvolume": "Sicurezza Vt (ml)",
        "patienttype": "Tipo di paziente",
        "interface": "Interfaccia paziente",
        "volumebreathoptions": "Opzioni respirazione a volume controllato",
        "sigh": "Sospiro",
        "manualbreath": "Respiro manuale",
        "cyclesensitivity": "Ciclo (%)",
        "ti": "Ti (s)",
        "mask": "Maschera"
    },
    "astral": {
        "therapy_mode": "Modalità",
        "mode": "Modalità",
        "press": "Imposta pressione (cmH20)",
        "ramp_time": "Tempo di rampa (min)",
        "ramp_enable": "Abilitazione rampa",
        "eprtype": "EPR",
        "eprlevel": "Livello EPR (cmH20)",
        "startpress": "Pressione iniziale (cmH2O)",
        "minpress": "Pressione min (cmH2O)",
        "maxpress": "Pressione max (cmH2O)",
        "autosetresponse": "Risposta",
        "epap": "EPAP (cmH2O)",
        "ipap": "IPAP (cmH2O)",
        "ibrenable": "iBR",
        "resprate": "Frequenza respiratoria (respiri/min)",
        "startepap": "EPAP iniziale (cmH2O)",
        "minepap": "EPAP Min (cmH2O)",
        "maxepap": "EPAP Max (cmH2O)",
        "epapautoenable": "EPAP Auto",
        "maxipap": "IPAP Max (cmH2O)",
        "presssupport": "Aiuto respiratorio (cmH2O)",
        "minps": "AI Min (cmH2O)",
        "maxps": "AI Max (cmH2O)",
        "tgtpatientrate": "Frequenza paziente target (respiri/min)",
        "tgtalveolarvent": "Ventilazione alveolare target (l/min)",
        "triggersensitivity": "Trigger",
        "ramp_down_enable": "Abilitazione discesa della rampa",
        "timin": "Ti Min (s)",
        "timax": "Ti Max (s)",
        "heightcm": "Altezza del paziente (cm)",
        "risetime": "Tempo di salita (ms)",
        "circuit": "Tipo di circuito",
        "tidalvolume": "Vt (ml)",
        "safetytidalvolume": "Sicurezza Vt (ml)",
        "patienttype": "Tipo di paziente",
        "interface": "Interfaccia paziente",
        "volumebreathoptions": "Opzioni respirazione a volume controllato",
        "sigh": "Sospiro",
        "manualbreath": "Respiro manuale",
        "cyclesensitivity": "Ciclo (%)",
        "ti": "Ti (s)",
        "mask": "Maschera"
    },
    "airmini": {
        "therapy_mode": "Modalità",
        "mode": "Modalità",
        "press": "Imposta pressione (cmH20)",
        "ramp_time": "Tempo di rampa (min)",
        "ramp_enable": "Abilitazione rampa",
        "eprtype": "EPR",
        "eprlevel": "Livello EPR (cmH20)",
        "startpress": "Pressione iniziale (cmH2O)",
        "minpress": "Pressione min (cmH2O)",
        "maxpress": "Pressione max (cmH2O)",
        "autosetresponse": "Risposta",
        "epap": "EPAP (cmH2O)",
        "ipap": "IPAP (cmH2O)",
        "ibrenable": "iBR",
        "resprate": "Frequenza respiratoria (respiri/min)",
        "startepap": "EPAP iniziale (cmH2O)",
        "minepap": "EPAP Min (cmH2O)",
        "maxepap": "EPAP Max (cmH2O)",
        "epapautoenable": "EPAP Auto",
        "maxipap": "IPAP Max (cmH2O)",
        "presssupport": "Aiuto respiratorio (cmH2O)",
        "minps": "AI Min (cmH2O)",
        "maxps": "AI Max (cmH2O)",
        "tgtpatientrate": "Frequenza paziente target (respiri/min)",
        "tgtalveolarvent": "Ventilazione alveolare target (l/min)",
        "triggersensitivity": "Trigger",
        "ramp_down_enable": "Abilitazione discesa della rampa",
        "timin": "Ti Min (s)",
        "timax": "Ti Max (s)",
        "heightcm": "Altezza del paziente (cm)",
        "risetime": "Tempo di salita (ms)",
        "circuit": "Tipo di circuito",
        "tidalvolume": "Vt (ml)",
        "safetytidalvolume": "Sicurezza Vt (ml)",
        "patienttype": "Tipo di paziente",
        "interface": "Interfaccia paziente",
        "volumebreathoptions": "Opzioni respirazione a volume controllato",
        "sigh": "Sospiro",
        "manualbreath": "Respiro manuale",
        "cyclesensitivity": "Ciclo (%)",
        "ti": "Ti (s)",
        "mask": "Maschera"
    },
    "prisma": {
        "mode": "Modalità terapeutica",
        "date": "Data",
        "press": "Pressione",
        "softpap": "softPAP",
        "blocksoftpap": "Blocco softPAP",
        "autostartstop": "autoSTART-STOP",
        "softstartmax": "softSTART max",
        "softstartmin": "softSTART",
        "softstartpressure": "Pressione di softSTART",
        "humidifierlevel": "Livello di umidificazione",
        "circuit": "Tipo di tubo flessibile",
        "tubetypelock": "Blocco tipo di tubo flessibile",
        "maxpress": "Limite di pressione superiore P max",
        "minpress": "Limite di pressione inferiore P min",
        "apapcontrol": "Comando APAP",
        "apap": "APAP",
        "softstart": "softSTART"
    }
}