import React from 'react';

interface IconProps extends React.HtmlHTMLAttributes<HTMLButtonElement> {
    icon: string
    size?: string
    tintColor?: string
}

export const IconSVG = ({
                            icon,
                            size = '32px',
                            tintColor,
                            ...rest
                        }: IconProps) => {
    const image = icon ? `${process.env.REACT_APP_LOAD_CONFIG}assets/icons/svg/${icon}.svg` : ''

    return (
        <span {...rest}>
      {tintColor ? (
          <div
              className="icon icon-svg-mask"
              style={{
                  maskImage: `url(${image})`,
                  WebkitMaskImage: `url(${image})`,
                  backgroundColor: tintColor,
                  width: size,
                  height: size,
              }}
          ></div>
      ) : (
          <img className="icon" src={image} width={size} height={size}/>
      )}
    </span>
    )
}
