import React, {ReactNode} from 'react'
import {mergeClass} from '../../../../../shared/functions/function'
import {IconSVG} from "../../../../../shared/custom/icon/IconSVG";
import configuration from "../../../../../shared/configuration";

export const Sidebar = ({
                            isVisible,
                            hideSidebarHandler,
                            children,
                        }: {
    isVisible: boolean
    hideSidebarHandler: () => void
    children: ReactNode
}) => (
    <div className={mergeClass('sidebar', isVisible ? 'in' : '')}>
        <div className="sidebar-header pointer" onClick={hideSidebarHandler}>
            <IconSVG icon={'close'} size={'20px'} tintColor={configuration.theme.primary}/>
        </div>

        <div className="sidebar-body">{children}</div>
    </div>
)
