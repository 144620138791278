import React, {Fragment} from 'react';
import TitleMenuVoice from "./TitleMenuVoice";
import ItemMenuVoice from "./ItemMenuVoice";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";
import {sectionModel, voicesModel} from "../../models/sectionsModel";

interface MenuSidebarProps {
    currentPage: string
    sections: sectionModel[]
    goToPageHandler: (event: React.MouseEventHandler<HTMLDivElement>, voice: voicesModel) => void
}

const MenuSidebar: React.FC<MenuSidebarProps> = ({currentPage, sections, goToPageHandler}) => {
    const {t} = useLanguageForShared()

    return (
        <div>
            {sections.map((section, key) => (
                <Fragment key={key}>
                    <TitleMenuVoice title={t(`sidebarMenu.nav.voices.${section.name}`)}/>
                    {section.voices.map((voice, key) => (
                        <ItemMenuVoice key={key} voice={voice} clickItemHandler={goToPageHandler}
                                       activeItem={voice.name === currentPage}/>
                    ))}

                </Fragment>
            ))}
        </div>
    );
};

export default MenuSidebar;
