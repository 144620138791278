import {generalIT} from "../../../../config/lang/general_it";
import {devicesIT} from "./devices/devices_it";

export default {
    ...generalIT,
    ...devicesIT,
    header: {
        nav: {
            voices: {
                notifications: 'Notifiche',
                notification_list: 'Elenco Notifiche ',
                appointments: 'Appuntamenti',
                appointments_calendar: 'Calendario appuntamenti',
                patients: 'Pazienti',
                patients_list: 'Elenco pazienti',
                patients_respiratory_compliance: 'Compliance respiratoria',
                devices: 'Dispositivi',
                devices_list: 'Lista dispositivi',
                services: 'Prestazioni',
                users: 'Utenti',
                organizations: 'Organizzazioni',
                manage_users: 'Gestione utenti',
                manage_organizations: 'Gestione organizzazioni',
                help: 'Help',
                manual: 'Manuale',
                info: 'Informazioni su',
                support: 'Supporto',
                billing: 'Fatturazione',
                costs: 'Costi',
                invoices: 'Fatture'
            }
        },
        sidebar: {
            userProfile: 'Profilo utente',
            organization: 'Organizzazione',
            language: 'Lingua',
        }
    },
    sidebarMenu: {
        nav: {
            voices: {
                patient: 'Paziente',
                personal_data: 'Anagrafica',
                patologies_treatments: 'Patologie e Trattamenti',
                devices: 'Dispositivi',
                services: 'Servizi',
                treatments: 'Trattamenti',
                summary: 'Riepilogo',
                trend_over_time: 'Trend nel tempo',
                wave_forms_over_time: 'Forme onda nel tempo',
                prescription_Log: 'Log Prescrizioni',
                biomedical_parameters: 'Parametri biomedici',
                logbook_spo2: 'Logbook SpO2/BPM',
                report_spo2: 'Report SpO2/BPM',
                logbook_glycemia: 'Logbook glicemia',
                logbook_weight: 'Logbook peso',
                logbook_temperature: 'Logbook temperatura',
                logbook_pressure: 'Logbook pressione',
                questionnaires: 'Questionari',
                test_sleep: 'Test del sonno',
                test_init_trerapy: 'Test di inizio terapia',
                test_trend_terapy: 'Test di andamento della terapia',
                surveillance: 'Sorveglianza',
                monitoring_plan: 'Piani di monitoraggio',
                pharmacological_treatment: 'Trattamento farmacologico',
                patientGraph: 'Sintesi dello stato e prestazioni in corso',
                patientRegistry: 'Anagrafica',
                patientPrescriptions: 'Trattamenti',
                patientProvisions: 'Prestazioni svolte e pianificate',
                patientHistory: 'Storico stati',
                patientRequestPrescription: 'Richiesta prescrizione',
                patientDevices: 'Dispositivi',
                patientAccessories: 'Accessori',
                patientConsumable: 'Consumabili',
                patientLoxGraph: 'Ossigeno prescritto/consegnato',
                patientMedicinals: 'Farmaci',
                prescriptions: 'Prescrizione',
                patientInvoices: 'Importi fatturati',
                provision: 'Prestazioni',
                deviceCard: 'Scheda Dispositivo',
                deviceLastPatientAssigned: 'Ultimo Paziente Assegnatario',
                deviceLogBook: 'Libro Macchina',
                deviceAccessories: 'Accessori',
                provisionSectionMain: 'Informazioni Principali',
                provisionPatientInfo: 'Anagrafica Paziente',
                provisionDevices: 'Dispositivi',
                provisionAccessories: 'Accessori',
                provisionConsumables: 'Consumabili',
                provisionInfoDetail: 'Dettaglio Prestazione',
                patientCosts: 'Costi Paziente',
            }
        }
    },
    languages: {
        it: 'IT - Italiano',
        en: 'EN - Inglese',
        ro: 'RO - Romeno',
    },
    treatments: {
        'Sleep': 'Sonno',
        'Sleep_Bilevel': 'Sonno Bilevel',
        'Sleep_CPAP_APAP': 'Sonno CPAP/APAP',
        'NIV': 'Vent',
        'Other': 'Altro',
    },
    pathology: {
        'COPD': 'BPCO',
        'OSAS': 'OSAS',
        'COPDSAS': 'BPCO-OSAS',
        'OHS': 'OHS',
        'CSA': 'CSAS',
        'RestrictivePathology': 'Patologia restrittiva',
        'NeuroMuscularDisease': 'Malattia neuro-muscolare',
        'Other': 'Altro',
        'Obesity': 'Obesità',
        'Asthma': 'Asma',
        'RespiratoryFailure': 'Insufficienza respiratoria',
        'HeartFailure': 'Insufficienza cardiaca',
        'KidneyFailure': 'Insufficienza renale',
        'Hyperthyroidism': 'Ipertiroidismo',
        'Hypothyroidism': 'Ipotiroidismo',
        'IschemicHeartDisease': 'Cardiopatia ischemica',
        'Cerebrovascular': 'M. Cerebrovascolare',
        'Diabetes': 'Diabete II',
        'RLS_PLM': 'RLS/PLM',
        'Insomnia': 'Insonnia',
        'Anxiety': 'Ansia',
        'Parkinson': 'Parkinson',
    },
    msg: {
        requiredField: '(*) Campi Obbligatori',
        emptyData: {
            title: "Ci dipiace, nessun report disponibile.",
            subTitle: "Al momento non è presente nessun report.",
        },
        success: {
            requestSend: 'Richiesta modifica prescrizione inviata con successo'
        }
    },
    errors: {
        be: {},
        fe: {
            required: 'Il campo {{name}} è obbligatorio',
            requiredOne: 'Compilare il campo o aggiungere {{name}}',
        },
        errorsMsg: {
            noPermission: 'Non sei autorizzato per questa operazione',
            token: 'Token scaduto o errato',
            genericSave: 'Errore interno, riprova più tardi',
            pdfDownload: 'Errore nel generare il pdf, riprova più tardi.'
        },
    },
    success: {
        successMsg: {},
    },

    prescription: {
        on: 'SI',
        off: 'NO',
        settingsDevice: "Impostazioni dispositivi",
        settingFrom: 'Impostazioni del',
        showOtherSettings: 'Mostra impostazioni precedenti',
        hideOtherSettings: 'Nascondi impostazioni precedenti',
        mode: 'Modalità',
        dataAssociateFrom: "Dati associati dal",
        associateDate: "Data associazione",
        state: "Stato",
        telemetry: "Telemetria",
        from: "dal",
        settingsFrom: "Impostazioni dal",
        lastSettingFrom: 'Ultima modifica il',
        of: 'di',
        noSettings: 'Nessuna impostazione disponibile',
        stateType: {
            active: 'Attivo',
            inactive: 'Disattivato',
            enabled: 'Abilitato',
            disabled: 'Disabilitato'
        },
        editTreatment: "Modifica trattamento",
        introduction: "Questa procedura guidata consente di modificare i parametri del trattamento associato e richiedere una variazione del trattamento per il dispositivo attuale.",
        stepOne: "Step 1 di 2",
        stepTwo: "Step 2 di 2",
        step: "Step",

        summary: "Riepilogo",
        idRequest: "ID Richiesta",
        requestDate: "Data richiesta",
        updateButton: "Modifica trattamento",
        template: {
            mode: {
                label: "Modalità",
            },
            device: {
                label: "Modello dispositivo",
                placeholder: "Inserisci modello dispositivo",
            },
            modelCannula: {
                label: "Modello cannula tracheostomica",
                placeholder: "Inserisci modello cannula tracheostomica",
            },
            cannulaNasal: {
                label: "Cannula Nasale",
                placeholder: "Inserisci modello cannula nasale",
            },
            cannulaTracheostomy: {
                label: "Cannula tracheostomica eventuale",
                placeholder: "Inserisci modello cannula tracheostomica",
            },
            cannulaType: {
                label: 'Tipo',
                placeholder: 'Inserisci tipo'
            },
            cannulaModel: {
                label: 'Modello',
                placeholder: 'Inserisci modello'
            },
            guaranteedVolume: {
                label: 'Volume garantito eventuale',
                placeholder: 'es. 12'
            },
            modelDevice: {
                label: 'Modello ventilatore',
                placeholder: 'Inserisci modello ventilatore'
            },
            regulation: {
                label: "Regolazione alle dimissioni",
                placeholder: "Inserisci regolazione alle dimissioni",
            },
            pressure: {
                label: "Pressione (cmH2O)",
                placeholder: "es. CPAP",
            },
            ipap_max: {
                label: "IPAP max",
                placeholder: "es. 10",
            },
            ipap_min: {
                label: "IPAP min",
                placeholder: "es. 4",
            },
            epap_max: {
                label: "EPAP max",
                placeholder: "es. 10",
            },
            epap_min: {
                label: "EPAP min",
                placeholder: "es. 4",
            },
            press_min: {
                label: "Press. min (cmH2O)",
                placeholder: "es. 4",
            },
            press_max: {
                label: "Press. max (cmH2O)",
                placeholder: "es. 10",
            },
            epap: {
                label: "EPAP",
                placeholder: "es. 4",
            },
            ipap: {
                label: "IPAP",
                placeholder: "es. 4",
            },

            freq_resp: {
                label: "Freq. resp",
                placeholder: "es. 4",
            },
            freq_backup: {
                label: "Freq. backup",
                placeholder: "es. 4",
            },

            ps_min: {
                label: "PS min",
                placeholder: "es. 4",
            },
            ps_max: {
                label: "PS max",
                placeholder: "es. 4",
            },
            notesHumidifier: {
                label: "Livello di umidificazione",
                placeholder: "Inserire livello di umidificazione"
            },
            cpap: 'CPAP',
            autost: 'AUTO S/T',
            apap: 'APAP',
            st: 'ST',
            t: 'T',
            apvc: 'APVC',
            s: 'S',
            autocpap: 'AUTOCPAP',
            bilevels: 'BILEVEL S',
            bilevelauto: 'BILEVEL AUTO',
            rampa: {
                label: "Rampa",
            },
            initialPress: {
                label: "Press. iniziale (cmH2O)",
                placeholder: "es. 4",
            },
            duration: {
                label: "Durata (min)",
                placeholder: "es. 10",
            },
            interface: {
                label: "Interfaccia",
            },
            interfaceType: {
                label: "Tipo interfaccia",
                placeholder:
                    "Inserisci tipo interfaccia",
            },
            interfaceModel: {
                label: "Modello",
                placeholder: "Inserisci modello maschera",
            },
            interfaceSize: {
                label: "Misura",
                placeholder: "es. M",
            },
            mask: {
                label: "Maschera",
            },
            check24h: {
                label: "Controllo <= 24h su totale ore",
            },
            maskType: {
                label: 'Tipo',
                placeholder: 'Inserisci tipo'
            },
            maskModel: {
                label: 'Modello',
                placeholder: 'Inserisci Modello'
            },
            humidifier: {
                label: "Umidificatore",
            },
            humidifierLabel: "Umidificatore",
            note: {
                label: "Note",
                placeholder: "Inserisci note",
            },
            flowRest: "A riposo | flusso (l/min)",
            flowRest_placeholder: "es. 2",
            hourDie: "ore/die",
            hourDie_placeholder: "es. 2",
            underStressFlow: "Sotto sforzo | flusso (l/min)",
            underStressFlow_placeholder: "es. 2",
            nightFlow: "Notturno | flusso (l/min)",
            nightFlow_placeholder: "es. 2",
            validUntil: "Prescrizione valida fino al",
        }
    },
    common: {
        pathology: 'Patologia',
        pathologies: 'Patologie',
        treatment: 'Trattamento',
        device: 'Dispositivo',
        column: 'Colonne',
        otherPathologies: 'Altre patologie',
        otherPathologies_placeholder: 'es. patologia',

        yes: 'SI',
        no: 'NO',
    },

}
