import React from 'react';
import ActionTopBar from "../../../../shared/custom/actionBar/ActionTopBar";
import {TitleHeadingH3} from "../../../../shared/custom/headings/TitleHeadingH3";
import BtnList from "../../../../shared/custom/actionBar/BtnList";
import HistoricalPlan from "./HistoricalPlan";
import InfoTreatment from "../components/InfoTreatment";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";
import {PatientDetailModel} from "../../../../model/PatientDetails";

const TherapeuticPlan = ({data}: { data: PatientDetailModel }) => {
    const {t} = useLanguageForShared()
    const actionPage = [
        {
            label: 'Generate report',
            icon: 'report-gen',
            actionHandler: () => console.log('generate report')
        },
        {
            label: 'Edit plan',
            icon: 'edit-plan',
            actionHandler: () => console.log('edit plan')
        }
    ]

    return (
        <div>

            <ActionTopBar leftTemplate={<TitleHeadingH3 title={t('hlv.telerehabilitation_treatment')}/>}
                          rightTemplate={<BtnList listBtn={actionPage}/>}
            />
            <div className={'row mt-3 gap-5'}>

                <div className={"col-12"}>
                    <TitleHeadingH3 title={t('hlv.therapeutic_plan')}/>
                    <InfoTreatment data={data}/>
                </div>

                <HistoricalPlan/>

            </div>
        </div>
    );
};

export default TherapeuticPlan;
