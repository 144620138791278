import React, {useEffect, useState} from "react";
import {setLanguageAction} from "../redux/generalSettingsSlice";
import {setI18n} from "../utils/languages/config/i18n";
import {useDispatch} from "react-redux";

const LanguageLoad: React.FC<{language: string, ignoreMoment?: boolean}> = ({children, ignoreMoment = false, language}) => {
    const [initLanguage, setInitLanguage] = useState<boolean>(false)
    const dispatch = useDispatch()
    useEffect(() => {
        setI18n(language, () => setInitLanguage(true));
    }, [])
    useEffect(() => {
        initLanguage && dispatch(setLanguageAction(language, ignoreMoment))
        //uncomment to test the language
        /*  setTimeout(() => {
              dispatch(setLanguageAction('en'))
          }, 5000)*/
    }, [language, initLanguage])
    return (
        <>
            {initLanguage && children}
        </>
    )
}
export default LanguageLoad
