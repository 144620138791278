import jsPDF from "jspdf";

export const exportPdf = (fileName, ref, callback) => {

    const doc = new jsPDF(
        {
            format: "a4",
            unit: "px",
            precision: 100,
        }
    );
    console.log('refcurrent', ref.current)
    console.log('doc', doc)
    doc.html(ref.current, {
        margin: [16, 12],
        html2canvas: {
            scale: 0.35,
            windowWidth: 1200
        },
        windowWidth: 1200,
        width: 1200,

        async callback(doc) {
            doc.save(`${fileName}.pdf`);
        }
    }).then(res => {
        callback && callback()
    });

};
