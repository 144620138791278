import React from 'react';
import LabelValue from "../../../../shared/custom/labelValue/LabelValue";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";
import {PatientDetailModel, PatientDetailOxyviewModel} from "../../../../model/PatientDetails";
import {useDateCustom} from "../../../../shared/custom_use/useDateCustom";

const RowPatient = ({data}: { data: any }) => {
    const {t} = useLanguageForShared()
    const {data: {dateFormatGGmmmYYYY}} = useDateCustom()
    const gender = data?.gender === 'male' ? t('common.male') : data.gender === 'female' ? t('common.female') : t('common.notDefined');

    return (
        <div className={"d-flex flex-wrap gap-5 row-gap-1 flex-wrap"}>
            <div style={{minWidth: '120px', maxWidth: '290px'}}>
                <LabelValue label={t('hlv.firstName')} value={data?.name} labelClass={'sm'}
                            valueClass={'bold'}/>
            </div>
            <div style={{minWidth: '120px', maxWidth: '288px'}}>
                <LabelValue label={t('hlv.lastName')} value={data?.surname} labelClass={'sm'}
                            valueClass={'bold'}/>
            </div>
            <div style={{minWidth: '120px', maxWidth: '288px'}}>
                <LabelValue label={t('hlv.birthDate')}
                            value={data?.birthDate ? dateFormatGGmmmYYYY(data?.birthDate) : '-'} labelClass={'sm'}
                            valueClass={'bold'}/>
            </div>
            <div style={{minWidth: '120px', maxWidth: '288px'}}>
                <LabelValue label={t('hlv.gender')} value={gender} labelClass={'sm'}
                            valueClass={'bold'}/>
            </div>
            <div style={{minWidth: '120px', maxWidth: '288px'}}>
                <LabelValue label={t('hlv.fiscalcode')} value={'xx00112233445'} labelClass={'sm'}
                            valueClass={'bold'}/>
            </div>
            <div style={{minWidth: '120px', maxWidth: '288px'}}>
                <LabelValue label={t('hlv.device_associated')} value={'HealtStation23 | SN 01010101'}
                            labelClass={'sm'} valueClass={'bold'}/>
            </div>
        </div>

    );
};

export default RowPatient;
