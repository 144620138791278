import React from 'react';
import {ValuesModel} from "../../../model/PatientDetails";

const Spo2Cursor = ({min, avg}: { min?: ValuesModel, avg?: ValuesModel }) => {

    const minVal = min.value
    const avgVal = avg.value

    const calcPosition = (100 * ((avgVal || 0) - (minVal || 0))) / (100 - (minVal || 0))

    return (
        <div className={`spo2-cursor`}>
            <div className={'cursor-bar'} style={{left: `${calcPosition}%`}}></div>
            <div className={'bar'}></div>
            <div className={'labels'}>
                <span className={'min'}>{minVal}</span>
                {minVal !== avgVal && <span className={'avg'} style={{left: `${calcPosition}%`}}>{avgVal}</span>}
            </div>
        </div>
    );
};

export default Spo2Cursor;
