import configuration from "../../../shared/configuration";

export const scoreStyle: any = {
    1: {
        color: configuration.theme.grayDark,
        bg: configuration.theme.gray100,
    },
    2: {
        color: configuration.theme.success_color,
        bg: configuration.theme.success,
    },
    3: {
        color: configuration.theme.alert_color,
        bg: configuration.theme.alert,
    },
    4: {
        color: configuration.theme.danger_color,
        bg: configuration.theme.danger,
    }
}


export const telemetryStyle: any = () => ({
    0: {
        bg: configuration.theme.gray,
    },
    1: {
        bg: configuration.theme.series.success100,
    },
    2: {
        bg: configuration.theme.series.success150,
    },
    3: {
        bg: configuration.theme.series.alert170,
    },
    4: {
        bg: configuration.theme.series.danger170,
    }
})

export const telemetryStyleRehab: any = () => ({
    1: {
        bg: configuration.theme.sgreen,
    },
    2: {
        bg: configuration.theme.slightgreen,
    },
    3: {
        bg: configuration.theme.sorange,
    },
    4: {
        bg: configuration.theme.sred,
    },
    5: {
        bg: configuration.theme.gray,
    },
    6: {
        bg: configuration.theme.syellow,
    }
})