import React from 'react'
import {FormElements} from "../../../../shared/bootstrap/form/FormElements";
import {useForm} from "../../../../shared/utils/forms/useForm";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";
import {useEffectOnlyUpdate} from "../../../../shared/utils/use_custom/useEffectCustom";

interface ChangeLanguageProps {
    languages: string[]
    changeLanguageHandler?: (id: any) => void
}

export const ChangeLanguage: React.FC<ChangeLanguageProps> = ({languages, changeLanguageHandler}) => {

    const submitHandler = () => {

    }
    const {t, lang} = useLanguageForShared()
    const {dataForm, setData, errors} = useForm(submitHandler, undefined, {lang})
    useEffectOnlyUpdate(() => {
        setData({lang})
    }, [lang])
    const formStructure = [
        {
            name: 'lang',
            type: 'select',
            options: languages.map(ele => ({
                label: t(`languages.${ele}`),
                value: ele
            })),
            label: t('header.sidebar.language'),
            dataElement: {
                placeholder: '',
                type: 'text',
            },
            col: {xs: "12"},
        }
    ]
    const changeValueHandler = (data: any, val: any) => {
        changeLanguageHandler && changeLanguageHandler({val: data.value})
    }
    return (
        <FormElements
            structure={formStructure}
            dataForm={dataForm}
            errors={errors}
            changeValue={changeValueHandler}
        />
    )
}
