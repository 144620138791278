import React from 'react';
import {useHl7Translate} from "../../../../shared/hl7/useHl7Translate";
import {valueSetHl7Name} from "../../../../shared/hl7/valueSet.hl7";


const PathologyItem = ({pathology}: { pathology: { name: string } }) => {
    const {value} = useHl7Translate(pathology?.name, valueSetHl7Name.condition)
    return (
        <div className={"item-treatment"}>
            {value || '-'}
        </div>
    );
};

export default PathologyItem;
