import React from 'react';
import {ChartWithZoom} from "../../../../shared/chartcustom/ChartWithZoom";
import configuration from "../../../../shared/configuration";
import {PatientDetailModel} from "../../../../model/PatientDetails";
import {getArrayByNumber} from "../../../../shared/utils/arrayUtils";
import Legend from "../../../../shared/chartcustom/legend/Legend";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";

const ChartDataLine = ({patient}: {patient: PatientDetailModel}) => {
    const {t} = useLanguageForShared()

    const sessions = patient?.sessions || [];
    const data = [
        ["Date", t("hlv.spo2"), ...getArrayByNumber(sessions.length).map(ele => t("hlv.sessions_status"))],
        ...sessions.map(ses => [new Date(ses.effectivePeriod.start), ses.block2Spo2Avg.value, ...getArrayByNumber(sessions.length).map(ele => null)]),
        ...sessions.map((ses, index) => [new Date(ses.effectivePeriod.start), null, ...Array(index * 1).fill(null), {v: 85, f: ses.sessionCompletion === 'completed'?  t("hlv.completed"): t("hlv.interrupted")}, ...Array(((sessions.length - 1) * 1) - (index * 1)).fill(null)])
    ];

    const formatters = [
        {
            type: "NumberFormat",
            column: 1,
            options: {
                suffix: ' %',
                decimalSymbol: ',',
                groupingSymbol: '.',
                prefix: ``
            }
        }
    ]

/*    const {calcTicksDay} = useTicksDate()
    console.log(calcTicksDay(sessions))
    */
    return (
        <>
            <Legend list={[
                {label: t("hlv.spo2"), color: configuration.theme.series.s100_hex || 'blue'},
                {label: t("hlv.completed"), color: configuration.theme.series.success100_hex || 'green'},
                {label: t("hlv.interrupted"), color: configuration.theme.series.alert100_hex || 'yellow'},
                {label: t("hlv.notPerformed"), color: configuration.theme.series.inactive || 'gray'},
            ]}/>
        <ChartWithZoom dataTable={data} chartType="LineChart" height={"160px"} formatters={formatters}
                       options={{
                           pointSize: 8,
                           lineWidth: 1,

                           hAxis: {
                               viewWindowMode: "pretty",
                               slantedText: true,
                               baselineColor: "#5A5A5A",
                               minorGridlines: {
                                   color: "#F2F2F2"
                               },
                               gridlines: {
                                   color: "#F2F2F2"
                               },
                               format: 'YY MMM dd',
                           },
                           vAxis: {
                               scaleType: 'log',

                               minorGridlines: {
                                   color: "#F2F2F2"
                               },
                               gridlines: {
                                   color: "#F2F2F2"
                               },
                               baselineColor: "#5A5A5A"

                           },
                           vAxes: {
                               0: {
                                   //title: 'label',
                                   titleTextStyle: {
                                       italic: false,
                                       fontSize: 13,
                                       color: "#5A5A5A"
                                   },
                               },
                               1: {
                                   //title: 'title',
                                       titleTextStyle: {
                                       italic: false,
                                       fontSize: 13,
                                       color: "#5A5A5A"
                                   },
                               }
                           },
                           colors: [configuration.theme.series.s100_hex || 'blue'],
                           series: {
                               0: { color: configuration.theme.series.s100_hex || 'blue' },
                               ...getArrayByNumber(sessions.length).reduce((acc, ele) =>{
                                   return {...acc, [ele+1]: {color: sessions[ele].sessionCompletion === 'completed' ? configuration.theme.series.success100_hex || 'green' : configuration.theme.series.alert100_hex || 'yellow'}}
                               }, {})
                           },
                           chartArea: {top: 20, left: 60, right: 60, bottom: 60},
                           legend: {position: 'none'},
                       }}/>
        </>
    );
};

export default ChartDataLine;
