import React from "react";
import userManager from "./oidcUserManager";
import {AuthProvider} from "oidc-react";
import App from "./App";

function Init() {
    const onSignIn = (user) => {
        // console.log('sign in', user)
        // debugger;
        // window.location.href = '/'
    }
    return (
        <AuthProvider onSignIn={onSignIn} {...userManager}>
            <App />
        </AuthProvider>
    );
}

export default Init;

