import React, {useState} from "react";
import {Pagination as PaginationModel} from "../../../models/TableProps";
import {usePagination} from "./usePagination";
import {useEffectOnlyUpdate} from "../../../../utils/use_custom/useEffectCustom";

const Pagination = ({
                        elementForPage = 8,
                        elements,
                        changeExternalPage,
                        callbackChangePage
                    }: PaginationModel) => {
    const [page, setPage] = useState<number>(1)
    useEffectOnlyUpdate(() => {
        changeExternalPage && changeExternalPage(setPage)
    }, [changeExternalPage])
    const changePageAction = (pageNext: number) => {
        const callback = () => {
            setPage(pageNext)
        }
        callbackChangePage && callbackChangePage({
            page: pageNext,
            elementForPage,
            offset: (pageNext - 1) * elementForPage
        }, callback)
    }

    const {items, pages, changePage} = usePagination(
        elements,
        elementForPage,
        page,
        changePageAction
    );
    return (
        <div className="d-flex justify-content-end">
            {/*         {items.map(number => <div style={{background: number === page ? 'yellow' : 'none'}}
                                      onClick={() => changePageAction(number)}
                                      data-test={'page-number-' + number}>{number}</div>)}*/}

            <div className={'pagination'}>
                <div className={`page-item firstPage ${page === 1 ? 'disabled' : ''}`}
                     onClick={() => page > 1 && changePageAction(1)}>
                    <div className="page-link">{`<<`}</div>
                </div>
                <div className={`page-item prevPage ${page === 1 ? 'disabled' : ''}`}
                     onClick={() => page > 1 && changePage()}>
                    <div className="page-link">{`<`}</div>
                </div>
                {page > 3 ? <div className={`page-item`}>
                    <div className="page-link">
                        ...
                    </div>
                </div> : null}

                {items.map((number) => (
                    (number <= page && page - number < 3) || (number > page && number - page < 3) ? <div
                        className={`page-item ${number === page ? 'active' : ''}`}

                        key={number}
                        onClick={() => changePageAction(number)}
                        data-test={'page-number-' + number}
                    >
                        <div className="page-link">
                            {number}
                        </div>
                    </div> : null
                ))}

                {(items.length - page) >= 3 ?
                    <>
                        <div className={`page-item`}>
                            <div className="page-link">...</div>
                        </div>

                        <div
                            className={`page-item ${items[items.length - 1] === page ? 'active' : ''}`}

                            key={items[items.length - 1]}
                            onClick={() => changePageAction(items[items.length - 1])}
                            data-test={'page-number-' + items[items.length - 1]}
                        >
                            <div className="page-link">
                                {items[items.length - 1]}
                            </div>
                        </div>
                    </>
                    : null}
                <div className={`page-item nextPage ${page === pages ? 'disabled' : ''}`}
                     onClick={() => page < pages && changePage(true)}>
                    <div className="page-link">{`>`}</div>
                </div>
                <div className={`page-item lastPage ${page === pages ? 'disabled' : ''}`}
                     onClick={() => page < pages && changePageAction(items.length)}>
                    <div className="page-link">{`>>`}</div>
                </div>
            </div>
        </div>
    );
};

export default Pagination;
