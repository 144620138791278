import React from "react";
import {useDateCustom} from "../../../../shared/custom_use/useDateCustom";

const PatientBodyTemplate: React.FC<any> = ({patient}) => {
    const {data: {dateFormatGGmmmYYYY}} = useDateCustom()
    const state = patient?.alarm ? 'alarm' : patient?.alert ? 'alert' : ''
    const calcAge = (d: any) => {
        const dob = new Date(d);
        //calculate month difference from current date in time
        const monthDiff = Date.now() - dob.getTime();

        //convert the calculated difference in date format
        const ageDt = new Date(monthDiff);

        //extract year from date
        const year = ageDt.getUTCFullYear();

        //now calculate the age of the user
        return Math.abs(year - 1970);
    }
    return  <div className={`col-item col-patient ${state ? 'notify-' + state : ''}`}>
                <span>{patient.surname.toLocaleUpperCase()} {patient.name}</span>
                <small>{dateFormatGGmmmYYYY(patient.birthDate)}, {calcAge(patient.birthDate)} anni</small>
    </div>
}
export default PatientBodyTemplate
