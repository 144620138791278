import React from "react";
import {funcTemplate, TableData, TemplateTable} from "../../../models/TableProps";

const TableHeader = ({columns, templates = {}}: { columns: TableData[], templates?: TemplateTable; }) => {
    return (
        <thead>
        <tr>
            {columns.map((ele) => {
                const template = templates && templates[ele?.name]
                const element = template ? template(ele.label, ele) : (ele.label);
                return <th data-test={'table-col-' + ele.name} key={ele.name}>{element}</th>;
            })}
        </tr>
        </thead>
    );
};
export default TableHeader;
