import React from "react";
import TreatmentCol from "../../components/treatment/TreatmentCol";

const TreatmentsBodyTemplate: React.FC<any> = ({patient}) => {
    return   <>
        {patient?.carePlanes?.length > 0 && <TreatmentCol
            compliance={patient.compliance.data}
            complianceType={patient.compliance.type}
//            treatmentStatus={patient.status}
            treatments={patient.treatments.map((ele: any) => ({
                name: ele.name, date: ele?.period?.start, status: ele?.status
            }))}/>}
    </>
}
export default TreatmentsBodyTemplate
