import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {AppThunk, RootState} from "../shared/redux/store";
import {OrganizationModelHl7} from "../libraries/header/models/Organization.model";
import {getOrganizations} from "../services/organization.service";


const initialState: any = {
  organizations: [],
  currentOrganization: undefined
}

export const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    setCurrentOrganization: (state, action: PayloadAction<OrganizationModelHl7>) => {
      state.currentOrganization = action.payload
    },
    setOrganizations: (state, action: PayloadAction<OrganizationModelHl7[]>) => {
      state.organizations = action.payload
    },
    resetOrganizations: (state) => {
      state.organizations = []
    }
  },
})

export const {
    setOrganizations,
    resetOrganizations,
    setCurrentOrganization
} = organizationSlice.actions


export const getOrganizationsAction = (): AppThunk => (
  dispatch
) => {
  getOrganizations().then(res => {
    const organizations = res.entry.map(ele => ({name: ele.resource.name, id: ele.resource.id}))
    dispatch(setOrganizations(organizations))
    if(organizations.length){
      setCurrentOrganization(organizations[0])
    }
  })
}

export const selectOrganizations = (state: RootState) => state.organization.organizations
export const selectCurrentOrganization = (state: RootState) => state.organization.currentOrganization

export default organizationSlice.reducer
