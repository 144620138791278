import React from 'react';
import configuration from "../../../../shared/configuration";
import Tag from "../../../../shared/custom/tag/Tag";
import {scoreStyle} from "../colorData";

const ScoreItem = ({score}: { score: string }) => {


    return (
        <div>
            <Tag label={score} bgColor={scoreStyle[score]?.bg}
                 color={scoreStyle[score]?.color}/>
        </div>
    );
};

export default ScoreItem;
