export const detailMock = [
    {
        name: 'patient',
        voices: [
            {
                name: 'patient_info',
                urlPage: '/#patientInfo',
            },
            {
                name: 'pathologies_treatment',
                urlPage: '/#patologiesTreatment',
            },
            {
                name: 'device_associated',
                urlPage: '/#deviceAssociated',
            }
        ]
    },
    {
        name: 'telerehabilitation_treatment',
        voices: [
            {
                name: 'summary',
                urlPage: '/#summary',
            },
            {
                name: 'therapeutic_plan',
                urlPage: '/#therapeuticPlan',
            },
        ]
    }]
