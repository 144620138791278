import React from 'react';
import {ChartWithZoom} from "../../../../shared/chartcustom/ChartWithZoom";
import configuration from "../../../../shared/configuration";
import Legend from "../../../../shared/chartcustom/legend/Legend";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";
import {getArrayByNumber} from "../../../../shared/utils/arrayUtils";

const ChartData = ({setImg, patientBis}: {patientBis: any,setImg?: (data: string) => void}) => {
    const {t} = useLanguageForShared()

    const session = patientBis.session;
    const block1Spo2 = session.block1Spo2.data.split(' ').map((ele: any) => +ele)
    const block2Spo2 = session.block2Spo2.data.split(' ').map((ele: any) => +ele)
    const block1 = session.block1Bpm;
    const block2 = session.block2Bpm;
    const block1Data = block1.data.split(' ').reduce((acc: any, ele: any, index: number) => {
        return [...acc, [acc.length ? acc[acc.length -1][0]+block1.period : 0, +ele, block1Spo2[index], null, null]]
    }, [])
    const lastTimeBlock1 = block1.data.split(' ').reduce((acc: number, ele: number) => {
        return acc + block1.period
    }, block1.period)
    const block2Data = block2.data.split(' ').reduce((acc: any, ele: any, index: number) => {
        return [...acc, [acc.length ? acc[acc.length -1][0]+block2.period : lastTimeBlock1, null, null, +ele, block2Spo2[index]]]
    }, [])

    const data = [
        ["Time", t("hlv.bpm"), t("hlv.spo2"), t("hlv.bpm"),  t("hlv.spo2")],
        ...block1Data,
        ...block2Data
    ];
    const formatters = [
        ...getArrayByNumber([ ...block1Data, ...block2Data].length).reduce((acc: any, ele: any, index: number) => {
            return {
                //num: acc.num,
                formatters: [...acc.formatters, {
                    type: "NumberFormat",
                    column: index + 1,
                    options: {
                        suffix: ele%2 ? ' %' : ' bpm',
                        decimalSymbol: ',',
                        groupingSymbol: '.',
                        prefix: `  `
                    }
                }]
            }
        }, {num: 1, formatters: []}).formatters
    ]
    return (
        <>
            <Legend list={[
                {label: t("hlv.bpm"), color: configuration.theme.series.s800_hex || 'pink'},
                {label: t("hlv.spo2"), color: configuration.theme.series.s100_hex || 'blue'},
            ]}/>
        <ChartWithZoom setImg={setImg} dataTable={data} chartType="LineChart" formatters={formatters}
                       options={{
                           pointSize: 8,
                           lineWidth: 1,

                           hAxis: {
                               baselineColor: "#5A5A5A",
                               minorGridlines: {
                                   color: "#F2F2F2"
                               },
                               gridlines: {
                                   color: "#F2F2F2"
                               },
                           },
                           vAxis: {
                               minorGridlines: {
                                   color: "#F2F2F2"
                               },
                               gridlines: {
                                   color: "#F2F2F2"
                               },
                               baselineColor: "#5A5A5A"

                           },
                           vAxes: {
                               0: {
                                   //title: 'label',
                                   titleTextStyle: {
                                       italic: false,
                                       fontSize: 13,
                                       color: "#5A5A5A"
                                   },
                               }, 1: {
                                   //title: 'title',
                                   titleTextStyle: {
                                       italic: false,
                                       fontSize: 13,
                                       color: "#5A5A5A"
                                   },
                               }
                           },
                           colors: [ configuration.theme.series.s800_hex || 'pink', configuration.theme.series.s100_hex || 'blue',],
                           chartArea: {top: 20, left: 60, right: 30, bottom: 60},
                           legend: {position: 'none'},
                       }}/>
        </>
    );
};

export default React.memo(ChartData);
