import React from 'react';
import {classByArray, mergeClass} from "../../../../shared/functions/function";
import Dropdown from "../../../../shared/bootstrap/dropdown/Dropdown";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";
import {HeaderMenuItemProps} from "./models/Nabar.model";
import {VoiceInfoMenuModel, VoiceMenuModel} from "../../models/Header.model";


const NavMenuItem: React.FC<HeaderMenuItemProps> = ({itemMenu, currentPage, templateVoices, currentSection, clickSubVoice}) => {
        const {t} = useLanguageForShared()
        const hasChildren = itemMenu.children && itemMenu.children.length;
        const itemStyle = mergeClass('nav-item-menu nav-item dropdown', classByArray([currentPage === itemMenu.name || currentSection === itemMenu.name || (itemMenu.children?.length && itemMenu.children.find(ele => ele.name === currentPage)) ? 'active' : '']))
        const dropdownStyle = mergeClass('nav-link', classByArray([hasChildren ? 'dropdown-toggle' : '']))

        const clickItemMenu = () => {
            !hasChildren && clickSubVoice(itemMenu)
        }

        const dropdownList = hasChildren && <> {itemMenu.children?.map((sub: VoiceInfoMenuModel, i: number) => {
            const active = currentPage === sub.name;
            const classData = classByArray(['dropdown-item', ...(active? ['active'] : [])])
            return (
            <li key={i} data-test="nav-menu-children" data-test-name={sub.name}><a className={classData}
                           onClick={() => clickSubVoice(sub)}>
                {templateVoices && templateVoices[sub.name] ? templateVoices[sub.name] : t(`header.nav.voices.${sub.name}`)}
            </a>
            </li>)})}
        </>

        return (
            <>
                <Dropdown dataTest={'section-menu-'+itemMenu.name} className={itemStyle} list={dropdownList}>
                    <div className={dropdownStyle} onClick={clickItemMenu}>
                        {templateVoices && templateVoices[itemMenu.name] ? templateVoices[itemMenu.name] :(itemMenu.label || t(`header.nav.voices.${itemMenu.name}`))}
                    </div>
                </Dropdown>
            </>
        );
    }
;

export default NavMenuItem;
