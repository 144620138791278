import React from 'react';
import {useAuth} from 'oidc-react';
import {Route, Routes, BrowserRouter as Router} from "react-router-dom"
import CallbackPage from "./CallbackPage";
import LoadingAuthPage from "./LoadingAuthPage";
import AuthPage from "./AuthPage";
import PublicPage from "./PublicPage";

const LoggedIn = () => {
    const auth = useAuth();
    return <Router>
        <Routes>
            <Route path="/callback" element={<CallbackPage/>}/>
        </Routes>
        {
            auth.isLoading ?
                <LoadingAuthPage/> : <>
                    {auth && auth.userData ?
                        <AuthPage auth={auth}/> :
                        <PublicPage/>
                    } </>
        }
    </Router>
};

export default LoggedIn;
