import React from 'react'
import UserAvatar from "../../../../../shared/custom/userAvatar/UserAvatar";
import {NavUser} from "./NavUser";
import {IconSVG} from "../../../../../shared/custom/icon/IconSVG";
import Dropdown from "../../../../../shared/bootstrap/dropdown/Dropdown";
import {OrganizationModel} from "../../../models/Organization.model";
import {ListAppsProps} from "../models/Nabar.model";

export const NavRight = ({
                             user,
                             setToggleSidebarHandler,
                              currentOrganization,
                             listApps
                         }: {
    user: any
    currentOrganization?: OrganizationModel
    setToggleSidebarHandler: () => void
    listApps?:ListAppsProps[]

}) => {

    return (
        <div className={'d-flex align-items-center'}>
            {user && <NavUser
                currentOrganization={currentOrganization}
                user={user}
                setToggleSidebarHandler={setToggleSidebarHandler}
                listApps={listApps}
            />}
        </div>
    )
}
