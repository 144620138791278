import React, {useCallback, useEffect, useRef, useState} from 'react';
import {LayoutCentered} from "../../core/layout/LayoutCentered";
import SessionTelerehabilitation from "./sessionTelerehabilitation/SessionTelerehabilitation";
import ActionTopBar from "../../shared/custom/actionBar/ActionTopBar";
import {TitleHeadingH3} from "../../shared/custom/headings/TitleHeadingH3";
import BtnList from "../../shared/custom/actionBar/BtnList";
import {useLanguageForShared} from "../../shared/utils/languages/useLanguageForShared";
import {exportPdf} from "../../shared/utils/exportPdf";
import LoaderFullScreen from "../../shared/custom/loader/loaderFullscreen/LoaderFullScreen";
import http from "../../shared/utils/http";
import configuration from "../../shared/configuration";
import {findMoreResourceByReference, findResourceByReference} from "../../shared/hl7/hl7FunctionSearch";
import {getCurrentPatientAction, resetCurrentPatient} from "../../store/patientSlice";
import {useParams} from "react-router-dom";
import {PatientDetailModel} from "../../model/PatientDetails";

const OxyView = () => {
    const {t} = useLanguageForShared()
    const {id, patientId} = useParams();
    const [patient, setPatient] = useState<any | undefined>(undefined);
    const [patientBis, setPatientBis] = useState<any | undefined>(undefined);
    useEffect(() => {
        http.get(configuration.external.hl7.httpUrl + '/composition/v1/demo/fhir/Composition?type=urn:ae:patienttreatment:detail|rehab&subject=Observation/' + id).then(res => {
            const entry = res.entry[0];
            const patient = findResourceByReference(entry, 'patient-info', 'Patient');
            const sessionRaw = findResourceByReference(entry, 'session', 'Observation');
            const d = sessionRaw.component.reduce((acc: any, ele: any) => {
                const key = Object.keys(ele).find(k => k.startsWith('value'))
                return {
                    ...acc,
                    [ele.code.coding[0].code.split('-').map((k: string, index: number) => index ? k.charAt(0).toUpperCase() + k.slice(1) : k).join('')]: ele[key],
                    ...(ele.code.text !== undefined ? {[ele.code.coding[0].code.split('-').map((k: string, index: number) => index ? k.charAt(0).toUpperCase() + k.slice(1) : k).join('') + 'Translate']: ele.code.text} : {})
                }
            }, {})
            const session =  {...d, id: sessionRaw.id, effectivePeriod: sessionRaw.effectivePeriod, status: sessionRaw.status}
            const data = {
                patient, session
            }
            setPatientBis(data)
        })
        http.get(configuration.external.hl7.httpUrl + '/composition/v1/demo/fhir/Composition?type=urn:ae:patienttreatment:summary|rehab&subject=Patient/' + patientId).then(res => {
            const entry = res.entry[0];
            const patient = findResourceByReference(entry, 'patient-info', 'Patient');
            const practitioner = findResourceByReference(entry, 'treatment', 'Practitioner')
            const treatments = findMoreResourceByReference(entry, 'treatment', 'CarePlan').map((ele: any) => {
                const url = ele.instantiatesCanonical[0].split('|')[0];
                const extraData = entry?.resource?.contained?.find((ele: any) => ele?.resourceType === "PlanDefinition" && ele?.url === url);
                const sectionsData = extraData.goal.reduce((acc: any, ele: any) => {
                    return {
                        ...acc,
                        [ele.description.coding[0].code.split('-').map((k: string, index: number) => index ? k.charAt(0).toUpperCase() + k.slice(1) : k).join('')]: ele.target[0].detailQuantity.value,
                    }
                }, {})
                const actions = extraData.action.reduce((acc: any, ele: any) => {
                    return {
                        ...acc,
                        [ele.code[0].coding[0].code.split('-').map((k: string, index: number) => index ? k.charAt(0).toUpperCase() + k.slice(1) : k).join('')]: {
                            labelText: ele.code[0].text,
                            timingTiming: ele.timingTiming,
                            timingDuration: ele.timingDuration
                        },
                    }
                }, {})
                const name = extraData?.title || ''
                return {...ele, name, sectionsData, actions}
            })
            const sessions = findMoreResourceByReference(entry, 'sessions-list', 'Observation').map((ele: any) => {
                const data = ele.component.reduce((acc: any, ele: any) => {
                    const key = Object.keys(ele).find(k => k.startsWith('value'))
                    return {
                        ...acc,
                        [ele.code.coding[0].code.split('-').map((k: string, index: number) => index ? k.charAt(0).toUpperCase() + k.slice(1) : k).join('')]: ele[key],
                        ...(ele.code.text !== undefined ? {[ele.code.coding[0].code.split('-').map((k: string, index: number) => index ? k.charAt(0).toUpperCase() + k.slice(1) : k).join('') + 'Translate']: ele.code.text} : {})
                    }
                }, {})
                return {...data, id: ele.id, effectivePeriod: ele.effectivePeriod, status: ele.status}
            })
            const session = sessions.find(ele => ele.id === id)
            const identifier = patient?.identifier?.find((ele: any) => ele?.system === 'urn:ae:defaultpatientregistry:id')?.value

            const data = {
                birthDate: patient.birthDate,
                gender: patient.gender,
                id: patient.id,
                status: patient?.extension?.find((ele: any) => ele.url === 'urn:ae:defaultpatientregistry:status')?.valueString,
                identifier,
                name: patient.name.map((name: any) => name.given.join(' ')).join(' '),
                surname: patient.name.map((name: any) => name.family).join(' '),
                session,
                ...(practitioner ? {
                    practitioner: {
                        name: practitioner.name.map((name: any) => name.given.join(' ')).join(' '),
                        surname: practitioner.name?.map((name: any) => name.family).join(' ')
                    }
                } : {}),
                treatments: treatments,
            };
            setPatient(data)
        })

    }, [])
    if(patient){
        console.log('patient', patient)
    }
    if(patientBis){
        console.log('patientBis',patientBis)
    }
    const pdfRef = useRef<HTMLDivElement>(null);
    const [printView, setPrintView] = useState<boolean>(false)
    const actionPage = [
        {
            label: t('hlv.action.downloadPDF'),
            icon: 'report-gen',
            actionHandler: () => {
                setPrintView(true)
                // setTimeout(() => {
                    exportPdf(`patient_${''}`, pdfRef, () => {
                        setPrintView(false)
                    })
                // }, 5000)

            }
        }
    ]
    const [img, setImg] = useState()
    /*NB no dettaglio apziente nell'header*/
    /*NB la pagine si apre in nuova scheda*/
    return (
        <LayoutCentered>
            <LoaderFullScreen show={printView}/>
            <div style={{marginTop: '-24px'}}>
                <ActionTopBar rightTemplate={<BtnList listBtn={actionPage}/>}/>
            </div>
            <div className={'row gap-5'} style={{'minHeight': '100vh'}}>
                {patient && <SessionTelerehabilitation setImg={setImg} img={img} printView={printView} ref={pdfRef} patient={patient} patientBis={patientBis}/>}
            </div>
        </LayoutCentered>
    );
};

export default OxyView;
