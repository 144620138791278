import {useSelector} from "react-redux";
import {selectTranslationHl7} from "./translateHl7Slice";
import {useLanguageForShared} from "../utils/languages/useLanguageForShared";

export function useHl7Translate(code: string, sec: string) {
    const { lang, t} = useLanguageForShared()
    const translates = useSelector(selectTranslationHl7);
    const section = translates[sec];
    const data = section?.find((ele: any) => ele.code === code)
    const trans = data?.designation?.find((ele: any) => ele.language === lang)
    return {
        value: trans?.value,
        t,
        lang
    }
}
