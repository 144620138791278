import React, {useEffect, useState} from "react";
import http from "../../shared/utils/http";
import {TitleHeadingH3} from "../../shared/custom/headings/TitleHeadingH3";
import HeadingList from "./components/HeadingList";
import PatientListTable from "./components/PatientListTable";
import httpData from "../../shared/utils/httpData";
import {useLanguageForShared} from "../../shared/utils/languages/useLanguageForShared";
import {prevDayDate} from "../../shared/utils/dateUtils";
import {arrayObjSort, objToArraySortByProperty} from "../../shared/utils/sortArray";
import Pagination from "../../shared/table/table/components/pagination/Pagination";
import {useDispatch, useSelector} from "react-redux";
import {selectTranslationHl7, setTranslationHl7} from "../../shared/hl7/translateHl7Slice";
import {valueSetHl7Name} from "../../shared/hl7/valueSet.hl7";
import configuration from "../../shared/configuration";
import {mock} from "./mockPatient";
import {tableConfigData} from "./template/tableConfigData";
import {useRoute} from "../../core/routes/useRoute";
import {nameRoutes} from "../../core/routes/nameRoutes";
import {
    findMoreResourceByReference,
    findResourceByReference,
    findResourceTypeHl7,
    findSectionHl7,
    findSectionReferenceHl7
} from "../../shared/hl7/hl7FunctionSearch";

const PatientList: React.FC<{}> = () => {
    const {t} = useLanguageForShared()
    const [total, setTotal] = useState<number>(0)
    const [listPatient, setListPatient] = useState<any[] | undefined>(undefined);
    const listTableConfig: any = tableConfigData(t);

    const callData = (offset = 0, callback: any = () => {}) => {
        http.get(configuration.external.hl7.httpUrl + '/composition/v1/demo/fhir/Composition?type=urn:ae:patient:list|rehab-registry&_count=100&_offset='+offset).then(res => {
            setTotal(res.total)
            const data = res.entry.map((entry: any, index: number) => {
                const patient = findResourceByReference(entry, 'patient-info', 'Patient');
                const practitioner = findResourceByReference(entry, 'doc-org', 'Practitioner')
                const organization = findResourceByReference(entry, 'doc-org', 'Organization')
                const pathology = findResourceByReference(entry, 'pathology', 'Condition')
                const score = entry.resource.contained.find((ele: any) => ele.resourceType === "Observation" && ele?.code?.coding?.find((el: any) => el.code === 'SCORE'))
                // const carePlansSections = findSectionHl7(entry,'care-plan')
                const carePlans = findMoreResourceByReference(entry, 'care-plan', 'CarePlan').map((ele: any) => {
                    const url = ele.instantiatesCanonical[0].split('|')[0];
                    const translate = entry?.resource?.contained?.find((ele: any) => ele?.resourceType === "PlanDefinition" && ele?.url === url);
                    const name = translate?.title || ''
                    return {...ele, name}
                });

                const treatments = findMoreResourceByReference(entry, 'treatment', 'CarePlan').map((ele: any) => {
                    const url = ele.instantiatesCanonical[0].split('|')[0];
                    const translate = entry?.resource?.contained?.find((ele: any) => ele?.resourceType === "PlanDefinition" && ele?.url === url);
                    const name = translate?.title || ''
                    return {...ele, name}
                })
                const compliance = findResourceByReference(entry, 'treatment', 'Observation');
                const lastRehabSection = findResourceByReference(entry, 'last-rehab-session', 'Observation')
                const identifier = patient?.identifier?.find((ele: any) => ele?.system === 'urn:ae:defaultpatientregistry:id')?.value
                const specialDevice: any = {
                    'deac3433-d9fa-4664-95fc-35494775cd34': {
                        type: 'Resmed',
                        name: 'Airsense 10 Autoset'
                    },
                    '3b40156b-bd9c-4442-8e1f-1f2f37b816c4': {
                        type: 'Philips Respironics',
                        name: 'DreamStation'
                    },
                    'e9caa9ef-abf6-5993-cde6-46fca501c5e3': {
                        type: 'Lowenstein',
                        name: 'Prisma SmartSOFT'
                    }
                }
                const data = {
                    ...(treatments.length? (specialDevice[identifier] ? {device: specialDevice[identifier]} : {device: (mock[index] || {}).device}): {}),
                    birthDate: patient.birthDate,
                    gender: patient.gender,
                    id: patient.id,
                    status: patient.extension.find((ele: any) => ele.url === 'urn:ae:defaultpatientregistry:status').valueString,
                    identifier,
                    name: patient.name.map((name: any) => name.given.join(' ')).join(' '),
                    surname: patient.name.map((name: any) => name.family).join(' '),
                    ...(practitioner ? {
                        practitioner: {
                            name: practitioner.name.map((name: any) => name.given.join(' ')).join(' '),
                            surname: practitioner.name?.map((name: any) => name.family).join(' ')
                        }
                    } : {}),

                    ...(organization ? {
                        organization: {
                            name: organization.name
                        }
                    } : {}),
                    compliance: {
                        data: compliance?.valueSampledData?.data?.split(' ') || [],
                        type: compliance?.code?.coding && compliance?.code?.coding[0]?.system ? compliance?.code?.coding[0]?.system : undefined
                    },
                    ...(pathology ? {
                        pathology: {
                            name: pathology.code.coding[0].code
                        }
                    } : {}),
                    ...(score ? {
                        score: score.valueInteger
                    } : {}),
                    carePlanes: carePlans,
                    treatments: treatments,
                    rehabSection: {
                        status: lastRehabSection?.component[0] && lastRehabSection?.component[0]?.valueString,
                        statusTranslate: lastRehabSection?.component[0] && lastRehabSection?.component[0]?.code.text,
                        effectivePeriod: lastRehabSection?.effectivePeriod
                    }
                };
                return data
            })
            setListPatient(arrayObjSort(data, 'surname'))
            callback()
        });
    }
    const dispatch = useDispatch();
    useEffect(() => {
                callData()
    }, [])
    const callbackChangePage = (data: any, callback: any) => {
        callData(data.offset, callback)
    }
    const {changeRoute} = useRoute()
    const goToDetail = (data: any) => {
        changeRoute(nameRoutes.patientsDetail, {id: data.identifier})
    }
    return <div>
        <div className="container-fluid patient-list">
            <div className="row">
                <div className="col-12">
                    <TitleHeadingH3 title={t('patientList.title')}/>
                </div>
                <div className="col-12">
                    <HeadingList listTableConfig={listTableConfig}/>

                    <PatientListTable clickRow={goToDetail} listPatient={listPatient} listTableConfig={listTableConfig}/>
                    <Pagination elementForPage={100} elements={total} callbackChangePage={callbackChangePage}/>
                </div>
            </div>
        </div>
    </div>
}

export default PatientList
