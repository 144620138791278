import React from 'react';
import Hl7PatientList from "../../libraries/patient_list/Hl7PatientList";
import PatientList from "../../libraries/patient_list/PatientList";
import ActionTopBar from "../../shared/custom/actionBar/ActionTopBar";
import {TitleHeadingH3} from "../../shared/custom/headings/TitleHeadingH3";
import BtnList from "../../shared/custom/actionBar/BtnList";
import {useLanguageForShared} from "../../shared/utils/languages/useLanguageForShared";


const Patients = () => {
    const {t} = useLanguageForShared()


    return (
        <div className={'row'}>
            <ActionTopBar leftTemplate={<TitleHeadingH3 title={t('hlv.patients')}/>}
                          rightTemplate={<BtnList listBtn={[]}/>}
            />
            <Hl7PatientList>
                <PatientList/>
            </Hl7PatientList>
        </div>
    );
}

export default Patients
