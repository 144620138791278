import React, { ReactNode, useState } from "react";
import { IconSVG } from "../../../../../shared/custom/icon/IconSVG";
import configuration from "../../../../../shared/configuration";

export const SubNavbar = ({
                            children,
                            isExpand = false,
                            showExpand = true,
                            setIsExpand
                          }: { children: ReactNode, isExpand?: boolean, showExpand?: boolean, setIsExpand?: (isExpand: boolean) => void, }) => {

  return (
    <div className="container-fluid sub-navbar">
      {children}
      {showExpand && <div className={"icon-expand"} onClick={() => setIsExpand && setIsExpand(!isExpand)}><IconSVG
        tintColor={configuration.theme.primary}
        icon={isExpand ? "chevron-up" : "chevron-down"}
        size={"16px"} /></div>}
    </div>
  );
};
