import React from 'react'
import {t} from "i18next";
import CreatableSelect from "react-select/creatable";
import {components, StylesConfig} from "react-select";
import {useLanguageForShared} from "../../../utils/languages/useLanguageForShared";
import BtnPrimary from "../../../bootstrap/button/primary/BtnPrimary";

interface SelectProps {
    options: { value: string; label: string; disabled?: boolean }[]
    changeValue: (data: any) => void
    value: any
    extra: any
    error?: boolean;
    placeholder?: string;
}

const ReactSelect = ({

                         options,
                         changeValue,
                         extra,
                         value,
                         error,
                         placeholder,
                         ...rest
                     }: SelectProps) => {


    const {t} = useLanguageForShared()
    const onChangeAction = (val: any) => {
        changeValue(extra.multi? val.map((ele: any) => ele.value) :val.value)
    }

    const customStyles: StylesConfig<any> = {
        indicatorSeparator: base => ({
            ...base,
            display: 'none'
        }),
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                color: '#333',
            }
        }
    }
    const DropdownIndicator = (props: any) => {
        return (
            components.DropdownIndicator && (

                <components.DropdownIndicator {...props}>
                    {extra.noShowCaret ? <></> :
                        <div
                            className={`select-caret ${props?.selectProps?.menuIsOpen ? "caret-up" : "caret-down"}`}></div>}
                </components.DropdownIndicator>
            )
        );
    };

    const newValues = !extra.multi ? (value && !options.find(ele => ele.value === value) ? [{label: value, value}] : []) : (value && value.length ? value.filter((val: any) => !options.find(ele => ele.value === val)).map((val: any) => ({label: val, value: val})) : [])

    const optionsFormatted = [
        ...options,
        ...newValues
    ]

    const MenuList = (props: any) => {
        return (
            <components.MenuList {...props}>
                {Array.isArray(props.children) ? props.children.slice(0, 5) : props.children}
            </components.MenuList>
        );
    };

    return (
        <CreatableSelect
            className={`${error ? "error" : ""}`}
            placeholder={placeholder ? placeholder : t('action.select')}
            formatCreateLabel={(value) =>
                <span className={'d-flex align-items-center gap-2'}>
                                <BtnPrimary className={'pointer'}>{t('action.create')}</BtnPrimary>{value}
                             </span>}
            value={extra.multi? optionsFormatted.filter(ele => (value || []).find((el: any) => ele.value === el)) : optionsFormatted.find(ele => ele.value === value)}
            options={optionsFormatted}
            onChange={onChangeAction}
            components={{
                DropdownIndicator, MenuList
            }}
            styles={customStyles}
            isMulti={extra?.multi}
        />
    )
}


export default ReactSelect
