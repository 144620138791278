export   const findSectionHl7= (entry: any, code: string) => {
    return entry?.resource?.section?.find((s: any) => s?.code?.coding?.find((c: any) => c?.code === code))
}

export   const findSectionReferenceHl7= (section: any, type: string) => {
    return section?.entry?.find((ele: any) => ele.type === type)
}
export   const findSectionsReferenceHl7= (section: any, type: string) => {
    return section?.entry?.filter((ele: any) => ele.type === type)
}
export   const findResourceTypeHl7= (entry: any, section: any) => {
    return entry?.resource?.contained?.find((ele: any) => ele.resourceType === section?.type && ele.id === section?.reference)
}
export   const findResourcesTypeHl7= (entry: any, section: any) => {
    return entry?.resource?.contained?.filter((ele: any) => ele.resourceType === section?.type && ele.id === section?.reference)
}

export const findResourceByReference = (entry: any, code: string, type:string) => {
    const sectionElement = findSectionHl7(entry, code)
    const reference = findSectionReferenceHl7(sectionElement, type);
    return findResourceTypeHl7(entry, reference)
}

export const findMoreResourceByReference = (entry: any, code: string, type:string) => {
    const sectionElement = findSectionHl7(entry, code)
    const references = findSectionsReferenceHl7(sectionElement, type);
    return references?.map((ref: any) => findResourceTypeHl7(entry, ref)) || []
}
