import React, {ReactNode} from 'react'

export const LayoutCentered = ({
                                   children,
                               }: {
    children: ReactNode
}) => {
    return (
        <>
            <div className="content-centered container-fluid">
                <div className="container">
                    {children}
                </div>
            </div>
        </>
    )
}
