import React from 'react'

interface BoxFullScreenProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode
  show?: boolean
  className?: string
}

const BoxFullScreen = ({
  children,
  className,
  show = false,
  ...rest
}: BoxFullScreenProps) => {
  return (
    <>
      <div style={!show ? { display: 'none' } : {}}>
        <div className={`shared-boxFullScreen ${className}`} {...rest}></div>
        <div>{children}</div>
      </div>
    </>
  )
}
export default BoxFullScreen
