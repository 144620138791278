import configuration from "../../../shared/configuration";

export const tableConfigData = (t:any) => {
    const hideCol = configuration?.external?.patients?.hideCol || []
    const data = [
        {
            name: 'check',
            title: '',
            elementsHeaderBody: {
                className: 'col-check'
            }
        },
        {
            name: 'patient',
            title: t('patientList.headingTable.patient'),
            templateIntegralBody: true,
            elementsHeaderBody: {
                className: 'col-patient'
            }
        },
        {
            name: 'personalCare',
            title: t('patientList.headingTable.doctor'),
            elementsHeaderBody: {
                className: 'col-personal-care'
            }
        },
        {
            name: 'state',
            title: t('patientList.headingTable.state'),
            elementsHeaderBody: {
                className: 'col-state'
            }
        },
        {
            name: 'score',
            title: t('patientList.headingTable.score'),
            elementsHeaderBody: {
                className: 'col-score'
            }
        },
        {
            name: 'carePlan',
            title: t('patientList.headingTable.carePlan'),
            elementsHeaderBody: {
                className: 'col-care-plan'
            }
        },
        {
            name: 'pathology',
            title: t('patientList.headingTable.pathology'),
            elementsHeaderBody: {
                className: 'col-pathology'
            }
        },
        {
            name: 'treatments',
            title: t('patientList.headingTable.treatment'),
            templateHeader: 'treatments',
            elementsHeaderBody: {
                className: 'col-treatments'
            }
        },
        {
            name: 'lastSessionDate',
            title: t('patientList.headingTable.lastSessionDate'),
            elementsHeaderBody: {
                className: 'col-lastSessionDate'
            }
        },
        {
            name: 'lastSessionStatus',
            title: t('patientList.headingTable.lastSessionStatus'),
            elementsHeaderBody: {
                className: 'col-lastSessionStatus'
            }
        },
        {
            name: 'device',
            title: t('patientList.headingTable.device'),
            elementsHeaderBody: {
                className: 'col-device'
            }
        },
        {
            name: 'action',
            title: '',
            elementsHeaderBody: {
                className: 'col-action'
            }
        },
    ]

    return data.filter(ele => !hideCol.includes(ele.name));
}
