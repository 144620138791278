import React from 'react';
import {IconSVG} from "../icon/IconSVG";

const TagAlert = ({
                      label,
                      bgColor,
                      color,
                      dataElement = {},
                      tagTest = 'tag-test-alert',
                      icon
                  }: { label: string, bgColor?: string, color?: string, dataElement?: any, tagTest?: string, icon?: string }) => {
    return (
        <div className={'tag tag-alert'} {...dataElement} style={{backgroundColor: bgColor, color: color}}
             data-test={tagTest}>
            {icon && <IconSVG icon={icon} size={'16px'} tintColor={color || ''}/>}
            {label}
        </div>
    );
};

export default TagAlert;
