export const mock = [
    {
        nextAppointment: {
            date: '2023-05-12T13:00:00.502Z'
        },
        device: {
            type: 'Resmed',
            name: 'Airsense 10 Autoset'
        }
    },
    {
        alarm: true,
        toContact: true,
        nextAppointment: {
            date: '2023-05-16T15:45:00.502Z'
        },
        device: {
            type: 'Resmed',
            name: 'Airsense 10 Elite'
        }
    },
    {
        nextAppointment: {
            date: '2023-05-10T16:00:00.502Z'
        },
        device: {
            type: 'Lowenstein',
            name: 'Prisma SmartSOFT'
        }
    },
    {
        toContact: true,
        device: {
            type: 'BMC',
            name: 'Autocpap G2S A20'
        }
    },
    {
        questionnairesNotComplete: {
            status: 'not-complete'
        },
        device: {
            type: 'Lowenstein',
            name: 'Prisma SmartMAX'
        }
    },
    {
        alert: true,
        device: {
            type: 'Resmed',
            name: 'Airmini'
        }
    },
    {},
    {
        toContact: true,
        device: {
            type: 'Resmed',
            name: 'Airsense 10 Autoset'
        }
    },
    {
        alarm: true,
        toContact: true,
        questionnairesNotComplete: {
            status: 'not-complete'
        },
        device: {
            type: 'Lowenstein',
            name: 'Prisma SmartSOFT'
        }
    },
    {
        nextAppointment: {
            date: '2023-05-10T16:00:00.502Z'
        },
        device: {
            type: 'BMC',
            name: 'G3A20'
        }
    },
    {
        alarm: true,
        questionnairesNotComplete: {
            status: 'not-complete'
        },
        device: {
            type: 'Resmed',
            name: 'Airsense 10 Autoset'
        }
    },
    {
        alert: true,
        toContact: true,
        device: {
            type: 'Lowenstein',
            name: 'Prisma SmartSOFT'
        }
    },
    {
        alert: true,
        toContact: true,
        device: {
            type: 'Lowenstein',
            name: 'Prisma SmartSOFT'
        }
    },
    {
        nextAppointment: {
            date: '2023-05-12T13:00:00.502Z'
        },
        device: {
            type: 'Resmed',
            name: 'Airsense 10 Autoset'
        }
    },
    {
        alarm: true,
        toContact: true,
        nextAppointment: {
            date: '2023-05-16T15:45:00.502Z'
        },
        device: {
            type: 'Resmed',
            name: 'Airsense 10 Elite'
        }
    },
    {
        nextAppointment: {
            date: '2023-05-10T16:00:00.502Z'
        },
        device: {
            type: 'Lowenstein',
            name: 'Prisma SmartSOFT'
        }
    },
    {},
    {
        toContact: true,
        device: {
            type: 'BMC',
            name: 'Autocpap G2S A20'
        }
    },
    {
        questionnairesNotComplete: {
            status: 'not-complete'
        },
        device: {
            type: 'Lowenstein',
            name: 'Prisma SmartMAX'
        }
    },
    {
        alert: true,
        device: {
            type: 'Resmed',
            name: 'Airmini'
        }
    },
    {
        toContact: true,
        device: {
            type: 'Resmed',
            name: 'Airsense 10 Autoset'
        }
    },
    {
        alarm: true,
        toContact: true,
        questionnairesNotComplete: {
            status: 'not-complete'
        },
        device: {
            type: 'Lowenstein',
            name: 'Prisma SmartSOFT'
        }
    },
    {
        nextAppointment: {
            date: '2023-05-10T16:00:00.502Z'
        },
        device: {
            type: 'BMC',
            name: 'G3A20'
        }
    },
    {
        alarm: true,
        questionnairesNotComplete: {
            status: 'not-complete'
        },
        device: {
            type: 'Resmed',
            name: 'Airsense 10 Autoset'
        }
    },
    {
        alert: true,
        toContact: true,
        device: {
            type: 'Lowenstein',
            name: 'Prisma SmartSOFT'
        }
    },
    {
        alert: true,
        toContact: true,
        device: {
            type: 'Lowenstein',
            name: 'Prisma SmartSOFT'
        }
    },
    {
        alarm: true,
        questionnairesNotComplete: {
            status: 'not-complete'
        },
        device: {
            type: 'Resmed',
            name: 'Airsense 10 Autoset'
        }
    },
    {
        alert: true,
        toContact: true,
        device: {
            type: 'Lowenstein',
            name: 'Prisma SmartSOFT'
        }
    },
    {
        alert: true,
        toContact: true,
        device: {
            type: 'Lowenstein',
            name: 'Prisma SmartSOFT'
        }
    },

]
