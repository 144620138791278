import React from 'react';
import ModalCustom from "../../../../../shared/bootstrap/modal/ModalCustom";
import {useLanguageForShared} from "../../../../../shared/utils/languages/useLanguageForShared";

const ModalComment = ({comment, closeModal}: { comment: any, closeModal: () => void }) => {
    const {t} = useLanguageForShared()

    return (
        <ModalCustom closeModal={closeModal}
                     title={t(comment.type === 'text' ? 'hlv.patient_comment' : 'hlv.patient_vocal')}
        >
            <h4>{t(comment.type === 'text' ? 'hlv.patient_comment_info' : 'hlv.patient_vocal_info')} </h4>

            {comment.type === 'text' && <div className={'fs-5'}>"{comment.data}"</div>}

            {comment.type === 'vocal' && <audio controls className={'mt-4'}>
                <source src="https://filesamples.com/samples/audio/mp3/Symphony%20No.6%20(1st%20movement).mp3"
                        type="audio/ogg"/>
                Your browser does not support the audio element.
            </audio>}
        </ModalCustom>
    );
};

export default ModalComment;
